import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

export enum TabSize {
  SM = "sm",
  MD = "md",
  LG = "lg",
}

interface ITab {
  children: any;
  size?: TabSize;
  disabled?: boolean;
  active?: boolean;
  onClick?: Function;
  extraClasses?: string;
  hoverClasses?: string;
}

export const Tab = ({
  children,
  size,
  disabled,
  active,
  onClick,
  extraClasses,
  hoverClasses,
}: ITab) => {
  const sizeClasses = useMemo(() => {
    if (size == TabSize.SM) {
      return "h-8 px-3 py-2 text-sm";
    } else if (size == TabSize.MD) {
      return "p-3 gap-x-[6px]";
    }
  }, [size]);

  const colorClasses = useMemo(() => {
    return `${
      disabled
        ? "bg-global-bg text-gray-400"
        : `${active ? "bg-gray-700 pointer-events-none" : "bg-global-bg text-gray-300"} ${
            hoverClasses || "hover:bg-gray-800"
          }`
    }`;
  }, [disabled, active]);

  return (
    <div
      className={twMerge(
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        "flex items-center rounded",
        sizeClasses || "",
        colorClasses || "",
        extraClasses || "",
      )}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};
