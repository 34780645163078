import { Connection, PublicKey } from "@solana/web3.js";
import {
    SwitchboardProgram
} from "@switchboard-xyz/solana.js";
import * as anchor from "@coral-xyz/anchor";
import { PLATFORM_PUBKEY } from "../../sdk/constants";
import { CASHIER_PROGRAM_PUBKEY } from "../../sdk/nftStaking/nftStaking";

export async function loadSwitchboardProgram(client: Connection) {
    try {
        const program = await SwitchboardProgram.load(client);
        return program
    } catch (err) {
        console.error('Issue loading switchboard program', err)
    }
}

export function derivePlatformPermissionAccountPubkey(
    authorityOwnerPubkey: PublicKey
): PublicKey {
    const [permissionAccountPubkey, _] = PublicKey.findProgramAddressSync(
        [
            anchor.utils.bytes.utf8.encode("platform_permission"),
            PLATFORM_PUBKEY.toBuffer(),
            authorityOwnerPubkey.toBuffer(),
        ],
        CASHIER_PROGRAM_PUBKEY,
    );
    return permissionAccountPubkey;
}