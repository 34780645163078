import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useErrorMessageValidation } from "../hooks/validation/useErrorMessageValidation";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { HealthContext } from "./HealthContext";
import { ErrorType, IGameError } from "../types/error";
import WarningIcon from "./../assets/icons/warning-circle.svg";
import ErrorIcon from "./../assets/icons/error-circle.svg";
import ClockIcon from "./../assets/icons/clock.svg";
import Icon from "./../components/common/icon/Icon";
import Button from "../components/common/button/Button";
import { useModal } from "react-modal-hook";
import { TermsAndConditionsModal } from "../modals/TermsAndConditionsModal";
import { GeoblockModal } from "../modals/GeoblockModal";
import { NetworkContext } from "./NetworkContext";
import { APP_NETWORK_TYPE } from "../types/chain";
import { AggregatedBalancesContext } from "./AggregatedBalancesContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { BannerType } from "../components/banners/Banner";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { AirdropModal } from "../modals/AirdropModal";
import { NftStakingContext } from "./NftStakingContext";
import { IS_MAINNET } from "../utils/solana/rpc";
import { AddFundsAndPlayModal, IFundOrWithdrawModalTabs } from "../components/modals/AddFundsAndPlayModal";
import { FundsModal } from "../components/modals/FundsModal";

export interface IBannerContext {
  warnings: IGameError[];
  alerts: any[];
  // METHODS TO ALLOW ADDING AND REMOVING BANNERS
  addErrorMessage: (error: IGameError) => void
  removeErrorMessage: (type: ErrorType) => void
}

export const BannerContext = createContext<IBannerContext>(
  {} as IBannerContext
);

interface Props {
  children: any;
}

export const ZEEBIT_SHOULD_HIDE_ZERO_BALANCE_BANNER =
  "zeebit-should-hide-zero-balance-banner";

export const BannerProvider = ({ children }: Props) => {
  const warnings = useErrorMessageValidation();

  const [shouldHideZeroBalanceBannerFromStore, setShouldHideZeroBalanceBannerFromStore] =
    useLocalStorage(ZEEBIT_SHOULD_HIDE_ZERO_BALANCE_BANNER, []);
  const [
    shouldHideZeroBalanceBanner, setShouldHideZeroBalanceBanner
  ] = useState<string[]>(shouldHideZeroBalanceBannerFromStore || []);

  // TODO - LOAD ALERTS FROM SOMEWHERE
  const [alerts, setAlerts] = useState<any[]>([]);

  const { networkValidation, locationValidation, playerValidation } =
    useContext(ErrorHandlingContext);
  const { solanaTps } = useContext(HealthContext);
  const { chain, chainDisplayName } = useContext(NetworkContext);
  const { solBalances } = useContext(AggregatedBalancesContext);
  const { walletPubkey } = useContext(WrappedWalletContext);
  const { isAvailable } = useContext(NftStakingContext);


  useEffect(() => {
    setShouldHideZeroBalanceBannerFromStore(shouldHideZeroBalanceBanner);
  }, [shouldHideZeroBalanceBanner]);

  const hasSolTokens = (solBalances?.native?.uiAmount || 0) > 0;

  const [showAirdropModal, hideWalletModal] = useModal(
    ({ in: open }) => (
      <AirdropModal visible={open} hideModal={hideWalletModal} />
    ),
    []
  );

  // DEPOSIT / WITHDRAW MODAL
  const [showFundsAndPlayModal, hideFundsAndPlayModal] = useModal(
      ({ in: open }) => (
        <AddFundsAndPlayModal
          visible={open}
          showModal={showFundsAndPlayModal}
          hideModal={() => {
            hideFundsAndPlayModal();
          }}
          walletModalSelectedTab={IFundOrWithdrawModalTabs.DEPOSIT}
        />
      ), []
    );

     const [showFundsModal, hideFundsModal] = useModal(
        ({ in: open }) => (
          <FundsModal
            visible={open}
            hideModal={hideFundsModal}
            showFundsAndPlayModal={showFundsAndPlayModal}
          />
        ), []
      );

  useEffect(() => {
    // CHECK INTERNET DOWN
    if (networkValidation.errorMessageByType.has(ErrorType.NO_INTERNET)) {
      warnings.addErrorMessage({
        type: ErrorType.NO_INTERNET,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              You are not connected to the internet, or experiencing network
              issues.
            </div>
          </Fragment>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.NO_INTERNET)) {
      warnings.removeErrorMessage(ErrorType.NO_INTERNET);
    }

    // CHECK SOLANA DOWN
    if (networkValidation.errorMessageByType.has(ErrorType.SOLANA_DOWN)) {
      warnings.addErrorMessage({
        type: ErrorType.SOLANA_DOWN,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              {`The ${chainDisplayName} network may be experiencing outage issues.`}
            </div>
          </Fragment>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.SOLANA_DOWN)) {
      warnings.removeErrorMessage(ErrorType.SOLANA_DOWN);
    }

    // CHECK SOLANA SLOW
    if (
      !warnings.errorMessageByType.has(ErrorType.SOLANA_SLOW) &&
      solanaTps != null &&
      solanaTps < 25 &&
      chain !== APP_NETWORK_TYPE.SONIC
    ) {
      warnings.addErrorMessage({
        type: ErrorType.SOLANA_SLOW,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              {`The ${chainDisplayName} network TPS is lower than usual, this may cause latency or outage issues.`}
            </div>
          </Fragment>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.SOLANA_SLOW)) {
      warnings.removeErrorMessage(ErrorType.SOLANA_SLOW);
    }
  }, [networkValidation, solanaTps]);

  const [showGeoblockModal, hideGeoblockModal] = useModal(
    ({ in: open }) => (
      <GeoblockModal
        open={open}
        hideModal={() => {
          hideGeoblockModal();
        }}
      />
    ),
    []
  );

  useEffect(() => {
    // CHECK NOT IN RESTRICTED IP
    if (locationValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      warnings.addErrorMessage({
        type: ErrorType.IP_RESTRICTED,
        title: "",
        message: (
          <div
            className="flex gap-x-2 cursor-pointer"
            onClick={showGeoblockModal}
          >
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<ErrorIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              Access Restricted due to location.
            </div>
          </div>
        ),
      });
    } else if (warnings.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      warnings.removeErrorMessage(ErrorType.IP_RESTRICTED);
    }
  }, [locationValidation]);

  // USE EFFECT TO CHECK FOR WARNINGS -
  //   TIME_OUT_ACTIVE = 'Time out',
  //   SELF_EXCLUSION_ACTIVE = 'Self exclusion',
  //   TERMS_AND_CONDITIONS_UNSIGNED = 'Terms and conditions'
  const [showTermsModal, hideTermsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <TermsAndConditionsModal open={open} handleClose={hideTermsModal} />
    ),
    []
  );

  useEffect(() => {
    if (playerValidation.errorMessageByType.has(ErrorType.TIME_OUT_ACTIVE)) {
      const error = playerValidation.errorMessageByType.get(
        ErrorType.TIME_OUT_ACTIVE
      );
      warnings.addErrorMessage({
        type: ErrorType.TIME_OUT_ACTIVE,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<ClockIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              {error?.message}
            </div>
          </Fragment>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.TIME_OUT_ACTIVE);
    }

    if (
      playerValidation.errorMessageByType.has(ErrorType.SELF_EXCLUSION_ACTIVE)
    ) {
      warnings.addErrorMessage({
        type: ErrorType.SELF_EXCLUSION_ACTIVE,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<ErrorIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              Betting is restricted due to Self Exclusion
            </div>
          </Fragment>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.SELF_EXCLUSION_ACTIVE);
    }

    if (
      playerValidation.errorMessageByType.has(
        ErrorType.TERMS_AND_CONDITIONS_UNSIGNED
      )
    ) {
      warnings.addErrorMessage({
        type: ErrorType.TERMS_AND_CONDITIONS_UNSIGNED,
        title: "",
        message: (
          <Fragment>
            <Icon
              className="text-gray-200"
              inheritStroke={true}
              size="lg"
              icon={<WarningIcon />}
            />
            <div className="text-sm font-normal text-gray-50">
              Please accepts Terms & Conditions to enable betting
            </div>
            <Button
              variant="secondary-gray"
              size="sm"
              onClick={() => {
                showTermsModal();
              }}
            >
              Terms & Conditions
            </Button>
          </Fragment>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.TERMS_AND_CONDITIONS_UNSIGNED);
    }
  }, [playerValidation]);


  useEffect(() => {
    if (
      walletPubkey
      && !hasSolTokens
      && !shouldHideZeroBalanceBanner?.includes?.(walletPubkey?.toString())
    ) {
      warnings.addErrorMessage({
        type: ErrorType.SOLANA_ZERO_BALANCE,
        bannerType: BannerType.ERROR,
        title: "",
        onBannerClick: () => {
          if (IS_MAINNET == false) {
            showAirdropModal()
          } else {
            // SHOW THE DEPOSIT MODAL
            showFundsModal()
          }
        },
        message: (
          <div className="flex w-full justify-start items-center">
            <div className="flex w-full text-sm font-normal text-amber-300 items-center justify-between">
              <span>
                <span className="font-semibold">Note:</span>
                <span> Looks like your wallet is empty. Please add funds to your wallet before playing.</span>
              </span>
              {/* <Button
                variant={"primary"}
                size={"md"}
                children={"Dismiss"}
                className="bg-purple-400 ml-3"
                onClick={() => {
                  warnings.removeErrorMessage(ErrorType.SOLANA_ZERO_BALANCE);
                  setShouldHideZeroBalanceBanner(prevState => [...prevState, walletPubkey?.toString()]);
                }}
              /> */}
            </div>
          </div>
        ),
      });
    } else {
      warnings.removeErrorMessage(ErrorType.SOLANA_ZERO_BALANCE);
    }
  }, [solBalances, shouldHideZeroBalanceBanner, warnings, showFundsAndPlayModal, showFundsModal]);

  return (
    <BannerContext.Provider
      value={useMemo(
        () => ({
          warnings: warnings.errorMessages,
          alerts: alerts,
          addErrorMessage: warnings.addErrorMessage,
          removeErrorMessage: warnings.removeErrorMessage
        }),
        [warnings, alerts]
      )}
    >
      {children}
    </BannerContext.Provider>
  );
};
