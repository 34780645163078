import { APP_NETWORK_TYPE } from "../../types/chain";

const HELIUS_API_KEY = "f6d9ddce-6566-49e8-a3a1-f0694d483043"

export const ENV_NAME: string | undefined = process.env.ENV_NAME?.toUpperCase();

const IS_MAINNET = ENV_NAME == 'MAINNET'
const HELIUS_ENDPOINT = `https://mainnet.helius-rpc.com/?api-key=f6d9ddce-6566-49e8-a3a1-f0694d483043`

export const WEB3_AUTH_REDIRECT: string = process.env.WEB3_AUTH_REDIRECT || (IS_MAINNET ? "https://zeebit.io": "https://dev.zeebit.io");
export const RPC_READ_ENDPOINT_SOLANA: string | undefined = process.env.RPC_READ_ENDPOINT || (IS_MAINNET ? HELIUS_ENDPOINT: "https://black-sparkling-hill.solana-devnet.quiknode.pro/140c5f0950de18bebf55d516e84e889f633b68fe/")
export const RPC_WRITE_ENDPOINTS_SOLANA: string | undefined = process.env.RPC_WRITE_ENDPOINTS || (IS_MAINNET ? HELIUS_ENDPOINT: "https://black-sparkling-hill.solana-devnet.quiknode.pro/140c5f0950de18bebf55d516e84e889f633b68fe/")
export const PRIORITY_FEES_ENDPOINT: string | undefined = process.env.PRIORITY_FEES_ENDPOINT || "https://mainnet.helius-rpc.com/?api-key=f6d9ddce-6566-49e8-a3a1-f0694d483043"

export const RPC_READ_ENDPOINT_SONIC: string | undefined = (IS_MAINNET ? "https://api.mainnet-alpha.sonic.game": "https://api.testnet.v1.sonic.game")
export const RPC_WRITE_ENDPOINTS_SONIC: string | undefined = (IS_MAINNET ? "https://api.mainnet-alpha.sonic.game": "https://api.testnet.v1.sonic.game")

export const APP_NETWORK: APP_NETWORK_TYPE = process.env.APP_NETWORK || APP_NETWORK_TYPE.MB_AND_SOLANA

export const getRpcReadEndpoint = (chain: APP_NETWORK_TYPE) => {
    return chain == APP_NETWORK_TYPE.SONIC ? RPC_READ_ENDPOINT_SONIC : RPC_READ_ENDPOINT_SOLANA
}

export const getRpcWriteEndpoints = (chain: APP_NETWORK_TYPE) => {
    return (chain == APP_NETWORK_TYPE.SONIC ? RPC_WRITE_ENDPOINTS_SONIC : RPC_WRITE_ENDPOINTS_SOLANA)?.split(',')
}

export const RPC_WRITE_ENDPOINTS = APP_NETWORK == APP_NETWORK_TYPE.SONIC ? RPC_WRITE_ENDPOINTS_SONIC : RPC_WRITE_ENDPOINTS_SOLANA

export const ENFORCE_DELEGATION_FLOWS: string = process.env.ENFORCE_DELEGATION_FLOWS || 'false' // USED IF APP_NETWORK == MAGIC_BLOCKS
