
export const networks: any[] = [
    {
        id: "devnet",
        type: "Devnet",
        name: "Origin",
        url: "",
        rpc: "https://devnet.sonic.game",
    },
    {
        id: "testnet",
        type: "Testnet",
        name: "Frontier",
        url: "/testnet",
        rpc: "https://api.testnet.v1.sonic.game",
    },
    {
      id: "mainnet",
      url: "/mainnet",
      name: "Mainnet",
      rpc: "https://api.mainnet-alpha.sonic.game",
    },
];