import React, {
  Fragment,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";

import { SidebarDesktop } from "./SideBarDesktop";
import { SideBarMobile } from "./SideBarMobile";
import Nav from "./Nav";
import { convertDocEleToReact } from "../../utils/svg/create-svg";

import { NetworkContext, NavContext } from "../../contexts";
import BarFooter from "./BarFooter";
import { WrappedWalletContext } from "../../contexts";

import RewardsBar from "../sonic/sonic-rewards/RewardsBar";
import {APP_NETWORK_TYPE} from "../../types/chain";
import {UserProfileMobile} from "../profile/UserProfileMobile";

export function SideBar() {
  const networkContext = useContext(NetworkContext);
  const { chain } = useContext(NetworkContext);
  const { loggedIn, isWeb3AuthWallet } = useContext(WrappedWalletContext);

  const platformGames = useMemo(() => {
    return networkContext.platformGames;
  }, [networkContext.platformGames]);

  const [gameSvgs, setGameSvgs] = useState(new Map());
  const domParser = new DOMParser();
  const fetchGameSvg = async (svgUrl: string) => {
    fetch(svgUrl).then((res) =>
      res.text().then((svgText) => {
        setGameSvgs(
          gameSvgs.set(
            svgUrl,
            convertDocEleToReact(
              domParser.parseFromString(svgText, "image/svg+xml")
                ?.documentElement,
              null
            )
          )
        );
      })
    );
  };
  useEffect(() => {
    if (platformGames) {
      platformGames.forEach((game) => {
        fetchGameSvg(game.icon);
      });
    }
  }, [platformGames]);

  const { leftPanelOpen, toggleLeftPanel, userProfilePanelOpen } = useContext(NavContext);

  return (
    <Fragment>
      <SideBarMobile sidebarOpen={leftPanelOpen}>
        <>
          <div data-id="sonic-rewards" className="flex flex-col w-full gap-2">
            {
              loggedIn
              && chain === APP_NETWORK_TYPE.SONIC
              && <RewardsBar className="md:hidden" />
            }
          </div>
          <Nav
            games={platformGames}
            leftBarIsOpen={true}
            toggleLeftPanel={toggleLeftPanel}
            closeAfterClick={true}
            footerContent={
              <BarFooter
                sidebarOpen={true}
                className={`mt-5`}
                toggleLeftBar={() => { }}
                displayProfile={false}
                showNetworkStatus={true}
              />
            }
          />
          {/*  */}
        </>
      </SideBarMobile>

      <UserProfileMobile panelOpen={userProfilePanelOpen} />

      {/* Static sidebar for desktop */}
      <SidebarDesktop leftBarIsOpen={leftPanelOpen}>
        <Nav
          games={platformGames}
          leftBarIsOpen={leftPanelOpen}
          toggleLeftPanel={toggleLeftPanel}
          closeAfterClick={false}
        />
        <BarFooter
          sidebarOpen={leftPanelOpen}
          className={`${leftPanelOpen ? "mb-2" : "mb-0"}`}
          toggleLeftBar={toggleLeftPanel}
          displayProfile={true}
          showNetworkStatus={leftPanelOpen}
        />
      </SidebarDesktop>
    </Fragment>
  );
}
