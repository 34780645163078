import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { BaseModal } from "./BaseModal";
import { HouseContext, BalanceContext, WrappedWalletContext, PlayerTokenContext } from "../contexts";
import { TermsAndConditionsStep } from "./registration/TermsAndConditions";
import { CustomizeProfileStep } from "./registration/ProfileCustomisation";
import { SolExplained } from "./registration/SolExplained";
import { FundWalletStep } from "./registration/FundWallet";
import { SonicExplained } from "./registration/SonicExplained";
import { AutoDeposit } from "./registration/AutoDeposit";
import { NetworkOnboardingStep } from "./registration/NetworkOnboardingStep";

interface IRegistrationModalProps {
  open: boolean;
  closeModal: Function;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const RegistrationModal: React.FC<IRegistrationModalProps> = ({
  open,
  closeModal,
  currentStep,
  setCurrentStep,
}) => {
  // QUICK FIX FOR ALPHA GATING WALLET CONNECTED
  const { walletPubkey } = useContext(WrappedWalletContext);
  const { solBalances } = useContext(BalanceContext);
  const hasSolTokens = (solBalances?.native?.uiAmount || 0) > 0;

  useEffect(() => {
    if (walletPubkey == null) {
      closeModal();
    }
  }, [walletPubkey]);

  const { playerMeta, setPlayerMeta } = useContext(PlayerTokenContext);
  const { house } = useContext(HouseContext);
  const platform = {};

  const updateLatestTerms = useCallback(() => {
    const meta = playerMeta || {};
    meta.signedTerms = true;

    setPlayerMeta(meta);
  }, [house, platform, playerMeta, setPlayerMeta]);

  const steps = useMemo(() => {
    return [
      <NetworkOnboardingStep
        setCurrentStep={setCurrentStep}
      />,
      <TermsAndConditionsStep
        setLatestTermsVersion={() => {
          updateLatestTerms();
          closeModal();
        }}
        onClose={closeModal}
      />,
      // <CustomizeProfileStep
      //   incrementStep={() => setCurrentStep(currentStep + 1)}
      //   hasSolTokens={hasSolTokens}
      //   onClose={closeModal}
      // />,
      // <SonicExplained
      //   incrementStep={() => setCurrentStep(currentStep + 1)}
      //   decrementStep={() => setCurrentStep(currentStep - 1)}
      // />,

      // // TODO: Hide as per Cian's request
      // <AutoDeposit
      //   decrementStep={() => setCurrentStep(currentStep - 1)}
      //   onClose={closeModal}
      // />,

      // ...(
      //   !hasSolTokens
      //     ? [
      //       <SolExplained
      //         incrementStep={() => setCurrentStep(currentStep + 1)}
      //         handleClose={closeModal}
      //       />,
      //       <FundWalletStep onClose={() => closeModal()} />,
      //     ]
      //     : []
      // )
    ];
  }, [currentStep, hasSolTokens]);

  const stepsSelected = useMemo(() => {
    const selected = [];
    for (let i = 0; i < steps.length; i++) {
      if (i > currentStep) {
        selected.push(false);
      } else {
        selected.push(true);
      }
    }

    return selected;
  }, [currentStep, steps]);

  return (
    <BaseModal open={open} hideModal={closeModal}>
      <ModalWrapper steps={stepsSelected}>{steps[currentStep]}</ModalWrapper>
    </BaseModal>
  );
}

interface IModalWrapper {
  steps: boolean[];
  children: any;
}
const bgPositionClass = (idx: number) => {
  if (idx === 0) return "bg-[left_center]";
  if (idx === 1) return "bg-[left_25%_center]";
  if (idx === 2) return "bg-[left_50%_center]";
  if (idx === 3) return "bg-[left_75%_center]";
};

const ModalWrapper = ({ steps, children }: IModalWrapper) => {
  return (
    <div className="flex w-[80vw] md:w-[560px] flex-col items-start gap-y-5 rounded-lg bg-gray-800">
      <div className="flex flex-col justify-center items-center gap-y-6 self-stretch">
        {/*<div className="flex h-[6px] items-start gap-x-2 self-stretch">*/}
        {/*  {steps.map((step, index) => {*/}
        {/*    return (*/}
        {/*      <div*/}
        {/*        key={index}*/}
        {/*        className={twMerge(*/}
        {/*          "flex-1 self-stretch rounded-full bg-[length:400%]",*/}
        {/*          bgPositionClass(index),*/}
        {/*          step ? "bg-brand-mixed-gradient-1 shadow-brand-purple-sm" : "bg-gray-950"*/}
        {/*        )}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}

        {/* UI FOR EACH INDIVIDUAL STEP */}
        {children}
      </div>
    </div>
  );
};
