import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import House from "../sdk/house";
import { ProgramContext } from "./ProgramContext";
import {
  HOUSE_STATUS_TAKING_BETS,
  HOUSE_TOKEN_STATUS_TAKING_BETS,
  getHousePubkey,
} from "../sdk/constants";
import { Program } from "@coral-xyz/anchor";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import HouseToken from "../sdk/houseToken";
import { BalanceContext } from "./BalanceContext";
import { PublicKey } from "@solana/web3.js";
import { NetworkContext } from "./NetworkContext";
import { APP_NETWORK_TYPE } from "../types/chain";
import { ICasinoToken } from "../types/token";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { sendTransaction } from "../sdk/transactions";

export interface IHouseValidation {
  takingBets: boolean;
  tokenTakingBets: boolean
}

export interface IHouseContext {
  house: House | undefined;
  houseLoaded: boolean;
  loadHouse: (program?: Program) => Promise<void>
  houseToken: HouseToken | undefined;
  houseTokens: HouseToken[] | undefined
  houseTokensLoaded: boolean;
  houseTokenByMint: Map<string, HouseToken> | undefined
  houseTokenByPubkey: Map<string, HouseToken> | undefined
  airdropTokens: (amount: number, tokenMint: PublicKey) => Promise<string>
  houseUptoDateWithChain: boolean
}

export const HouseContext = createContext<IHouseContext>({} as IHouseContext);

interface Props {
  children: any;
}

export const HouseProvider = ({ children }: Props) => {
  const [house, setHouse] = useState<House>();
  const [houseTokens, setHouseTokens] = useState<HouseToken[]>()

  const [houseLoaded, setHouseLoaded] = useState(false);
  const [houseTokensLoaded, setHouseTokensLoaded] = useState(false);

  const { meta, isUptoDateWithChain } = useContext(ProgramContext);
  const { selectedTokenMeta } = useContext(BalanceContext)
  const { chain, platformTokens, uptoDateWithChain } = useContext(NetworkContext)

  const [houseUptoDateWithChain, setHouseUptoDateWithChain] = useState<boolean>()
  const [chainLoaded, setChainLoaded] = useState<APP_NETWORK_TYPE>()
  
  const [houseTokenByMint, setHouseTokenByMint] = useState<Map<string, HouseToken>>()
  const [houseTokenByPubkey, setHouseTokenByPubkey] = useState<Map<string, HouseToken>>()
  const [houseToken, setHouseToken] = useState<HouseToken>()

  useEffect(() => {
    setHouseToken(houseTokenByMint?.get(selectedTokenMeta?.mint || ""))
  }, [selectedTokenMeta]);

  // METRICS USED IN VALIDATIONS - KEEP THE STATUS
  const [validation, setValidation] = useState<IHouseValidation>();

  useEffect(() => {
    if (house == null || houseToken == null) {
      return;
    }

    setValidation({
      takingBets: house.status != null && HOUSE_STATUS_TAKING_BETS.includes(house.status),
      tokenTakingBets: houseToken.status != null && HOUSE_TOKEN_STATUS_TAKING_BETS.includes(houseToken.status)
    });
  }, [house, houseToken]);

  const loadHouse = useCallback(async (program?: Program, erProgram?: Program, chainIn?: APP_NETWORK_TYPE) => {
    try {
      setHouseLoaded(false)
      
      const baseProgram = program || meta?.zeebitV2Program
      const rollupProgram = erProgram || meta?.zeebitV2ErProgram
      const chainVal = chainIn || chain

      const housePubkey = getHousePubkey(chainVal)

      const house = await House.load(baseProgram, rollupProgram, housePubkey);

      setHouse(house);
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setHouseLoaded(true);
    }
  }, [meta?.zeebitV2Program, meta?.zeebitV2ErProgram])

  useEffect(() => {
    async function houseLoadAndTokens(baseProgram: Program, rollupProgram: Program, chain: APP_NETWORK_TYPE, tokens: ICasinoToken[]) {
      try {
        setHouseLoaded(false)

        const housePubkey = getHousePubkey(chain)
        const house = await House.load(baseProgram, rollupProgram, housePubkey);
        setHouse(house);

        const houseTokenResults = await Promise.allSettled(tokens.map((token) => {
          return HouseToken.load(house, new PublicKey(token.pubkey))
        }))
  
        // USE ANY THAT ARE FOUND
        let hseTokens: HouseToken[] = []
  
        houseTokenResults.forEach((tokenRes) => {
          if (tokenRes.status == "fulfilled") {
            const hasState = !!tokenRes.value.baseState || !!tokenRes.value.erState
  
            if (hasState == true) {
              hseTokens.push(tokenRes.value)
            }
          }
        })
  
        setHouseTokens(hseTokens)

        const hseTokenByMint = hseTokens?.reduce((result, item) => {
          result.set(item.tokenMintPubkey.toString(), item)
    
          return result
        }, new Map<string, HouseToken>())

        setHouseTokenByMint(hseTokenByMint)

        setHouseTokenByPubkey(hseTokens?.reduce((result, item) => {
          result.set(item.publicKey.toString(), item)
    
          return result
        }, new Map<string, HouseToken>()))

        setHouseToken(hseTokenByMint?.get(selectedTokenMeta?.mint || ""))
      } catch (err) {
        console.warn({
          err
        })
      } finally {
        setHouseLoaded(true)
        setHouseTokensLoaded(true)
        setChainLoaded(chain)
      }
    }
    if (meta == null || meta.zeebitV2ErProgram == null || meta.zeebitV2Program == null || chain == null || isUptoDateWithChain == false || uptoDateWithChain == false) {
      return;
    }
    
    houseLoadAndTokens(meta.zeebitV2Program, meta.zeebitV2ErProgram, chain, platformTokens);
  }, [meta, isUptoDateWithChain, platformTokens, uptoDateWithChain]);

  const errorHandling = useContext(ErrorHandlingContext);

  // VALIDATE THE HOUSE STATUS IS OPEN - Disable button, show message above button
  useEffect(() => {
    if (house == null || validation == null || houseToken == null) {
      return;
    }

    if (validation.takingBets == false) {
      errorHandling.houseValidation.addErrorMessage({
        type: ErrorType.HOUSE_NOT_ACTIVE,
        title: "House not active",
        message: "The house is not currently taking bets.",
      });
    } else {
      errorHandling?.houseValidation?.removeErrorMessage(ErrorType.HOUSE_NOT_ACTIVE);
    }

    if (validation.tokenTakingBets == false) {
      errorHandling.houseValidation.addErrorMessage({
        type: ErrorType.HOUSE_TOKEN_NOT_ACTIVE,
        title: "House token not active",
        message: "The house token is not currently taking bets.",
      });
    } else {
      errorHandling.houseValidation.removeErrorMessage(ErrorType.HOUSE_TOKEN_NOT_ACTIVE);
    }
  }, [validation, house, errorHandling]);

  const { recentBlockhash, client } = useContext(NetworkContext)
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)
  const airdropTokens = useCallback(async (amount: number, tokenMint: PublicKey): Promise<string> => {
    if (house == null || recentBlockhash == null || client == null || walletPubkey == null || solanaRpc == null) throw new Error("House, client, wallet or recentBlockhash is null")

    const ixns = await HouseToken.airdropTokensIxns(house, amount, walletPubkey, tokenMint)

    return await sendTransaction(ixns, client, walletPubkey, true, chain, meta?.errorByCodeByProgram, recentBlockhash, solanaRpc.signTransaction, undefined, undefined, "confirmed");
  }, [house, recentBlockhash, client, walletPubkey, solanaRpc, meta, chain])


  useEffect(() => {
    if (chainLoaded != chain && houseUptoDateWithChain == true) {
      setHouseUptoDateWithChain(false)
    } else if (chainLoaded == chain && houseUptoDateWithChain == false) {
      setHouseUptoDateWithChain(true)
    }
  }, [chain, house, houseTokens])

  return (
    <HouseContext.Provider
      value={useMemo(
        () => ({
          house: house,
          houseLoaded: houseLoaded,
          loadHouse: loadHouse,
          houseToken: houseToken,
          houseTokens: houseTokens,
          houseTokensLoaded: houseTokensLoaded,
          houseTokenByMint: houseTokenByMint,
          airdropTokens: airdropTokens,
          houseTokenByPubkey: houseTokenByPubkey,
          houseUptoDateWithChain: houseUptoDateWithChain
        }),
        [house, houseLoaded, loadHouse, houseToken, houseTokensLoaded, houseTokenByMint, houseTokens, airdropTokens, houseTokenByPubkey, houseUptoDateWithChain],
      )}
    >
      {children}
    </HouseContext.Provider>
  );
};
