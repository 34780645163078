import React, { FC } from "react";

export interface NetworkStatusProps {
  connected: boolean;
  className?: string;
}

const NetworkStatus: FC<NetworkStatusProps> = ({ connected, className }) => {
  return (
    <div className={`flex gap-1.5 text-xs text-gray-400 items-center justify-center ${className}`}>
      Network status:
      <div className="flex gap-1 font-normal text-gray-300 items-center">
        {connected ? (
          <div className="relative mb-[2px] mr-0.5 mt-0.5">
            <span className="w-2 h-2 rounded-full animate-ping absolute inline-flex bg-emerald-500 opacity-25 mt-1 "></span>
            <span className="rounded-full relative inline-flex rounded-full h-2 w-2 bg-emerald-500"></span>
          </div>
        ) : (
          <div className={`w-2 h-2 mb-[2px] rounded-full bg-red-500 mr-0.5 mt-0.5`} />
        )}
        {connected ? "Connected" : "Disconnected"}
      </div>
    </div>
  );
};

export default NetworkStatus;
