import React, { useMemo } from "react";
import { BASE_BUTTON_CLASSES } from "../../../styles/commonClasses";
import IconFont, { IconName, IconSizes } from "../iconFont/IconFont";
import IconUrl from "../icon/Icon";

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: IconButtonSize;
  iconSize?: IconSizes
  iconName?: IconName;
  className?: string;
  iconClass?: string;
  inheritFill?: boolean;
  inheritStroke?: boolean;
  icon?: JSX.Element;
}
export type IconButtonSize = "xl" | "lg" | "md" | "sm" | "xs" | "social";

export const IconButton = ({
  size = "md",
  iconName,
  children,
  className,
  iconClass,
  inheritFill,
  inheritStroke,
  icon,
  iconSize,
  ...props
}: IconButtonProps) => {
  const SIZE_CLASSES = useMemo(() => {
    switch (size) {
      case "xl": {
        return "p-4 rounded-md";
      }
      case "lg": {
        return "p-3 rounded-md";
      }
      case "md": {
        return "p-2 rounded-md";
      }
      case "xs": {
        return "p-1 rounded-lg";
      }
      case "social": {
        return "p-[10px] rounded-lg";
      }
      default: {
        return "p-1.5 rounded-lg";
      }
    }
  }, [size]);
  const ICONS_SIZE: IconSizes = useMemo(() => {
    switch (size) {
      case "xl": {
        return "lg";
      }
      case "lg": {
        return "lg";
      }
      case "md": {
        return "lg";
      }
      case "xs": {
        return "xl";
      }
      case "social": {
        return "xl";
      }
      default: {
        return "xl";
      }
    }
  }, [size]);
  const BUTTON_CLASSES = `
          gap-2
           justify-center
          text-gray-50 bg-gray-700 
          hover:bg-gray-600 
          active:bg-gray-800 
          disabled:text-gray-400 disabled:hover:bg-gray-700`;

  return (
    <button
      type="button"
      className={`${BASE_BUTTON_CLASSES} ${BUTTON_CLASSES} ${SIZE_CLASSES} ${className}`}
      {...props}
    >
      {iconName && <IconFont size={iconSize || ICONS_SIZE} className={`${iconClass} `} name={iconName} />}
      {icon && (
        <IconUrl
          size={iconSize || ICONS_SIZE}
          inheritFill={inheritFill}
          inheritStroke={inheritStroke}
          className={`${iconClass} `}
          icon={icon}
        />
      )}
    </button>
  );
};
export default IconButton;
