import React, { FC } from "react";

export interface TagProps {
  title: string;
  type: "new" | "soon";
}

const Tag: FC<TagProps> = ({ title, type }) => {
  return (
    <div
      className={`px-1.5 py-[2px] rounded uppercase
  ${
    type === "new"
      ? "bg-brand-mixed-gradient text-gray-50 text-xs shadow-sm"
      : "bg-gray-700 text-gray-300 text-xs"
  }
  
  `}
    >
      {title}
    </div>
  );
};

export default Tag;
