import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchAuthorize,
  fetchBasicInfo,
  fetchLogout,
  getUserRewardInfo,
} from "../data/sonic/account";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { useWallet } from "@solana/wallet-adapter-react";
import { encodeBase64 } from "tweetnacl-util";
import { WalletList } from "../data/sonic/wallet-list";
import { ENV_NAME } from "../utils/env/env";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AggregatedBalancesContext } from "./AggregatedBalancesContext";
import { loadPlayerPoints } from "../utils/supabase/supabase";
import { APP_NETWORK_TYPE } from "../types/chain";
import { BalanceContext } from "./BalanceContext";
import { NetworkContext } from "./NetworkContext";
import { IS_MAINNET } from "../admin/sdk/constants";

export interface ISonicContext {
  sonicAvailable: boolean;
  refreshInfo: () => void;
  logout: () => void;
  token: string;
  networkId: string;
  mysteryBoxAmount: number;
  ringAmount: number;
  playerZeebitPoints: number;
  showSonicNotification: boolean;
  setShowSonicNotification: (value: boolean) => void;
}

export const SonicContext = createContext<ISonicContext>({} as ISonicContext);

interface Props {
  children: any;
}

let lastAddress = "";
let currentSignature = "";
let currentToken = "";
let messageToSign = "";
let isWhitelist = false;

export const SonicProvider = ({ children }: Props) => {
  const { walletPubkey, solanaRpc, disconnect, wallet } = useContext(WrappedWalletContext);
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { selectedTokenMeta } = useContext(BalanceContext);

  const [sonicAvailable, setSonicAvailable] = useState(false);

  const [signature, setSignature] = useState("");

  const [ringAmount, setRingAmount] = useState<null | number>(null);
  const [mysteryBoxAmount, setMysteryBoxAmount] = useState<null | number>(null);

  const [playerZeebitPoints, setPlayerZeebitPoints] = useState(0);

  const networkId = ENV_NAME === "MAINNET" ? "mainnet" : "testnet-v1";

  const { chain } = useContext(NetworkContext)

  // CURRENTLY WE ONLY LOAD THE REWARDS ETC ON TESTNET
  const loadSonic = useMemo(() => {
    return chain == APP_NETWORK_TYPE.SONIC && IS_MAINNET == false
  }, [chain])

  useEffect(() => {
    if (sonicAvailable == true) {
      return
    } else if (loadSonic == false && sonicAvailable == false) {
      setSonicAvailable(false)
    }
  }, [sonicAvailable, loadSonic])

  const [tokens, setTokens] = useLocalStorage("sonic-tokens", {});
  const [showSonicNotification, setShowSonicNotification] = useLocalStorage(
    "sonic-notification",
    false
  );

  const token = useMemo(() => {
    if (walletPubkey && tokens) {
      return tokens[walletPubkey?.toString()];
    }
  }, [tokens, walletPubkey]);

  const {
    data: dataBasicInfo,
    isLoading: loadingBasicInfo,
    refetch: refetchBasicInfo,
  } = useQuery({
    queryKey: ["queryBasicInfo", walletPubkey?.toString()],
    queryFn: () =>
      fetchBasicInfo({
        address: walletPubkey?.toString(),
        source: WalletList.find(
          (wallet: any) => wallet.name === wallet?.adapter.name
        )?.id,
        networkId,
      }),

    enabled: false,
  });

  const {
    data: dataAuthorize,
    isLoading: loadingAuthorize,
    refetch: refetchAuthorize,
  } = useQuery({
    queryKey: ["queryAuthorize", walletPubkey?.toString()],
    queryFn: () =>
      fetchAuthorize({
        address: walletPubkey?.toString(),
        address_encoded: encodeBase64(walletPubkey!.toBytes()),
        signature: currentSignature || signature,
        networkId,
      }),
    enabled: false,
  });

  const {
    data: dataRewardsInfo,
    isLoading: loadingRewardsInfo,
    refetch: refetchRewardsInfo,
    isError: dataAuthorizeIsError,
    error: dataAuthorizeError,
  } = useQuery({
    queryKey: ["queryUserRewardsInfo", walletPubkey?.toString()],
    queryFn: () => getUserRewardInfo({ token, networkId }),
    enabled: !!walletPubkey && !!token,
  });

  const selectedToken = mergedTokens?.find(
    (mergedToken) => mergedToken?.context?.pubkey === selectedTokenMeta.mint
  );
  const playBalanceBasis = selectedToken?.playerToken?.baseState
    ?.availableBalance
    ? Number(selectedToken.playerToken?.baseState?.availableBalance)
    : 0;

  const {
    data: loadedPlayerPoints,
    error: playerPointsLoadingError,
    isLoading: isPlayerPointsLoading,
  } = useQuery({
    queryKey: ["loadPlayerPoints", playBalanceBasis, walletPubkey?.toString()],
    queryFn: async () => {
      const data = await loadPlayerPoints({
        chain: "SONIC" as APP_NETWORK_TYPE,
        owner: walletPubkey?.toString(),
      })

      const zeebroPoints = data?.data.find(
        (pointsObj) => pointsObj.owner === walletPubkey?.toString()
      )?.points;

      setPlayerZeebitPoints(zeebroPoints || 0);

      return data;
    },
    enabled: !!walletPubkey,
  });

  const mutationLogout = useMutation({
    mutationFn: () => fetchLogout({ token, networkId }),
    onSuccess: () => {
      setSonicAvailable(false);
    },
  });

  const sign = async () => {
    try {
      if (!solanaRpc?.signMessage) {
        console.log("signMessage function is not available");
        return;
      }

      const message = new TextEncoder().encode(messageToSign);
      const uint8arraySignature = await solanaRpc?.signMessage(message);
      const signature = encodeBase64(uint8arraySignature);
      currentSignature = signature;
      messageToSign = "";
      setSignature(currentSignature);
      refetchAuthorize();
    } catch (e) {
      console.log("could not sign message", e);
      currentToken = "";
      // disconnect();
    }
  };

  const handleToLogout = () => {
    mutationLogout.mutate();
  };

  useEffect(() => {
    if (loadSonic == false) {
      return
    }

    if (dataBasicInfo?.data && !token) {
      if (messageToSign === dataBasicInfo.data) {
        return;
      }
      messageToSign = dataBasicInfo.data;
      const isNoToken = !token && !currentToken;
      const isNewAddress = walletPubkey?.toString() !== lastAddress;
      if (messageToSign && (isNoToken || isNewAddress)) {
        sign();
      }
    }
  }, [dataBasicInfo, loadSonic]);

  useEffect(() => {
    if (loadSonic == false) {
      return
    }

    if (dataAuthorize) {
      // not in whitelist
      if (dataAuthorize.code === 100027) {
        // setIsInWhitelist(false);
        isWhitelist = false;
      }

      if ((dataAuthorize.data?.token, walletPubkey)) {
        currentToken = dataAuthorize?.data?.token;

        setTokens({
          ...tokens,
          [walletPubkey?.toString()]: currentToken,
        });
        setSonicAvailable(true);
        // setIsInWhitelist(true);
        isWhitelist = true;
      }

      // open tip dilogs
      //   afterWalletConnected();
    }
  }, [JSON.stringify(dataAuthorize), loadSonic]);

  useEffect(() => {
    if (loadSonic == false) {
      return
    }

    if (token) {
      setSonicAvailable(true);
      refetchRewardsInfo();
    }
  }, [token, loadSonic]);

  useEffect(() => {
    if (loadSonic == false) {
      return
    }

    const data = dataRewardsInfo?.data;

    if (dataRewardsInfo?.code === 401) {
      setTokens({
        ...tokens,
        [walletPubkey?.toString()]: "",
      });
      refetchBasicInfo();
    }
    if (data) {
      const { ring, ring_monitor } = data;
      // if (data.ring_monitor !== mysteryBoxAmount && mysteryBoxAmount !== null) {
      //   setShowSonicNotification(true);
      // }
      setRingAmount(ring);
      setMysteryBoxAmount(ring_monitor);
    }
  }, [dataRewardsInfo, ringAmount, mysteryBoxAmount, loadSonic]);

  useEffect(() => {
    if (loadSonic == false) {
      return
    }

    if (walletPubkey && !token) {
      refetchBasicInfo();
    }
  }, [walletPubkey, token, loadSonic]);

  useEffect(() => {
    if (loadSonic == false) {
      return
    }
    
    if (!walletPubkey || !token) {
      setSonicAvailable(false);
    }
  }, [walletPubkey, token, loadSonic]);

  return (
    <SonicContext.Provider
      value={useMemo(
        () => ({
          logout: handleToLogout,
          sonicAvailable: sonicAvailable,
          refreshInfo: refetchRewardsInfo,
          token: token,
          networkId: networkId,
          ringAmount: ringAmount || 0,
          mysteryBoxAmount: mysteryBoxAmount || 0,
          playerZeebitPoints,
          showSonicNotification,
          setShowSonicNotification,
        }),
        [
          showSonicNotification,
          setShowSonicNotification,
          token,
          networkId,
          mysteryBoxAmount,
          ringAmount,
          sonicAvailable,
          handleToLogout,
          playerZeebitPoints,
        ]
      )}
    >
      {children}
    </SonicContext.Provider>
  );
};
