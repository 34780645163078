import { PublicKey } from "@solana/web3.js";

import { 
    TRAITS_BACKGROUND_NAMES, TRAITS_CLOTHES_NAMES, 
    TRAITS_EYES_AND_ACCESSORIES_NAMES, TRAITS_HEADWEAR_NAMES, 
    TRAITS_MOUTH_NAMES, ATTRIBUTE_NAMES
} from "./zeebrosCollection";
import NftStaking from "./nftStaking";
import Trait from "./trait";

export default class Attribute {

    private _nftStakingInstance: NftStaking;
    private _attributeId: number;
    private _state: any;
    private _publicKey: PublicKey;
    private _traits: Trait[];

    constructor( 
        nftStakingInstance: NftStaking,
        attributeId: number,
        pubkey: PublicKey,
        state: any
    ) {
        this._nftStakingInstance = nftStakingInstance;
        this._attributeId = attributeId;
        this._publicKey = pubkey;
        this._state = state;
        // console.log(state);
        this._traits = state.traits?.map((t: any, i)=>{return new Trait(this, i ,t)});
    };

    static loadFromState(
        nftStakingInstance: NftStaking,
        attributeId: number,
        pubkey: PublicKey,
        state: any
    ) {
        const attribute = new Attribute(
            nftStakingInstance,
            attributeId,
            pubkey,
            state,
        );
        return attribute;
    }

    get nftStakingInstance() {
        return this._nftStakingInstance
    }
    get id() {
        return this._state.attributeId
    }

    get countTraits() {
        return this._state.countTraits
    }

    get countStaked() {
        return this._state.countStaked
    }
    
    get countTimesSelected() {
        return Number(this._state.countTimesSelected)
    }

    get pastRewardsAmount() {
        return Number(this._state.pastRewardsAmount)
    }

    get probabilityTraitSelectedPostAttributeSelection(): number | null {
        if (this._nftStakingInstance.currentDistributionAttributeIsSelected) {
            if (this._nftStakingInstance.currentDistributionSelectedAttribute.id == this._attributeId) {
                return this.probabilityTraitSelectedGivenAttribute
            } else {
                return 0
            }
        } else {
            return null
        }
    }

    get probabilityTraitSelectedPreAttributeSelection(): number | null {
        if (this._nftStakingInstance.currentDistributionAttributeIsSelected) {
            return null
        } else {
            return this.probabilityAttributeSelected * this.probabilityTraitSelectedGivenAttribute
        }
    }

    get probabilityTraitSelectedGivenAttribute(): number {
        return this.countTraits ? (1 / this.countTraits) : 0
    }

    get probabilityAttributeSelected(): number {
        return this.nftStakingInstance.countAttributes ? (1 / this.nftStakingInstance.countAttributes) : 0
    }

    get probabilityOfMostCommonTrait(): number {
        return Math.max(...this.traits.map((trait) => trait.proportionPopulation))
    }

    get traits() {
        return this._traits
    }

    get nameMapping() {
         switch(this._attributeId) {
            case 0:
              return TRAITS_BACKGROUND_NAMES
            case 1:
              return TRAITS_EYES_AND_ACCESSORIES_NAMES
            case 2:
                return TRAITS_HEADWEAR_NAMES
            case 3:
                return TRAITS_CLOTHES_NAMES
            case 4:
              return TRAITS_MOUTH_NAMES
        };
    }

    get name() {
        return ATTRIBUTE_NAMES[this._attributeId]
    }
}
