import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { twMerge } from "tailwind-merge";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { ModalProps } from "./types";
import { BaseModal, Button, IconFont, Tooltip } from "../common";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import {
  formatZeebitNumber,
  NumberType,
} from "../../utils/currency/formatting";
import { AutoSigningAgreementModal, IFundOrWithdrawModalTabs } from "./AddFundsAndPlayModal";
import {
  AggregatedBalancesContext,
  IMergedToken,
} from "../../contexts/AggregatedBalancesContext";
import { AirdropModal } from "../../modals/AirdropModal";
import { useTokenAirdrop } from "../../hooks/useTokenAirdrop";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import RewardsModal, { RewardsModalTabs } from "../sonic/sonic-rewards/rewards-modal/RewardsModal";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AutoSigningModal } from "../../modals/auto-sign/AutoSigningModal";
import { TransferToAutoSignBalanceModal } from "./TransferToAutoSignBalanceModal";
import { WrappedWalletContext, BalanceContext, NetworkContext } from "../../contexts";
import { AutoSigningNeedsMoreSolModal } from "../../modals/auto-sign/AutoSigningNeedsMoreSolModal";
import { APP_NETWORK_TYPE, NetworkType } from "../../types/chain";
import { SonicBridgeTransferModal } from "./SonicBridgeTransferModal";
import { defaultNetwork, NetworkType as DefaultNetworkType } from "../../utils/chain/network";
import Spinner from "../common/spinner/Spinner";
import { showAutoSigningNetworks } from "../../contexts/NetworkContext";

interface ISelectTokenModalProps extends ModalProps {
  showFundsAndPlayModal: Function;
}

export const ZEEBIT_SHOULD_HIDE_AUTO_SIGNING_MODAL =
  "zeebit-should-hide-auto-signing-modal";

export const FundsModal: FC<ISelectTokenModalProps> = ({
  visible,
  hideModal,
  showFundsAndPlayModal,
}) => {
  const { setSelectedTokenMeta, selectedTokenMeta } =
    useContext(BalanceContext);
  const {
    allowsAutoSigning, setAllowsAutoSigning, withdrawSol, lamportBalance
  } = useContext(SessionAuthorityContext);
  const [isAutoSignSolWithdrawing, setIsAutoSignSolWithdrawing] = useState(false)
  const { isWeb3AuthWallet } = useContext(WrappedWalletContext);
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { solBalances } = useContext(BalanceContext);
  
  const hasSolInMainWallet = useMemo(() => {
    return (solBalances?.native?.basis || 0) > 0
  }, [solBalances])
  const hasSolInAutoSigner = useMemo(() => {
    return (lamportBalance || 0) > 0
  }, [lamportBalance])
  const hasBalanceToDeposit = useMemo(() => {
    return (solBalances?.primary?.basis || 0) > 0
  }, [solBalances])

  const [shouldHideAutoSigningModal, setShouldHideAutoSigningModal] =
    useLocalStorage(ZEEBIT_SHOULD_HIDE_AUTO_SIGNING_MODAL, false);

  const [showAutoSigningModal, hideAutoSigningModal] = useModal(
    ({ in: open }) => (
      <AutoSigningModal
        visible={open}
        hideModal={hideAutoSigningModal}
        shouldHideAutoSigningModal={shouldHideAutoSigningModal}
        setShouldHideAutoSigningModal={setShouldHideAutoSigningModal}
        showFundsAndPlayModal={showFundsAndPlayModal}
      />
    ),
    [setShouldHideAutoSigningModal, shouldHideAutoSigningModal, showFundsAndPlayModal]
  );

  const [showSonicRewardsModal, hideSonicRewardsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <RewardsModal
        tab={RewardsModalTabs.CheckIn}
        visible={open}
        hideModal={hideSonicRewardsModal}
      />
    ),
    []
  );

  const [showTransferToAutoSignBalanceModal, hideTransferToAutoSignBalanceModal] = useModal(
    ({ in: open }) => (
      <TransferToAutoSignBalanceModal
        visible={open}
        hideModal={() => {
          hideTransferToAutoSignBalanceModal();
        }}
      />
    ),
    []
  );

  const [showAutoSigningNeedsMoreSolModal, hideAutoSigningNeedsMoreSolModal] = useModal(
    ({ in: open }) => (
      <AutoSigningNeedsMoreSolModal
        visible={open}
        hideModal={() => {
          hideAutoSigningNeedsMoreSolModal();
        }}
      />
    ),
    []
  );

  // ENSURE ONLY SHOWN WHEN NOT WEB3 AUTH WALLET
  const [showAutoSigningAgreementModal, hideAutoSigningAgreementModal] =
    useModal(
      ({ in: open }) => (
        <AutoSigningAgreementModal
          visible={open}
          hideModal={hideAutoSigningAgreementModal}
          onAutoSigningApprove={() => setAllowsAutoSigning(true)}
          onModalsClose={hideModal}
        />
      ),
      [setAllowsAutoSigning]
    );

  // MERGED TOKENS FOR THE LIST

  const tokens = useMemo(() => {
    return mergedTokens?.map((token) => {
      const playBalanceBasis = token.playerToken?.playBalance
        ? token.playerToken?.playBalance
        : 0;
      const playBalanceUi =
        playBalanceBasis / Math.pow(10, token.context?.decimals || 6);
      const status = token.playerToken != null ? "active" : "ready";

      return {
        ...token,
        icon: token.context?.imageDarkSvg,
        name: token.context?.name,
        walletBalance: token.wallet?.uiAmount,
        playBalance: playBalanceUi,
        status: status,
      };
    });
  }, [mergedTokens, selectedTokenMeta]);

  const [selectedToken, setSelectedToken] = useState<IMergedToken | undefined>();

  useEffect(() => {
    if (selectedTokenMeta != null && selectedTokenMeta.mint != selectedToken?.context?.pubkey) {
      const updatedSelectedToken = tokens?.find(
        (token) => token?.context?.pubkey === selectedTokenMeta?.mint
      );

      setSelectedToken(updatedSelectedToken)
    }
  }, [selectedTokenMeta])

  const isUpdatingBalance = useMemo(() => {
    return selectedToken?.isUpdatingBalance || false
  }, [selectedToken]);

  const withdrawDisabled = useMemo(() => {
    return selectedToken == null || selectedToken.playerToken == null || (selectedToken.playerToken.playBalance || 0) <= 0;
  }, [selectedToken]);

  const depositDisabled = useMemo(() => {
    return selectedToken == null || selectedToken.wallet == null || (selectedToken.wallet.uiAmount || 0) <= 0;
  }, [selectedToken]);

  const [showAirdropModal, hideWalletModal] = useModal(
    ({ in: open }) => (
      <AirdropModal visible={open} hideModal={hideWalletModal} />
    ),
    []
  );

  const { handleAirdrop } = useTokenAirdrop();
  const [
    airdroppingToken, setAirdroppingToken
  ] = useState<string | null>(null);

  const { chain } = useContext(NetworkContext)
  const [shouldShowScrollHeader, setShouldShowScrollHeader] = useState(false);

  const [showSonicBridgeTransferModal, hideSonicBridgeTransferModal] = useModal(
    ({ in: open }) => (
      <SonicBridgeTransferModal
        visible={open}
        hideModal={() => {
          hideSonicBridgeTransferModal();
        }}
      />
    ),
    [mergedTokens]
  );

  const shouldShowSonicBridgeSwitcher = localStorage.getItem('zeebit-test-features');

  return (
    <BaseModal
      title="Funds"
      open={visible}
      onClose={() => {
        hideModal();
        hideAutoSigningAgreementModal();
      }}
      classes={{
        dialog: `w-full sm:max-w-[580px] bg-gray-700 p-5`,
      }}
    >
      <div
        data-id="funds-modal"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col items-center gap-y-2  bg-gray-800 rounded-lg">
            <div
              className={twMerge(
                "flex w-full text-gray-50 font-normal text-sm pt-4 [&>div>img]:transition-all [&>div>img]:duration-500",
                shouldShowScrollHeader ? "[&>div>img]:w-7 [&>div>*]:translate-x-0 [&>div>*]:translate-y-0 [&>div>img]:mt-0" : ""
              )}
            >
              <div className="flex w-full justify-center items-center gap-x-2 gap-y-1">
                <img
                  src={getS3StaticFolderUrl("/static/wallet-icon.png")}
                  className="flex w-1/4 translate-x-10 -translate-y-1/2 mt-8"
                />
                <div className=" flex justify-center items-center -translate-x-1/2 translate-y-8">
                  <div className="truncate">WALLET</div>
                  <Tooltip key="information" content={"Wallet balance"} className="ml-1 -mt-0.5">
                    <IconFont name="information" size="md" />
                  </Tooltip>
                </div>
              </div>
              <div className="flex w-full justify-center items-center gap-x-2 gap-y-1">
                <img
                  src={getS3StaticFolderUrl("/static/play-chips-icon.png")}
                  className="flex w-1/4 translate-x-16 -translate-y-1/2 mt-8"
                />
                <div className="flex justify-center items-center -translate-x-10 translate-y-8">
                  <div className="truncate">PLAY BALANCE</div>
                  <Tooltip key="information" content={"Play balance"} className="ml-1 -mt-0.5">
                    <IconFont name="information" size="md" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div
              className={twMerge(
                "flex flex-col overflow-y-auto w-full p-3 pt-0 ",
                shouldShowScrollHeader || (tokens?.length || 0) <= 3 ? "max-h-56" : "max-h-40",
                SCROLLBAR_CLASSES_BLACK
              )}
              onScroll={(e) => {
                setShouldShowScrollHeader((e.target as HTMLElement).scrollTop > 10);
              }}
            >
              {tokens?.map((item) => (
                <button
                  key={item.context?.pubkey}
                  className={twMerge(
                    "flex w-full rounded-md py-3 px-1 [&>div]:flex [&>div]:justify-center",
                    "hover:bg-gray-700 items-center border-2 border-transparent h-[64px]",
                    item.context?.pubkey === selectedToken?.context?.pubkey ? "bg-gray-600" : "",
                    (item.combined?.basis || 0) > 0 ? "" : "text-gray-300",
                  )}
                  disabled={item.isUpdatingBalance}
                  onClick={() => {
                    setSelectedToken(item);
                    setSelectedTokenMeta({
                      mint: item?.context?.pubkey || "",
                      decimals: item?.context?.decimals || 6,
                    });
                  }}
                >
                  <div className="flex gap-2 items-center w-full">
                    <img
                      src={item.icon}
                      className="w-[24px] h-[24px] rounded-full relative -top-0.5"
                      alt="token"
                    />
                    <div className="truncate shrink">{
                      formatZeebitNumber(
                        item.walletBalance || 0,
                        NumberType.TOKEN_AMOUNT,
                        9,
                        item?.context?.decimals
                      )
                    }</div>
                  </div>

                  {item.isUpdatingBalance ?
                    <Spinner />
                    : <Button
                      variant="secondary-gray"
                      className="bg-gray-600"
                      disabled={(item.combined?.basis || 0) == 0}
                      onClick={() => {
                        setSelectedTokenMeta({
                          mint: item?.context?.pubkey || "",
                          decimals: item?.context?.decimals || 6,
                        });
                        showFundsAndPlayModal(
                          item.playBalance && !item.walletBalance
                            ? IFundOrWithdrawModalTabs.WITHDRAW
                            : IFundOrWithdrawModalTabs.DEPOSIT
                        );
                        hideModal();
                      }}
                    >
                      <IconFont name="transactions" size="xl" className="rotate-90" />
                    </Button>}

                  <div className="flex gap-2 items-center w-full">
                    <img
                      src={item.icon}
                      className="w-[24px] h-[24px] rounded-full relative -top-0.5"
                      alt="token"
                    />
                    <div className="truncate shrink">{
                      formatZeebitNumber(
                        item.playBalance,
                        NumberType.TOKEN_AMOUNT,
                        9,
                        item?.context?.decimals
                      )
                    }</div>
                  </div>
                </button>
              ))}
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3">
            {
              selectedToken?.combined?.uiAmount && NetworkType.DEVNET
                ? (
                  <div className="flex w-full gap-x-3">
                    <Button
                      disabled={depositDisabled || !!airdroppingToken || isUpdatingBalance}
                      variant={isUpdatingBalance ? "gray" : selectedToken?.playerToken?.playBalance == 0 ? "primary" : "secondary"}
                      className="w-full"
                      size="md"
                      onClick={() => {
                        setSelectedTokenMeta({
                          mint: selectedToken?.context?.pubkey || "",
                          decimals: selectedToken?.context?.decimals || 6,
                        });
                        showFundsAndPlayModal(IFundOrWithdrawModalTabs.DEPOSIT);
                        hideModal();
                      }}
                    >
                      <div className="flex">
                        {
                          selectedToken?.playerToken?.playBalance == 0
                            ? isUpdatingBalance ? "Updating" : "Deposit"
                            : (
                              <><IconFont name="download" size="lg" />&nbsp; Add</>
                            )
                        }
                        &nbsp; {selectedToken?.context?.symbol}
                        {isUpdatingBalance ? "..." : null}
                      </div>
                    </Button>
                    {
                      selectedToken?.playerToken?.playBalance == 0 || isUpdatingBalance
                        ? ""
                        : (
                          <Button
                            variant={isUpdatingBalance ? "gray" : "secondary"}
                            className="w-full"
                            size="md"
                            onClick={() => {
                              setSelectedTokenMeta({
                                mint: selectedToken?.context?.pubkey || "",
                                decimals: selectedToken?.context?.decimals || 6
                              });
                              showFundsAndPlayModal(IFundOrWithdrawModalTabs.WITHDRAW);
                              hideModal();
                            }}
                            disabled={withdrawDisabled || !!airdroppingToken || isUpdatingBalance}
                          >
                            <div className="flex">
                              <IconFont name="withdraw" size="lg" />
                              &nbsp; Withdraw {selectedToken?.context?.symbol}
                            </div>
                          </Button>
                        )
                    }
                  </div>
                )
                : (defaultNetwork !== DefaultNetworkType.MAINNET ? (
                  <Button
                    disabled={!selectedToken || !!airdroppingToken}
                    variant="secondary"
                    className="w-full"
                    size="sm"
                    isLoading={selectedToken?.context?.symbol === airdroppingToken}
                    onClick={async () => {
                      if (selectedToken?.context?.symbol === "SOL") {
                        showAirdropModal();
                        hideModal();
                      } else if (selectedToken?.context?.symbol === "RINGS") {
                        showSonicRewardsModal();

                        hideModal();
                      } else {
                        setAirdroppingToken(selectedToken?.context?.symbol);

                        await handleAirdrop(
                          mergedTokens?.find(
                            walletToken => walletToken?.context?.symbol === selectedToken?.context?.symbol
                          )
                        );

                        setAirdroppingToken(null);
                      }
                    }}
                  >
                    {
                      selectedToken?.context?.symbol !== "RINGS"
                        ? `${selectedToken?.context?.symbol} Faucet`
                        : "Earn RINGS"
                    }
                  </Button>
                ) : selectedToken != null ? <Button
                disabled={!selectedToken || !!airdroppingToken || !((solBalances?.primary?.basis || 0) > 0)}
                variant="secondary"
                className="w-full"
                size="sm"
                onClick={() => {
                  showFundsAndPlayModal(IFundOrWithdrawModalTabs.DEPOSIT)
                }}
              >
                {
                  `Deposit ${selectedToken?.context?.name}`
                }
              </Button>: '')
            }
          </div>

          {/*SONIC-BRIDGING*/}
          {
            chain === APP_NETWORK_TYPE.SONIC
            && shouldShowSonicBridgeSwitcher
            && (
              <div className="flex w-full rounded-lg bg-gray-600 py-3 px-4 gap-4 items-center">
                <div className={allowsAutoSigning ? "flex w-1/12" : "flex w-1/5"}>
                  <img src={getS3StaticFolderUrl("/static/auto-signing-icon.png")} className="flex w-full" />
                </div>
                <div className="flex flex-col text-sm gap-1">
                  <div className="font-semibold">
                    Bridge between Solana and Sonic
                  </div>
                  <div className="font-normal text-gray-200">
                    Here you have possibility to transfer your tokens between networks
                  </div>
                </div>
                <div className="flex items-center">
                  <>
                    <Button
                      disabled={false}
                      variant="gray"
                      className="w-full bg-gray-500"
                      size="md"
                      isLoading={false}
                      onClick={showSonicBridgeTransferModal}
                    >
                      Bridge
                    </Button>
                  </>
                </div>
              </div>
            )
          }

          {/* EMPTY WALLET */}
          {
            hasSolInMainWallet == false ? <>
            <div className="flex w-full rounded-lg bg-amber-900 py-3 px-4 gap-4 items-center text-amber-300 text-sm">
              
                <span>
                  <span className="font-semibold">Note:</span> <span className="font-normal">Looks like your wallet is empty. Please add funds to your wallet before playing.</span>
                </span>
              
            </div></>: ''
          }

          {/* AUTO-SIGNING */}
          {
            showAutoSigningNetworks.includes(chain)
            && !isWeb3AuthWallet
            && (hasSolInAutoSigner || hasSolInMainWallet)
            && (
              <div className="flex w-full rounded-lg bg-gray-600 py-3 px-4 gap-4 items-center justify-between">
                <div className={allowsAutoSigning ? "flex w-1/12" : "flex w-1/5"}>
                  <img src={getS3StaticFolderUrl("/static/auto-signing-icon.png")} className="flex w-full" />
                </div>
                <div className="flex flex-col text-sm gap-1">
                  <div className="font-semibold">
                    {allowsAutoSigning ? "Auto-Signing Keys" : "Auto-Signing"}
                  </div>
                  <div className="font-normal text-gray-200">
                    {
                      allowsAutoSigning
                        ? `${Number(lamportBalance || 0) / LAMPORTS_PER_SOL} SOL`
                        : "Play without signing each bet transaction. Requires SOL and funds in ‘Play Balance’."
                    }
                  </div>
                </div>
                <div className="flex flex-col gap-y-2 sm:gap-x-2 sm:flex-row items-center">
                  {
                    allowsAutoSigning
                      ? (<>
                        <Button
                          disabled={isAutoSignSolWithdrawing}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={false}
                          onClick={() => {
                            showTransferToAutoSignBalanceModal();
                            hideModal();
                          }}
                        >
                          <div className="whitespace-nowrap">Top Up</div>
                        </Button>
                        <Button
                          disabled={isAutoSignSolWithdrawing}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={isAutoSignSolWithdrawing}
                          onClick={async () => {
                            setIsAutoSignSolWithdrawing(true);

                            try {
                              await withdrawSol();
                            } catch (err) {
                              console.warn({
                                err
                              })
                            } finally {
                              setIsAutoSignSolWithdrawing(false);
                            }


                          }}
                        >
                          <div className="whitespace-nowrap">Withdraw</div>
                        </Button>
                        {/* CANT TURN OFF IF ON MB CHAIN */}
                        <Button
                          disabled={isAutoSignSolWithdrawing || chain == APP_NETWORK_TYPE.MB_AND_SOLANA}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={isAutoSignSolWithdrawing}
                          onClick={async () => {
                            if (lamportBalance > 0) {
                              setIsAutoSignSolWithdrawing(true);

                              try {
                                await withdrawSol();
                              } catch (err) {
                                console.warn({
                                  err
                                })
                              } finally {
                                setIsAutoSignSolWithdrawing(false);
                              }
                            }

                            setAllowsAutoSigning(false)
                          }}
                        >
                          <div className="whitespace-nowrap">Turn off</div>
                        </Button>
                      </>)
                      : (<>
                        <Button
                          disabled={false}
                          variant="gray"
                          className="w-full bg-gray-500"
                          size="md"
                          isLoading={false}
                          onClick={() => {
                            setAllowsAutoSigning(true)
                            
                            if (!lamportBalance || lamportBalance < 5000) {
                              showAutoSigningNeedsMoreSolModal();
                            } else {
                              showAutoSigningModal();
                            }
                            hideModal();
                          }}
                        >
                          <div className="whitespace-nowrap">Turn on</div>
                        </Button>
                      </>)
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    </BaseModal>
  );
};
