import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useContext, useCallback, useMemo } from "react";
import { Airdrop } from "../components/toast/airdrop/Airdrop";
import { AggregatedBalancesContext, IMergedToken } from "../contexts/AggregatedBalancesContext";
import { NetworkContext } from "../contexts/NetworkContext";
import { PlayerContext } from "../contexts/PlayerContext";
import { ToasterContext } from "../contexts/ToasterContext";
import SVG from "react-inlinesvg";
import SolIcon from "../assets/icons/sol.svg";
import { BASE_TOAST_CONFIG } from "../components/toast/BaseToast";
import { requestAndConfirmAirdropSol } from "../utils/solana/utils";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";
import { HouseContext } from "../contexts";
import { LAMPORTS_IN_TOKEN_ACCOUNT } from "../sdk/constants";

export const useTokenAirdrop = () => {
  const { client } = useContext(NetworkContext);
  const { solanaRpc, walletPubkey } = useContext(WrappedWalletContext);
  const { solBalances } = useContext(AggregatedBalancesContext);
  const toast = useContext(ToasterContext);
  const { airdropTokens } = useContext(HouseContext)

  const airdropSol = useCallback(
    async (amount: number) => {
      if (solanaRpc == null) {
        throw new Error("Issue with solana rpc");
      } else if (client == null) {
        throw new Error("Issue with solana client");
      } else if (walletPubkey == null) {
        throw new Error("Wallet not connected");
      }
      return await requestAndConfirmAirdropSol(client, walletPubkey, amount);
    },
    [walletPubkey, client, solanaRpc],
  );

  const showAirdropToast = (
    type: "success" | "error",
    token?: { icon: any; amount: number },
    message?: string,
  ) => {
    toast(<Airdrop type={type} token={token} message={message} />, BASE_TOAST_CONFIG);
  };

  const airdropSolWithCallback = useCallback(
    async (
      onStart: Function | undefined,
      onSuccess: Function | undefined,
      onError: Function | undefined,
    ) => {
      if (onStart) {
        onStart();
      }

      try {
        await airdropSol(LAMPORTS_PER_SOL);
        showAirdropToast("success", {
          icon: <SolIcon />,
          amount: 1,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.warn(err);
        showAirdropToast(
          "error",
          undefined,
          "Issue airdropping sol. There is a rate limit on airdrops, and may be the issue here.",
        );
        if (onError) {
          onError();
        }
      }
    },
    [airdropSol],
  );

  const handleAirdrop = useCallback(
    async (
      selectedToken: IMergedToken | undefined,
      onStart?: Function | undefined,
      onError?: Function | undefined,
      onSuccess?: Function | undefined,
    ) => {
      if (onStart != null) {
        onStart();
      }

      try {
        const isRealSol = selectedToken?.context?.isNative == true;
        if (isRealSol == true) {
          try {
            await airdropSol(LAMPORTS_PER_SOL);
          } catch (err) {
            showAirdropToast(
              "error",
              undefined,
              "Issue airdropping sol. There is a rate limit on airdrops, and may be the issue here.",
            );
            if (onError != null) {
              onError();
            }

            return;
          }

          showAirdropToast("success", {
            icon: <SVG height={16} width={16} src={selectedToken?.context?.imageDarkSvg || ""} />,
            amount: 1,
          });
          if (onSuccess != null) {
            onSuccess();
          }
        } else {
          const solBalance = solBalances?.native?.basis || 0;
          if (solBalance < 5000) {
            try {
              await airdropSol(LAMPORTS_PER_SOL);
            } catch (err) {
              showAirdropToast(
                "error",
                undefined,
                "Issue airdropping sol. There is a rate limit on airdrops, and may be the issue here.",
              );

              if (onError != null) {
                onError();
              }

              return;
            }
          }
          const amountUI = selectedToken?.context?.airdrop || 100;
          const amountBasis = amountUI * Math.pow(10, selectedToken?.context?.decimals || 6);

          // CHECK ENOUGH SOL FOR ACCOUNT
          if ((selectedToken?.combined?.basis || 0) == 0) {
            if ((solBalances?.native?.basis || 0) < LAMPORTS_IN_TOKEN_ACCOUNT) {
              showAirdropToast(
                "error",
                undefined,
                "Insufficient lamports for token account rent. Please top up lamports before airdropping.",
              );

              return
            }
          }

          await airdropTokens(
            amountBasis,
            new PublicKey(selectedToken?.context?.pubkey || "")
          );

          showAirdropToast("success", {
            icon: <SVG height={16} width={16} src={selectedToken?.context?.imageDarkSvg || ""} />,
            amount: amountUI,
          });
          if (onSuccess != null) {
            onSuccess();
          }
        }
      } catch (err) {
        console.warn("Issue airdropping tokens", err);
        showAirdropToast("error");
        if (onError) {
          onError();
        }
      }
    },
    [
      walletPubkey,
      client,
      airdropSol,
      solBalances,
      airdropTokens,
    ],
  );

  return useMemo(() => {
    return {
      handleAirdrop: handleAirdrop,
      airdropSol: airdropSolWithCallback,
    };
  }, [handleAirdrop, airdropSolWithCallback]);
};
