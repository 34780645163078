import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import HouseToken from "../sdk/houseToken";
import { Commitment, ComputeBudgetProgram, Connection, Finality, Keypair, PublicKey, Transaction, VersionedTransaction } from "@solana/web3.js";
import PlayerToken, { DepositStage, WithdrawalStage } from "../sdk/playerToken";
import { HouseContext } from "./HouseContext";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { NetworkContext } from "./NetworkContext";
import { ProgramContext } from "./ProgramContext";
import { confirmTransaction, toVersionedTransaction } from "../utils/solana/utils";
import { useLocalStorage } from "@solana/wallet-adapter-react";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { SessionAuthorityContext } from "./SessionAuthorityContext";
import { IPlayerMeta } from "./PlayerContext";
import { LAMPORTS_FOR_AUTO_SIGNER_MB, LAMPORTS_FOR_UPDATE_SLIP, MIN_LAMPORTS_AUTO_SIGNER } from "../sdk/constants";
import { awaitCommitToBaseLayer } from "../sdk/utils";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { IMergedToken } from "./AggregatedBalancesContext";
import { sendTransaction } from "../sdk/transactions";
import { BalanceContext } from "./BalanceContext";
import useToast, { useToastAction } from "../components/toast/useToast";

export interface IPlayerTokenContext {
  playerToken: PlayerToken | undefined;
  playerTokenLoaded: boolean;
  playerTokens: PlayerToken[] | undefined;
  playerTokenByMint: Map<string, PlayerToken> | undefined
  loadPlayerToken: (houseToken?: HouseToken, wallet?: PublicKey) => Promise<void>
  loadPlayerTokens: () => Promise<void>
  initAndDeposit: (sessionAuthority?: Keypair, sessionAuthorityLamports?: number, depositAmount?: number, onSuccessfulSend?: Function) => Promise<string | undefined>
  initAndDelegate: (maxSessionLengthSeconds: number, instanceToDelegate: number, updateToast: Function, sessionAuthority?: Keypair, sessionAuthorityLamports?: number, depositAmount?: number, depositStage?: DepositStage, afterFirstSignature?: Function) => Promise<string | undefined>
  processWithdrawal: (amountBasis: number, updateToast: Function, withdrawalStage?: WithdrawalStage, onSuccessfulSendFirst?: Function) => Promise<string | undefined>
  processWithdrawalAll: (mergedTokens: IMergedToken[] | undefined, updateToast?: Function, onSuccessfulSendFirst?: Function) => Promise<string | undefined>
  updateSessionAuthority: (maxSessionLengthSeconds: number, sessionAuthority: PublicKey, sessionAuthorityLamports: number) => Promise<string | undefined>
  withdrawFundsAndEndSession: (updateToast: Function, onSuccessfulSendFirst?: Function) => Promise<string>
  updateSlipUndelegate: (sessionAuth?: Keypair, withConfirmation?: boolean) => Promise<string>
  setPlayerMeta: Function;
  playerMeta: IPlayerMeta | undefined;
  hasNoPlayerTokenState: boolean;
  updateToast: useToastAction;
  createToast: useToastAction;
  lastTransactionError: null | string;
  setLastTransactionError: React.Dispatch<React.SetStateAction<string | null>>

}

export const PlayerTokenContext = createContext<IPlayerTokenContext>({} as IPlayerTokenContext);

interface Props {
  children: any;
}

export const PlayerTokenProvider = ({ children }: Props) => {

  const { solanaRpc } = useContext(WrappedWalletContext)
  const { client, recentBlockhash, erClient } = useContext(NetworkContext)
  const { meta } = useContext(ProgramContext)
  const errorByCodeByProgram = useMemo(() => {
    return meta?.errorByCodeByProgram
  }, [meta])

  // SOL BALANCE OF REGULAR WALLET
  const { solBalances } = useContext(BalanceContext)
  const nativeBalance = useMemo(() => {
    return solBalances?.native?.basis || 0
  }, [solBalances])

  // SOL BALANCE OF AUTO SIGNER
  const { signerKp, allowsAutoSigning, allowsAutoDeposit, setAllowsAutoSigning, lamportBalance, signerPublicKey } = useContext(SessionAuthorityContext)

  const { houseToken, houseTokens } = useContext(HouseContext)
  const { walletPubkey } = useContext(WrappedWalletContext)

  // USED TO HOLD ANY DATA NOT HELD ON THE PLAYER ACC STATE
  const [playerMeta, setPlayerMeta] = useLocalStorage("zeebit-player-meta", "");
  const [counter, setCounter] = useState<number>(0);

  const setPlayerMetaWithCounter = useCallback(
    (newPlayerMeta: IPlayerMeta) => {
      setPlayerMeta({ ...newPlayerMeta, ...playerMeta });
      setCounter(counter + 1);
    },
    [setPlayerMeta, counter, playerMeta],
  );

  // ANY PLAYER TOKENS THEY HAVE
  const [playerTokens, setPlayerTokens] = useState<PlayerToken[]>()
  const [playerTokenLoaded, setPlayerTokenLoaded] = useState<boolean>(false)

  // PLAYER TOKEN FOR SELECTED TOKEN MINT
  const [playerToken, setPlayerToken] = useState<PlayerToken>()
  const [playerTokensLoaded, setPlayerTokensLoaded] = useState<boolean>(false)

  // TOASTER
  const { createToast, updateToast } = useToast();

  const [lastTransactionError, setLastTransactionError] = useState<null | string>(null);

  // NEED THE PLAYER TOKEN AS A REF SO CAN BE USED IN THE WS UPDATE
  const playerTokenRef = useRef<PlayerToken>()
  useEffect(() => {
    playerTokenRef.current = playerToken
  }, [playerToken])

  // WS LOGIC TO KEEP PLAYER TOKEN UP TO DATE
  const playerTokenWsId = useRef<number>()
  const playerTokenErWsId = useRef<number>()

  // TO BE USED TO CHECK IF THEY NEED TO SEE REGISTRATION
  // NO PLAYER TOKEN STATE ACCROSS ALL HOUSE TOKENS
  const hasNoPlayerTokenState = useMemo(() => {
    // FIRST CHECK ON PLAYER TOKEN
    if (playerTokenLoaded == false) {
      return false
    }

    const hasPlayerTokenState = playerToken?.stateLoaded && playerToken?.baseState != null

    if (hasPlayerTokenState) {
      return false
    }

    // NEXT CHECK ON ANY OTHER PLAYER TOKENS THEY MAY HAVE
    if (playerTokensLoaded == false) {
      return false
    }

    if (playerTokens == null) {
      return true
    }

    // Check the other player tokens too
    let playerTokensLength = playerTokens.length
    for (let i = 0; i < playerTokensLength; i++) {
      const token = playerTokens[i]

      if (token.stateLoaded == false || token.baseState != null) {
        return false
      }
    }

    return true
  }, [playerToken, playerTokens, playerTokensLoaded, playerTokenLoaded])

  const playerTokenByMint: Map<string, PlayerToken> | undefined = useMemo(() => {
    const tokenMap = playerTokens?.reduce((result, item) => {
      result.set(item.houseToken.tokenMintPubkey.toString(), item)
      return result
    }, new Map<string, PlayerToken>)

    if (playerToken != null && playerToken?.houseToken != null) {
      tokenMap?.set(playerToken?.houseToken.tokenMintPubkey.toString(), playerToken)
    }

    return tokenMap
  }, [playerTokens, playerToken])

  const loadPlayerToken = useCallback(async (hToken?: HouseToken, wallet?: PublicKey) => {
    try {
      const hseToken = hToken || houseToken
      const walletPkey = wallet || walletPubkey
      const pToken = await PlayerToken.load(hseToken, walletPkey);
      setPlayerTokenLoaded(true)
      setPlayerToken(pToken)
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setPlayerTokenLoaded(true);
    }
  }, [houseToken, walletPubkey])

  useEffect(() => {
    async function loadPlayerTokenAndStartWsHandler(wallet: PublicKey, hseToken: HouseToken, loadFx: (hToken?: HouseToken | undefined, wallet?: PublicKey | undefined) => Promise<void>, connection: Connection, erConnection: Connection, commitment?: Commitment = "processed") {
      await loadFx(hseToken, wallet)

      if (playerTokenWsId.current != null) {
        try {
          await connection.removeAccountChangeListener(playerTokenWsId.current)
        } catch (err) {
          console.warn({
            err
          })
        }
      }

      if (playerTokenErWsId.current != null) {
        try {
          await erConnection.removeAccountChangeListener(playerTokenErWsId.current)
        } catch (err) {
          console.warn({
            err
          })
        }
      }

      const playerTokenPubkey = PlayerToken.derivePlayerTokenPubkey(hseToken.publicKey, wallet, hseToken.programId)

      playerTokenWsId.current = connection.onAccountChange(playerTokenPubkey, (accInfo, context) => {
        if (accInfo.data != null) {
          // NEED TO COMBINE WITH PLAYER TOKEN ALREADY LOADED
          const newPlayerToken = PlayerToken.loadFromBuffer(hseToken, wallet, Buffer.from(accInfo.data), playerTokenRef.current?.erState)
          setPlayerToken(newPlayerToken)
        } else {
          if (houseToken != null && walletPubkey != null) {
            const pt = new PlayerToken(houseToken, walletPubkey)

            setPlayerToken(pt)
          }
        }
      }, { commitment: commitment })

      playerTokenErWsId.current = erConnection.onAccountChange(playerTokenPubkey, (accInfo, context) => {
        if (accInfo.data != null) {
          // NEED TO COMBINE WITH PLAYER TOKEN ALREADY LOADED
          const newPlayerToken = PlayerToken.loadFromBuffer(hseToken, wallet, undefined, undefined, Buffer.from(accInfo.data), playerTokenRef.current?.baseState)
          setPlayerToken(newPlayerToken)
        } else {
          if (houseToken != null && walletPubkey != null) {
            const pt = new PlayerToken(houseToken, walletPubkey, undefined, playerTokenRef.current?.baseState)
            setPlayerToken(pt)
          }
        }
      }, { commitment: commitment })
    }

    if (walletPubkey == null) {
      setPlayerToken(undefined)

      if (playerTokenWsId.current != null && client != null) {
        try {
          client.removeAccountChangeListener(playerTokenWsId.current)
        } catch (err) {
          console.warn({
            err
          })
        }
      }

      if (playerTokenErWsId.current != null && erClient != null) {
        try {
          erClient.removeAccountChangeListener(playerTokenErWsId.current)
        } catch (err) {
          console.warn({
            err
          })
        }
      }

      return
    }

    if (houseToken == null || walletPubkey == null || client == null || erClient == null) {
      return;
    }

    loadPlayerTokenAndStartWsHandler(walletPubkey, houseToken, loadPlayerToken, client, erClient)
  }, [houseToken, walletPubkey, loadPlayerToken, client, erClient]);

  const loadPlayerTokens = useCallback(async () => {
    if (houseTokens == null || walletPubkey == null) {
      const errorMessage = "Missing wallet or house tokens";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    const playerTokenResults = await Promise.allSettled(houseTokens.map((hToken) => {
      return PlayerToken.load(hToken, walletPubkey)
    }))

    const pTokens: PlayerToken[] = []
    playerTokenResults.forEach((result) => {
      if (result.status == "fulfilled") {
        const value = result.value
        const hasState = !!value.baseState || !!value.erState

        if (hasState) {
          pTokens.push(value)
        }
      }
    })

    setPlayerTokens(pTokens)
    setPlayerTokensLoaded(true)

    return pTokens
  }, [houseTokens, walletPubkey])

  useEffect(() => {
    if (houseTokens == null) {
      return
    }

    if (walletPubkey == null) {
      setPlayerTokens(undefined)
      return
    }

    loadPlayerTokens()
  }, [loadPlayerTokens, houseTokens, walletPubkey])

  // METHOD TO CREATE A PLAYER TOKEN AND DEPOSIT FUNDS
  const initAndDeposit = useCallback(
    async (
      sessionAuthority?: Keypair,
      sessionAuthorityLamports?: number,
      depositAmount?: number,
      onSuccessfulSend?: Function
    ) => {
      if (
        walletPubkey == null
        || playerToken == null
        || solanaRpc == null
      ) {
        console.warn(
          "Issue with wallet pubkey or solana rpc.",
          walletPubkey
        );
        const errorMessage = "Wallet not connected";
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
      const tx = new Transaction();

      // INIT PLAYER TOKEN IF DOESNT EXIST
      const playerTokenExists = playerToken?.baseState != null

      if (!playerTokenExists) {
        console.log(`initializeIxn`)
        const initPlayerToken = await playerToken?.initializeIxn();
        tx.add(initPlayerToken);
      }

      if (depositAmount != null && depositAmount > 0) {
        console.log(`depositIxn`)
        const depositIx = await playerToken?.depositIxn(depositAmount)
        tx.add(depositIx);
      }


      if (sessionAuthority != undefined) {
        console.log(`updateSessionAuthorityIxn`)
        tx.add(
          await playerToken.updateSessionAuthorityIxn(
            sessionAuthority.publicKey,
            new Date(Date.now() + 86_400_000),
            sessionAuthorityLamports || 1_000_000
          )
        );
      }

      const sig = await sendTransaction(
        tx.instructions,
        client,
        walletPubkey,
        true,
        errorByCodeByProgram,
        recentBlockhash,
        solanaRpc.signTransaction,
        undefined,
        undefined,
        playerTokenExists == false ? "confirmed" : undefined,
        () => {
          onSuccessfulSend?.()
        }
      )

      await loadPlayerToken();

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      loadPlayerToken,
      errorByCodeByProgram
    ],
  );

  const depositApply = useCallback(async (sessionAuth?: Keypair): Promise<string> => {
    if (erClient == null) {
      const errorMessage = `ER Client not initialised.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (playerToken == null) {
      const errorMessage = `Player token not initialised.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (walletPubkey == null) {
      const errorMessage = `Wallet not connected.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (solanaRpc == null) {
      const errorMessage = `Solana RPC is null.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }
    const walletOrAuth = sessionAuth != null ? sessionAuth.publicKey : walletPubkey

    const depositApply = await playerToken.applyDepositTx(walletOrAuth)

    return await sendTransaction(
      depositApply.instructions,
      erClient,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      false,
      errorByCodeByProgram,
      undefined,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      "confirmed"
    )
  }, [signerKp, playerToken, erClient, walletPubkey, allowsAutoSigning, errorByCodeByProgram])

  const closeUpdateSlip = useCallback(async (withConfirmation: boolean = true, commitment: Finality = "confirmed", afterFirstSignature?: Function): Promise<string | undefined> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    // CHECK IF IT NEEDS TO BE CLOSED...
    const hasUpdateSlipToClose = await playerToken.hasUpdateSlipToClose()

    if (hasUpdateSlipToClose == false) {
      return
    }

    // USE THE AUTO SIGNER IF WE CAN
    let signer = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined

    // VALIDATE WE HAVE ENOUGH SOL BEFORE USING AUTO SIGNER
    const LAMPORTS_FOR_TXN = 10_000
    if (signer != null) {
      // 5000 + Approx Priority Fees
      if ((lamportBalance || 0) <= LAMPORTS_FOR_TXN) {
        console.log(`Not using the session authority as no sol for tx fee.`)
        signer = undefined
      }
    }

    if (signer == undefined) {
      if (nativeBalance < LAMPORTS_FOR_TXN) {
        const errorMessage = "Not enoough sol for transaction, please top-up before progressing.";
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    const tx = await playerToken.closeUpdateSlipTx(signer?.publicKey)
    const recent = await client?.getLatestBlockhash("confirmed")

    const sig = await sendTransaction(
      tx.instructions,
      client,
      signer != null ? signer.publicKey : walletPubkey,
      true,
      errorByCodeByProgram,
      recent,
      signer != null ? undefined : solanaRpc.signTransaction,
      signer,
      undefined,
      withConfirmation ? "confirmed" : undefined
    )

    console.log(`closeUpdateSlipTx `, { sig, signer })

    if (afterFirstSignature != null) {
      afterFirstSignature()
    }

    return sig;
  }, [playerToken, client, walletPubkey, solanaRpc, meta, signerKp, allowsAutoSigning, errorByCodeByProgram, lamportBalance, nativeBalance])

  const { house } = useContext(HouseContext)
  const closeAllUpdateSlips = useCallback(async (playerTokens: PlayerToken[], withConfirmation: boolean = true, commitment: Finality = "confirmed"): Promise<string | undefined> => {
    if (walletPubkey == null || playerTokens == null || solanaRpc == null || house == null) {
      const errorMessage = "Player tokens or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    // LOAD ALL THE UPDATE SLIPS
    const updateSlipPubkeys = playerTokens.map((pt) => {
      return PlayerToken.deriveUpdateSlipPubkey(pt.publicKey, pt.programId)
    })

    let updateSlipsToClose: PublicKey[] = []
    const updateSlipAccounts = await meta?.zeebitV2Program?.account.updateSlip.fetchMultiple(updateSlipPubkeys, commitment)

    // CHECK IF IT NEEDS TO BE CLOSED...
    updateSlipAccounts?.forEach((updateSlip, index) => {
      if (updateSlip != null && Object.keys(updateSlip.status)[0] == 'applied') {
        updateSlipsToClose.push(updateSlipPubkeys[index])
      }
    })

    if (updateSlipsToClose.length == 0) {
      return
    }

    console.log(`There are ${updateSlipsToClose.length} update slips to close.`)

    const tx = await PlayerToken.closeUpdateSlipsTx(updateSlipsToClose, house, walletPubkey)
    const recent = await client?.getLatestBlockhash("confirmed")

    let signer = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined
    // we can only use the auto signer if if has sol
    if (signer != null) {
      if ((lamportBalance || 0) < 10_000) {
        signer = undefined
      }
    }

    const sig = await sendTransaction(
      tx.instructions,
      client,
      signer != null ? signer.publicKey : walletPubkey,
      true,
      errorByCodeByProgram,
      recent,
      signer != null ? undefined : solanaRpc.signTransaction,
      signer,
      undefined,
      withConfirmation ? "confirmed" : undefined
    )

    console.log(`closeUpdateSlipTx ${sig}`)

    return sig;
  }, [client, walletPubkey, solanaRpc, meta, signerKp, allowsAutoSigning, house, errorByCodeByProgram, lamportBalance])

  // DELEGATE - USED FOR THE ER FLOW
  const initAndDelegate = useCallback(
    async (
      maxSessionLengthSeconds: number,
      instanceToDelegate: number,
      updateToast: Function,
      sessionAuthority?: Keypair,
      sessionAuthorityLamports?: number,
      depositAmount?: number,
      depositStage?: DepositStage,
      afterFirstSignature?: Function
    ) => {
      // REMOVE WHEN CRANKER WORKING TO CLOSE THESE
      if (depositStage == null || depositStage == DepositStage.CLOSE_UPDATE_SLIP) {
        updateToast({
          content: {
            title: 'Initializing deposit on Solana…',
            type: 'loading',
          }
        })
        try {
          await closeUpdateSlip(true, "confirmed")
        } catch (err) { }
      }

      if (depositStage == null || [DepositStage.CLOSE_UPDATE_SLIP, DepositStage.INIT_DEPOSIT].includes(depositStage)) {
        let tx = new Transaction();

        // INIT PLAYER TOKEN IF DOESNT EXIST
        const playerTokenExists = playerToken?.baseState != null

        console.log(`INTO INIT`, { playerToken })

        if (playerTokenExists == false) {
          tx = await PlayerToken.initializeTx(walletPubkey, houseToken, depositAmount, undefined, sessionAuthority?.publicKey, sessionAuthorityLamports, true, instanceToDelegate)
        } else if (depositAmount != null && depositAmount > 0) {
          // LOGIC IF IT ALREADY EXISTS
          const needsDelegation = playerToken.delegationStatus == 'undelegated' && houseToken?.isDelegated
          const sessionAuth = sessionAuthority != null && allowsAutoSigning ? sessionAuthority.publicKey : undefined

          // IF NEEDS DELEGATION - FRONT RUN WITH CLOSE - AND GO THROUGH INIT AGAIN
          if (needsDelegation) {
            tx = await PlayerToken.initializeTx(walletPubkey, houseToken, depositAmount, undefined, sessionAuthority?.publicKey, sessionAuthorityLamports, true, instanceToDelegate, true)
          } else {
            tx = await playerToken.depositTx(depositAmount, true, needsDelegation, sessionAuth)
          }
        }

        const sig = await sendTransaction(
          tx.instructions,
          client,
          walletPubkey,
          true,
          errorByCodeByProgram,
          recentBlockhash,
          solanaRpc?.signTransaction,
          sessionAuthority != null && allowsAutoSigning && playerTokenExists == false ? sessionAuthority : undefined,
          undefined,
          undefined,
          () => {
            if (afterFirstSignature != null) {
              afterFirstSignature()
            }
          }
        )

        updateToast({
          content: {
            title: 'Confirming transaction on Solana…`',
            type: 'loading',
          }
        })
        // NEED TO CONFIRM THE TX BEFORE LOADING STATE
        const confirmed = await client?.confirmTransaction({
          signature: sig,
          blockhash: recentBlockhash.blockhash,
          lastValidBlockHeight: recentBlockhash.lastValidBlockHeight
        }, "confirmed");

        if (confirmed?.value.err != null) {
          const errorMessage = `There was an issue with the deposit. ${confirmed.value.err.toString()}`;
          setLastTransactionError(errorMessage);
          throw new Error(errorMessage)
        }
      }

      if (depositStage == null || [DepositStage.CLOSE_UPDATE_SLIP, DepositStage.INIT_DEPOSIT, DepositStage.DEPOSIT_APPLY].includes(depositStage)) {
        updateToast({
          content: {
            title: 'Applying deposit on MagicBlock…',
            type: 'loading',
          }
        })
        // CHECK IF WE CAN USE THE SESSION AUTH TO SIGN HERE
        const sessionAuth = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined

        // TODO - REMOVE WHEN MB READY SHORT TERM FIX, WE NEED TO WAIT 1s here
        await new Promise(resolve => setTimeout(resolve, 2_000))

        const depositApplySig = await depositApply(sessionAuth);

        updateToast({
          content: {
            title: 'Awaiting commit on Solana…',
            type: 'loading',
          }
        })
        await awaitCommitToBaseLayer(erClient, client, depositApplySig)
      }

      if (depositStage == null || [DepositStage.CLOSE_UPDATE_SLIP, DepositStage.INIT_DEPOSIT, DepositStage.DEPOSIT_APPLY, DepositStage.CLOSE_UPDATE_SLIP_FINISHED].includes(depositStage)) {
        updateToast({
          content: {
            title: 'Finalising deposit on Solana…',
            type: 'loading',
          }
        })
        // NO NEED TO CONFIRM THE TXN
        let sig = await closeUpdateSlip(false);

        await loadPlayerToken();

        // updateMessage?.(undefined)

        return sig;
      }
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      loadPlayerToken,
      depositApply,
      closeUpdateSlip,
      erClient,
      errorByCodeByProgram
    ],
  );

  const applyWithdrawalAndClose = useCallback(async (sessionAuth?: Keypair, withdrawAmount: number = 0, updateToast?: Function): Promise<string> => {
    if (client == null) {
      const errorMessage = `ER Client not initialised.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (playerToken == null) {
      const errorMessage = `Player token not initialised.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (walletPubkey == null) {
      const errorMessage = `Wallet not connected.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (solanaRpc == null) {
      const errorMessage = `Solana RPC is null.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    updateToast?.({
      content: {
        title: 'Finalizing withdrawal and end session on Solana…',
        type: 'loading',
      }
    })
    const tx = new Transaction()

    if (withdrawAmount > 0) {
      const withdrawIx = await playerToken.applyWithdrawalIx(sessionAuth?.publicKey)
      tx.add(withdrawIx)
    }

    const closeIx = await playerToken.closeIx(sessionAuth?.publicKey)

    tx.add(closeIx)

    const recentBlock = await client.getLatestBlockhash("confirmed")

    const sig = await sendTransaction(
      tx.instructions,
      client,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      true,
      errorByCodeByProgram,
      recentBlock,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )

    updateToast?.({
      content: {
        title: 'Confirming transaction on Solana…',
        type: 'loading',
      }
    })
    const confirmed = await client.confirmTransaction({
      signature: sig,
      blockhash: recentBlock.blockhash,
      lastValidBlockHeight: recentBlock.lastValidBlockHeight
    }, "confirmed")

    if (confirmed.value.err != null) {
      const errorMessage = `There was an issue with apply withdrawal and close. ${confirmed.value.err.toString()}`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    return sig
  }, [solanaRpc, playerToken, client, walletPubkey, allowsAutoSigning, signerKp, errorByCodeByProgram])

  const withdrawAndUndelegate = useCallback(async (
    sessionAuth?: Keypair,
    sessionAuthWithSol?: Keypair,
    withdrawAmount: number = 0,
    updateToast?: Function
  ): Promise<string> => {
    if (erClient == null || client == null) {
      const errorMessage = `Client not initialised.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (playerToken == null) {
      const errorMessage = `Player token not initialised.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (walletPubkey == null) {
      const errorMessage = `Wallet not connected.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    if (solanaRpc == null) {
      const errorMessage = `Solana RPC is null.`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }
    const tx = new Transaction()

    updateToast?.({
      content: {
        title: 'Initializing withdrawal and end session on MagicBlock…',
        type: 'loading',
      }
    })
    if (withdrawAmount > 0) {
      const withdrawIx = await playerToken.initializeWithdrawalIx(withdrawAmount, sessionAuth?.publicKey)
      tx.add(withdrawIx)
    }

    const undelegateIx = await playerToken.undelegateIx(sessionAuth?.publicKey)

    tx.add(undelegateIx)

    const recentBlock = await erClient.getLatestBlockhash("confirmed")

    const sig = await sendTransaction(
      tx.instructions,
      erClient,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      false,
      recentBlock,
      errorByCodeByProgram,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )

    console.log(`undelegate/withdraw ER -> ${sig}`)

    updateToast?.({
      content: {
        title: 'Confirming transaction on MagicBlock…',
        type: 'loading',
      }
    });
    const confirmed = await erClient.confirmTransaction({
      signature: sig,
      blockhash: recentBlock.blockhash,
      lastValidBlockHeight: recentBlock.lastValidBlockHeight
    }, "confirmed")

    if (confirmed.value.err != null) {
      const errorMessage = `Issue with withdraw and undelegate. ${confirmed.value.err.toString()}`;
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    console.log(`Awaiting commit on Solana…`)

    updateToast?.({
      content: {
        title: 'Awaiting commit on Solana…',
        type: 'loading',
      }
    });
    await awaitCommitToBaseLayer(erClient, client, sig, 'confirmed')

    console.log(`Made it to base layer -> ${sig}`)

    // TODO - REMOVE WHEN MB READY SHORT TERM FIX HERE
    await new Promise(resolve => setTimeout(resolve, 2_000))

    const withdrawAndCloseSig = await applyWithdrawalAndClose(sessionAuthWithSol, withdrawAmount, updateToast)

    return withdrawAndCloseSig
  }, [signerKp, playerToken, erClient, client, walletPubkey, applyWithdrawalAndClose, solanaRpc, signerKp, allowsAutoSigning, errorByCodeByProgram])

  const updateSessionAuthority = useCallback(
    async (
      maxSessionLengthSeconds: number,
      sessionAuthority: PublicKey,
      sessionAuthorityLamports: number
    ) => {

      const tx = new Transaction();

      tx.add(
        await playerToken.updateSessionAuthorityIxn(
          sessionAuthority,
          new Date(Date.now() + Math.round(maxSessionLengthSeconds * 1000)),
          sessionAuthorityLamports || 0
        )
      );

      const sig = await sendTransaction(
        tx.instructions,
        client,
        walletPubkey,
        true,
        errorByCodeByProgram,
        recentBlockhash,
        solanaRpc?.signTransaction,
        undefined,
        undefined,
        undefined
      );

      return sig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      errorByCodeByProgram
    ],
  );

  const withdraw = useCallback(
    async (
      amountBasis: number,
      onSuccessfulSend?: Function
    ) => {
      let recentBlock = await client?.getLatestBlockhash("confirmed")

      const tx = new Transaction()

      if (allowsAutoSigning == true && signerPublicKey != null) {
        // CHECK IF NEEDS AN UPDATE
        const needsUpdate = await playerToken?.needsSessionAuthorityUpdate(signerPublicKey, MIN_LAMPORTS_AUTO_SIGNER, lamportBalance)
        console.log(`updateSessionAuthorityIxn`)

        if (needsUpdate == true) {
          const topUpLamports = lamportBalance - MIN_LAMPORTS_AUTO_SIGNER
          tx.add(
            await playerToken.updateSessionAuthorityIxn(
              signerPublicKey,
              new Date(Date.now() + 86_400_000),
              topUpLamports > 0 ? topUpLamports : 0
            )
          );
        }
      } else if (allowsAutoSigning == false) {
        const needsUpdate = playerToken?.sessionAuthority.toString() != walletPubkey?.toString()

        if (needsUpdate == true) {
          tx.add(
            await playerToken.updateSessionAuthorityIxn(
              walletPubkey,
              new Date(),
              0
            )
          );
        }
      }

      const withdrawIxn = await playerToken.withdrawIxn(amountBasis)

      tx.add(withdrawIxn)
      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlock?.blockhash

      let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlock)
      versionedTransaction = await solanaRpc?.signTransaction(versionedTransaction)

      const withdrawSig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })
      
      if (onSuccessfulSend != null) {
        onSuccessfulSend()
      }
      
      await confirmTransaction(withdrawSig, client, recentBlockhash);
      console.log(`CONFIRMED`, {
        withdrawSig
      })

      return withdrawSig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      allowsAutoSigning,
      signerPublicKey
    ],
  );

  const withdrawAll = useCallback(
    async (
      mergedTokens: IMergedToken[],
    ) => {
      let recentBlock = await client?.getLatestBlockhash("confirmed")

      const tx = new Transaction()

      for (const mergedToken of mergedTokens) {
        if (!mergedToken.playerToken || mergedToken.playerToken?.availableBalance <= 0) break;

        if (allowsAutoSigning == true && signerPublicKey != null) {
          // CHECK IF NEEDS AN UPDATE
          const needsUpdate = await mergedToken.playerToken?.needsSessionAuthorityUpdate(signerPublicKey, MIN_LAMPORTS_AUTO_SIGNER, lamportBalance)
          console.log(`updateSessionAuthorityIxn`)

          if (needsUpdate == true) {
            const topUpLamports = lamportBalance - MIN_LAMPORTS_AUTO_SIGNER
            tx.add(
              await mergedToken.playerToken.updateSessionAuthorityIxn(
                signerPublicKey,
                new Date(Date.now() + 86_400_000),
                topUpLamports > 0 ? topUpLamports : 0
              )
            );
          }
        } else if (allowsAutoSigning == false) {
          const needsUpdate = mergedToken.playerToken?.sessionAuthority.toString() != walletPubkey?.toString()

          if (needsUpdate == true) {
            tx.add(
              await mergedToken.playerToken.updateSessionAuthorityIxn(
                walletPubkey,
                new Date(),
                0
              )
            );
          }
        }

        const withdrawIxn = await mergedToken.playerToken?.withdrawIxn(mergedToken.playerToken?.availableBalance)

        tx.add(withdrawIxn)
      }

      tx.feePayer = walletPubkey
      tx.recentBlockhash = recentBlock?.blockhash

      let versionedTransaction = await toVersionedTransaction(tx, client, walletPubkey, recentBlock)
      versionedTransaction = await solanaRpc?.signTransaction(versionedTransaction)

      const withdrawSig = await client?.sendRawTransaction(versionedTransaction.serialize(), { skipPreflight: true })
      console.log({
        withdrawSig
      })
      await confirmTransaction(withdrawSig, client, recentBlockhash);
      console.log(`CONFIRMED`, {
        withdrawSig
      })

      return withdrawSig;
    },
    [
      solanaRpc,
      walletPubkey,
      client,
      meta,
      recentBlockhash,
      playerToken,
      allowsAutoSigning,
      signerPublicKey
    ],
  );

  const withdrawFundsAndEndSession = useCallback(async (updateToast: Function, onSuccessSendFirst?: Function): Promise<string> => {
    if (playerToken == null || playerToken.state == null) {
      const errorMessage = "Player Token is null";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }
    let sig: string = ''

    // CHECK IF WE USE THE SESSION AUTH OR NOT FOR THE FLOW
    let sessionAuth = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined;
    let sessionAuthWithSol = sessionAuth

    // CHECK WE HAVE ENOUGH SOL TO USE AUTO SIGNER HERE
    if (sessionAuth != null) {
      if (sessionAuth.publicKey.toString() != playerToken?.sessionAuthority?.toString()) {
        sessionAuth = undefined
        sessionAuthWithSol = undefined
      } else if ((lamportBalance || 0) < LAMPORTS_FOR_AUTO_SIGNER_MB) {
        sessionAuthWithSol = undefined
      }
    }

    if (sessionAuthWithSol == null) {
      if (nativeBalance < (LAMPORTS_FOR_UPDATE_SLIP + 10_000)) {
        const errorMessage = "Insufficient lamports for txn.";
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    // BASE predelegate update slip
    updateToast({
      content: {
        title: 'Initializing withdrawal and end session on MagicBlock…',
        type: 'loading',
      }
    });
    sig = await updateSlipPredelegate(sessionAuthWithSol, true, onSuccessSendFirst)
    console.log(`updateSlipPredelegateSig -> ${sig}`)

    // UNDELEGATE THE ACCOUNT, WITHDRAW TOKENS
    const amountToWithdraw = playerToken.playBalance

    sig = await withdrawAndUndelegate(sessionAuth, sessionAuthWithSol, amountToWithdraw, updateToast)

    // LOAD THE PLAYER TOKEN ACCOUNT
    await loadPlayerToken()

    return sig
  }, [withdrawAndUndelegate, withdraw, playerToken, loadPlayerToken, erClient, client, allowsAutoSigning, signerKp, lamportBalance, nativeBalance])

  const updateSlipUndelegate = useCallback(async (sessionAuth?: Keypair, withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    const ix = await playerToken.undelegateUpdateSlip(sessionAuth?.publicKey)
    const tx = new Transaction()
    tx.add(ix)


    const recent = await erClient?.getLatestBlockhash("confirmed")
    const sig = await sendTransaction(
      tx.instructions,
      erClient,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      false,
      errorByCodeByProgram,
      recent,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )


    if (withConfirmation) {
      const confirmed = await erClient?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err) {
        const errorMessage = `Issue undelegating the update slip. ${confirmed?.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }


    return sig;
  }, [playerToken, erClient, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp, errorByCodeByProgram])

  const updateSlipPredelegate = useCallback(async (sessionAuth?: Keypair, withConfirmation: boolean = true, onSuccessfulSend?: Function): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    const recent = await client?.getLatestBlockhash("confirmed")

    const tx = await playerToken.predelegateUpdateSlipTx(sessionAuth?.publicKey || walletPubkey)
    console.log(`UPDATE SLIP PREDELEGATE`, { sessionAuth })

    const sig = await sendTransaction(
      tx.instructions,
      client,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      true,
      errorByCodeByProgram,
      recent,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined,
      () => {
        if (onSuccessfulSend != null) {
          console.log(`Calling on success`)
          onSuccessfulSend()
        }
      }
    )

    console.log(`UPDATE SLIP PREDELEGATE ${sig}`)

    if (withConfirmation) {
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err != null) {
        const errorMessage = `Issue predelegating. ${confirmed?.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    console.log(`CONFIRMED -> UPDATE SLIP PREDELEGATE ${sig}`)

    return sig;
  }, [playerToken, client, walletPubkey, solanaRpc, meta, signerKp, allowsAutoSigning, errorByCodeByProgram])

  const updateSlipsPredelegate = useCallback(async (playerTokens: PlayerToken[], withConfirmation: boolean = true, onSuccessfulSendFirst?: Function): Promise<string> => {
    if (walletPubkey == null || playerTokens == null || solanaRpc == null) {
      const errorMessage = "Player tokens or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    const recent = await client?.getLatestBlockhash("confirmed")

    // CHECK IF WE SHOULD USE SESSION AUTH OR WALLET
    let sessionAuth = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined;
    let RENT_REQUIRED = (LAMPORTS_FOR_UPDATE_SLIP * playerTokens.length) + 10_000
    // WE CAN ONLY USE THE SESSION AUTH IF ITS THE SESSION AUTH ON ALL, AND HAS ENOUGH FOR ALL THE RENT
    if (sessionAuth != null) {
      if ((lamportBalance || 0) < RENT_REQUIRED) {
        sessionAuth = undefined
      } else {
        const sessionAuthPubkeyString = sessionAuth.publicKey.toString()
        for (let i = 0; i < playerTokens.length; i++) {
          const sessAuthOnPt = playerTokens[i].sessionAuthority?.toString()

          if (sessAuthOnPt != sessionAuthPubkeyString) {
            sessionAuth = undefined
            break
          }
        }
      }
    }

    const tx = await PlayerToken.predelegateUpdateSlipsTx(playerTokens, sessionAuth?.publicKey || walletPubkey)
    console.log(`UPDATE SLIPS PREDELEGATE`)

    const sig = await sendTransaction(
      tx.instructions,
      client,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      true,
      errorByCodeByProgram,
      recent,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined,
      () => {
        if (onSuccessfulSendFirst != null) {
          onSuccessfulSendFirst()
        }
      }
    )

    console.log(`UPDATE SLIPS PREDELEGATE ${sig}`)

    if (withConfirmation) {
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err != null) {
        const errorMessage = `Issue predelegating. ${confirmed?.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    console.log(`CONFIRMED -> UPDATE SLIPS PREDELEGATE ${sig}`)

    return sig;
  }, [client, walletPubkey, solanaRpc, meta, signerKp, allowsAutoSigning, errorByCodeByProgram])

  const withdrawInit = useCallback(async (sessionAuth?: Keypair, amountBasis: number, withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    const ix = await playerToken.initializeWithdrawalIx(amountBasis, sessionAuth?.publicKey)
    const tx = new Transaction()
    tx.add(ix)

    console.log(`withdrawInit`)
    const recentBlock = await erClient?.getLatestBlockhash("confirmed")

    const sig = await sendTransaction(
      tx.instructions,
      erClient,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      false,
      errorByCodeByProgram,
      recentBlock,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )

    console.log(`withdrawInit ${sig}`)

    if (withConfirmation) {
      const confirmed = await erClient?.confirmTransaction({
        signature: sig,
        blockhash: recentBlock.blockhash,
        lastValidBlockHeight: recentBlock.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err != null) {
        const errorMessage = `Issue with withdrawal. ${confirmed.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    console.log(`CONFIRMED -> withdrawInit ${sig}`)

    return sig;
  }, [playerToken, erClient, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp, errorByCodeByProgram])

  const withdrawAllInit = useCallback(async (playerTokens: PlayerToken[], withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerTokens == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }
    let sessionAuth = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined;
    const sessionAuthPubkeyString = sessionAuth?.publicKey.toString()
    // ensure is session auth on all pts
    for (let i = 0; i < playerTokens.length; i++) {
      const sessAuthOnPt = playerTokens[i].sessionAuthority?.toString()

      if (sessAuthOnPt != sessionAuthPubkeyString) {
        sessionAuth = undefined
        break
      }
    }

    const tx = new Transaction()

    const ixns = await Promise.all(playerTokens.map((playerToken) => {
      return playerToken.initializeWithdrawalIx(
        playerToken?.availableBalance, sessionAuth?.publicKey
      )
    }))

    tx.add(...ixns)


    console.log(`withdrawInit (All)`)
    const recentBlock = await erClient?.getLatestBlockhash("confirmed")

    const sig = await sendTransaction(
      tx.instructions,
      erClient,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      false,
      errorByCodeByProgram,
      recentBlock,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )

    console.log(`withdrawInit (All) ${sig}`)

    if (withConfirmation) {
      const confirmed = await erClient?.confirmTransaction({
        signature: sig,
        blockhash: recentBlock.blockhash,
        lastValidBlockHeight: recentBlock.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err != null) {
        const errorMessage = `Issue with withdrawal. ${confirmed.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    console.log(`CONFIRMED -> withdrawInit ${sig}`)

    return sig;
  }, [erClient, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp, errorByCodeByProgram])


  const withdrawApply = useCallback(async (sessionAuth?: Keypair, withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerToken == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    const ix = await playerToken.applyWithdrawalIx(sessionAuth?.publicKey)
    const tx = new Transaction()
    tx.add(ix)

    console.log(`withdrawApply`)

    const recent = await client?.getLatestBlockhash("confirmed")
    const sig = await sendTransaction(
      tx.instructions,
      client,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      true,
      errorByCodeByProgram,
      recent,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )

    console.log(`withdrawApply ${sig}`)

    if (withConfirmation) {
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err) {
        const errorMessage = `Issue applying the withdrawal. ${confirmed?.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    console.log(`withdrawApply ${sig}`)

    return sig;
  }, [playerToken, client, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp, errorByCodeByProgram])

  const withdrawAllApply = useCallback(async (playerTokens: PlayerToken[], withConfirmation: boolean = true): Promise<string> => {
    if (walletPubkey == null || playerTokens == null || solanaRpc == null) {
      const errorMessage = "Player token or wallet pubkey null.";
      setLastTransactionError(errorMessage);
      throw new Error(errorMessage)
    }

    let sessionAuth = allowsAutoSigning && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined;
    // session auth must have lamports for base layer txn
    if (sessionAuth != null) {
      if ((lamportBalance || 0) < 10_000) {
        sessionAuth = undefined
      }
    }

    const ixns = await Promise.all(playerTokens.map((playerToken) => {
      return playerToken.applyWithdrawalIx(sessionAuth?.publicKey)
    }))

    console.log(`withdrawApply (All)`)

    const recent = await client?.getLatestBlockhash("confirmed")
    const sig = await sendTransaction(
      ixns,
      client,
      sessionAuth == null ? walletPubkey : sessionAuth.publicKey,
      true,
      errorByCodeByProgram,
      recent,
      sessionAuth == null ? solanaRpc.signTransaction : undefined,
      sessionAuth,
      undefined,
      undefined
    )

    console.log(`withdrawApply ${sig}`)

    if (withConfirmation) {
      const confirmed = await client?.confirmTransaction({
        signature: sig,
        blockhash: recent.blockhash,
        lastValidBlockHeight: recent.lastValidBlockHeight
      }, "confirmed")

      if (confirmed?.value.err) {
        const errorMessage = `Issue applying the withdrawal. ${confirmed?.value.err.toString()}`;
        setLastTransactionError(errorMessage);
        throw new Error(errorMessage)
      }
    }

    console.log(`withdrawApply ${sig}`)

    return sig;
  }, [client, walletPubkey, solanaRpc, meta, allowsAutoSigning, signerKp, errorByCodeByProgram, lamportBalance])



  const processWithdrawal = useCallback(async (
    amountBasis: number,
    updateToast: Function,
    withdrawalStage?: WithdrawalStage, // alows continuing from a certain part of the flow
    onSuccessfulSendFirst?: Function
  ) => {
    if (playerToken?.houseToken.isDelegated == true) {
      // CHECK IF WE USE THE SESSION AUTH OR NOT FOR THE FLOW, (WITH SOL INCLUDED FOR BASE LAYER, CAN STILL USE SESSION AUTH WITHOUT SOL ON ER)
      let sessionAuth = allowsAutoSigning == true && signerKp != null ? Keypair.fromSecretKey(bs58.decode(signerKp)) : undefined;
      let sessionAuthWithSol = sessionAuth

      // CHECK WE HAVE ENOUGH SOL TO USE AUTO SIGNER HERE
      // CHECK AUTO SIGNER IS THE SAME AS PLAYER TOKENS
      if (sessionAuth != null) {
        if (sessionAuth.publicKey.toString() != playerToken?.sessionAuthority?.toString()) {
          sessionAuth = undefined
          sessionAuthWithSol = undefined
        } else if ((lamportBalance || 0) < LAMPORTS_FOR_AUTO_SIGNER_MB) {
          sessionAuthWithSol = undefined
        }
      }

      if (sessionAuthWithSol == null) {
        if (nativeBalance < (LAMPORTS_FOR_UPDATE_SLIP + 10_000)) {
          const errorMessage = "Insufficient lamports for txn.";
          setLastTransactionError(errorMessage);
          throw new Error(errorMessage)
        }
      }

      // CLOSE OLD UPDATE SLIP IF POSSIBLE
      if (withdrawalStage == null || withdrawalStage == WithdrawalStage.CHECK_UPDATE_SLIP_TO_CLOSE) {
        updateToast({
          content: {
            title: 'Preparing for withdrawal on Solana…',
            type: 'loading',
          }
        });
        try {
          await closeUpdateSlip(true, "confirmed")
        } catch (err) { }
      }

      if (withdrawalStage == null || [WithdrawalStage.UPDATE_SLIP_PREDELEGATE, WithdrawalStage.CHECK_UPDATE_SLIP_TO_CLOSE].includes(withdrawalStage)) {
        updateToast({
          content: {
            title: 'Preparing for withdrawal on Solana…',
            type: 'loading',
          }
        });
        // // Base: update_slip_predelegate (permissionless, but requires a payer)
        const updateSlipPredelegateSig = await updateSlipPredelegate(sessionAuthWithSol, true, onSuccessfulSendFirst)
      }

      if (withdrawalStage == null || [WithdrawalStage.UPDATE_SLIP_PREDELEGATE, WithdrawalStage.CHECK_UPDATE_SLIP_TO_CLOSE, WithdrawalStage.WITHDRAW_INIT].includes(withdrawalStage)) {
        updateToast({
          content: {
            title: 'Initializing withdrawal on MagicBlock…',
            type: 'loading',
          }
        });

        // // ER: player_token_withdraw_initialize
        const withdrawInitSig = await withdrawInit(sessionAuth, amountBasis, true)

        updateToast({
          content: {
            title: 'Awaiting commit on Solana…',
            type: 'loading',
          }
        });
        await awaitCommitToBaseLayer(erClient, client, withdrawInitSig)
      }

      if (withdrawalStage == null || [WithdrawalStage.UPDATE_SLIP_PREDELEGATE, WithdrawalStage.CHECK_UPDATE_SLIP_TO_CLOSE, WithdrawalStage.WITHDRAW_INIT, WithdrawalStage.WITHDRAW_APPLY].includes(withdrawalStage)) {
        updateToast({
          content: {
            title: 'Finalizing withdrawal on Solana…',
            type: 'loading',
          }
        });
        // Base: player_token_withdraw_apply (permissionless)
        const withdrawApplySig = await withdrawApply(sessionAuthWithSol, false)
        
        await loadPlayerToken()
        return withdrawApplySig
      }
    } else {
      console.log(`withdraw ${amountBasis}`)
      const withdrawSig = await withdraw(amountBasis, onSuccessfulSendFirst)
      await loadPlayerToken()

      return withdrawSig
    }
  }, [playerToken, withdraw, loadPlayerToken, withdrawApply, withdrawInit, updateSlipPredelegate, closeUpdateSlip, erClient, client, allowsAutoSigning, signerKp, lamportBalance, nativeBalance])

  const processWithdrawalAll = useCallback(async (mergedTokens: IMergedToken[] | undefined, updateToast?: Function, onSuccessfulSendFirst?: Function) => {
    if (playerToken?.houseToken.isDelegated == true) {
      const updatedPlayerTokens = await loadPlayerTokens();

      updateToast?.({
        content: {
          title: 'Preparing for withdrawal on Solana…',
          type: 'loading',
        }
      });
      const playerTokensWithAvailableBalance: PlayerToken[] = updatedPlayerTokens?.filter((token) => {
        return token != null && (token?.availableBalance || 0) > 0
      })

      if (playerTokensWithAvailableBalance.length == 0) {
        return
      }

      // STEP 1 - CHECK IF ANY OF THE UPDATE SLIPS NEED TO BE CLOSED
      console.log(`STEP 1 - CLOSE ALL UPDATE SLIPS`);
      await closeAllUpdateSlips(playerTokensWithAvailableBalance, true)

      // STEP 2 - PREDELEGATE ANY UPDATE SLIPS NEEDED
      console.log(`Step 2 - Predelegate any update slips`)
      await updateSlipsPredelegate(playerTokensWithAvailableBalance, true, onSuccessfulSendFirst)

      // STEP 3 - INIT WITHDRAW FOR ALL PLAYER TOKENS
      console.log(`Step 3 - Init withdrawal for all player tokens`)
      updateToast?.({
        content: {
          title: 'Initializing withdrawal on MagicBlock…',
          type: 'loading',
        }
      });
      // // ER: player_token_withdraw_initialize
      const withdrawInitSig = await withdrawAllInit(playerTokensWithAvailableBalance, true)
      
      updateToast?.({
        content: {
          title: 'Awaiting commit on Solana…',
          type: 'loading',
        }
      });
      await awaitCommitToBaseLayer(erClient, client, withdrawInitSig)

      // STEP 4 - APPLY WITHDRAWAL FOR ALL PLAYER TOKENS
      updateToast?.({
        content: {
          title: 'Finalizing withdrawal on Solana…',
          type: 'loading',
        }
      });
      // Base: player_token_withdraw_apply (permissionless)
      const withdrawApplySig = await withdrawAllApply(playerTokensWithAvailableBalance)

      // STEP 5 - LOAD ALL PLAYER TOKENS
      await loadPlayerTokens()
      return withdrawApplySig
    } else {
      // console.log(`withdraw ${amountBasis}`)
      const withdrawSig = await withdrawAll(mergedTokens)
      await loadPlayerTokens()

      return withdrawSig
    }
  }, [playerToken, withdrawAll, loadPlayerTokens, withdrawAllApply, withdrawAllInit, updateSlipsPredelegate, closeAllUpdateSlips, erClient, client])

  const { playerValidation } = useContext(ErrorHandlingContext);

  // MINIMUM LAMPORTS CHECK
  useEffect(() => {
    if (allowsAutoDeposit == false && (playerToken == null || playerToken.baseState == null)) {
      playerValidation.addErrorMessage({
        type: ErrorType.NO_PLAYER_TOKEN,
        title: "No Player Token",
        message: "Please start a session and deposit tokens to play.",
      });
    } else {
      playerValidation.removeErrorMessage(ErrorType.NO_PLAYER_TOKEN);
    }
  }, [playerToken, allowsAutoDeposit]);

  // WHEN WE LOAD THE PLAYER TOKEN, WE NEED TO CHECK THE allowsAutoSigning flag is in line with the state
  const loadedPlayerTokenRef = useRef<string>()
  useEffect(() => {
    if (loadedPlayerTokenRef.current == playerToken?.publicKey?.toString()) {
      return
    }

    // IF THERE IS NOTHING ON CHAIN, DO NOTHING
    if (playerToken != null && playerToken?.baseState == null) {
      loadedPlayerTokenRef.current = playerToken?.publicKey.toString()
      return
    }

    const sessionAuthOnChain = playerToken?.sessionAuthority?.toString()
    const walletString = walletPubkey?.toString()

    if (sessionAuthOnChain == null || sessionAuthOnChain == walletString) {
      setAllowsAutoSigning(false)
    } else if (sessionAuthOnChain != null && sessionAuthOnChain != walletString) {
      setAllowsAutoSigning(true)
    }

    loadedPlayerTokenRef.current = playerToken?.publicKey.toString()
  }, [playerToken])

  return (
    <PlayerTokenContext.Provider
      value={useMemo(
        () => ({
          playerToken: playerToken,
          playerTokens: playerTokens,
          playerTokenByMint: playerTokenByMint,
          playerTokenLoaded: playerTokenLoaded,
          loadPlayerToken: loadPlayerToken,
          loadPlayerTokens: loadPlayerTokens,
          initAndDeposit: initAndDeposit,
          initAndDelegate: initAndDelegate,
          processWithdrawal: processWithdrawal,
          processWithdrawalAll: processWithdrawalAll,
          updateSessionAuthority: updateSessionAuthority,
          setPlayerMeta: setPlayerMetaWithCounter,
          playerMeta: playerMeta,
          hasNoPlayerTokenState: hasNoPlayerTokenState,
          withdrawFundsAndEndSession: withdrawFundsAndEndSession,
          updateSlipUndelegate: updateSlipUndelegate,
          updateToast: updateToast,
          createToast: createToast,
          lastTransactionError: lastTransactionError,
          setLastTransactionError: setLastTransactionError
        }),
        [
          playerToken,
          playerTokenLoaded,
          loadPlayerToken,
          loadPlayerTokens,
          initAndDeposit,
          initAndDelegate,
          playerTokens,
          playerTokenByMint,
          processWithdrawal,
          updateSessionAuthority,
          setPlayerMeta,
          playerMeta,
          hasNoPlayerTokenState,
          withdrawFundsAndEndSession,
          updateSlipUndelegate,
          updateToast,
          createToast,
          lastTransactionError,
          setLastTransactionError
        ],
      )}
    >
      {children}
    </PlayerTokenContext.Provider>
  );
};
