import {
    Connection,
    PublicKey,
    Transaction,
    Commitment,
    ComputeBudgetProgram,
    VersionedTransaction,
} from "@solana/web3.js";
import { IChainBalance } from "../../contexts/BalanceContext";
import { IdlErrorCode } from "@coral-xyz/anchor/dist/cjs/idl";
import { IRecentBlockhash } from "../../contexts/NetworkContext";

import { confirmTransaction, getPriorityFeesForComputeUnits, handleSendTransaction, loadAssociatedTokenBalances, loadLamportBalance, loadTokenBalance } from "./utils";
import { BLOCKHASH_COMMITMENT, ISolanaRpc } from "./rpc";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { addPriorityFeeIxn, modifyComputeUnitsIxn } from "../../sdk/utils";

export default class NonSocialSolanaRpc implements ISolanaRpc {
    private client: Connection;
    private writeClients: Connection[];
    wallet: WalletContextState;
    pubkey?: PublicKey;

    constructor(nonSocialWallet: WalletContextState, client: Connection, writeEndpoints: string[]) {
        this.wallet = nonSocialWallet;
        this.client = client;
        this.writeClients = writeEndpoints.map((connection) => {
            return new Connection(connection, 'processed')
        })
    }

    get useableWallet() {
        return this.wallet.connected == true ? this.wallet: this.wallet.wallet?.adapter
    }

    getPubkey = async (): Promise<string> => {
        if (this.useableWallet?.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return (this.useableWallet.publicKey)?.toString();
    };

    getLamportBalance = async (): Promise<IChainBalance> => {
        if (this.useableWallet?.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return await loadLamportBalance(this.useableWallet.publicKey, this.client)
    };

    getTokenBalance = async (mint: PublicKey, decimals: number): Promise<IChainBalance> => {
        if (this.useableWallet?.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return await loadTokenBalance(this.useableWallet.publicKey, mint, decimals, this.client)
    };

    getAssociatedTokenBalances = async (): Promise<IChainBalance[]> => {
        if (this.useableWallet?.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return await loadAssociatedTokenBalances(this.useableWallet.publicKey.toString(), this.client)
    };

    signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
        if (this.useableWallet?.signMessage == null) {
            return Promise.reject("No method to sign message available.")
        }
        return await this.wallet.wallet?.adapter.signMessage(message);
    };

    sendAndConfirmTransaction = async (
        transaction: Transaction,
        client: Connection,
        feePayer: PublicKey,
        errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
        blockhash?: IRecentBlockhash,
        commitment: Commitment | undefined = "processed"
    ): Promise<string> => {
        try {
            const latestBlockHash = blockhash != null ? blockhash : await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);
            const signature = await this.sendTransaction(
                transaction,
                client,
                feePayer,
                errorByCodeByProgram,
                latestBlockHash,
            );

            await confirmTransaction(signature, client, latestBlockHash, commitment)

            return signature;
        } catch (err: any) {
            return Promise.reject(err);
        }
    };

    sendTransaction = async (
        transaction: Transaction,
        client: Connection,
        feePayer: PublicKey,
        errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
        blockhash?: IRecentBlockhash,
        lookupTable?: PublicKey
    ): Promise<string> => {
        if (this.wallet.signTransaction == null) {
            return Promise.reject("No method to sign transaction available.")
        }

        return await handleSendTransaction(
            this.writeClients,
            this.wallet,
            transaction,
            client,
            feePayer,
            errorByCodeByProgram,
            blockhash,
            lookupTable
        )
    };

    signTransaction = async (transaction: Transaction | VersionedTransaction): Promise<Transaction | VersionedTransaction> => {
        console.log({
            wallet: this.wallet
        })
        if (this.useableWallet.signTransaction == null) {
            return Promise.reject("No method to sign transaction available.")
        }

        return await this.useableWallet.signTransaction(transaction);
    };

    sendLegacyTransaction = async (transaction: Transaction,
        client: Connection,
        feePayer: PublicKey,
        errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
        blockhash?: IRecentBlockhash) => {
        const recentBlockhash = blockhash || (await client.getLatestBlockhash(BLOCKHASH_COMMITMENT))

        const recentBlock = transaction.recentBlockhash || blockhash?.blockhash || recentBlockhash.blockhash
        // ADD TEST PRIORITY FEES AND COMPUTE UNITS
        const transactionToSimulate = new Transaction()
        transactionToSimulate.recentBlockhash = recentBlock
        transactionToSimulate.feePayer = feePayer

        transactionToSimulate.instructions = [modifyComputeUnitsIxn, addPriorityFeeIxn, ...transaction.instructions]
        
        console.log({
            client,
            transactionToSimulate
        })
        // SIMULATE      
        // const simulated = await simulateTransaction(client, transactionToSimulate, [], "processed")
        // const simulationMeta = handleSimulatedTransaction(simulated, errorByCodeByProgram);

        // if (simulationMeta.successful == false) {
        //     console.error({
        //         errorInSimulation: true,
        //         simulatedTxContext: simulated.context,
        //         simulatedTxContextValue: simulated.value,
        //         formattedErrors: simulationMeta.errors,
        //     });

        //     return Promise.reject(simulationMeta.errors);
        // }

        // WANT TO ACTUALLY SET THE COMPUTE UNITS AND PRIORITY FEES HERE
        const unitsConsumed = (1_000_000) * 1.1
        // CAN ONLY CALL FOR MAINNET
        const priorityFeeMicroLamports = getPriorityFeesForComputeUnits(unitsConsumed)

        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: unitsConsumed,
        });

        const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: priorityFeeMicroLamports || 100_000,
        });

        const updatedTransaction = new Transaction()
        updatedTransaction.instructions = [modifyComputeUnits, addPriorityFee, ...transaction.instructions]

        updatedTransaction.recentBlockhash = recentBlock
        updatedTransaction.feePayer = feePayer

        // SET ACTUAL COMPUTE UNITS AND PRIORITY FEES
        const signedTx = await this.wallet.sendTransaction(updatedTransaction, client);

        return signedTx
    };
}
