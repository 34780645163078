import { useContext, useEffect, useRef, useState } from "react";
import {
  WrappedWalletContext,
  SonicContext,
  ToasterContext,
  NetworkContext,
} from "../../../../contexts";
import {
  fetchCheckinStatus,
  fetchCheckinTransaction,
  fetchFinishCheckin,
} from "../../../../data/sonic/task";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import {
  confirmTransaction,
  sendLegacyTransaction,
} from "../../../../data/lib/transactions";
import { Card, FoldingCard, LoadingOverlay } from "../commonComponents";
import { Button, IconFont } from "../../../common";
import { BoxToast } from "./BoxToast";
import { BASE_TOAST_CONFIG } from "../../../toast/BaseToast";
import { twMerge } from "tailwind-merge";
import gsap from "gsap";

let transactionHash = "";

const CheckInTab = () => {
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
  const { client } = useContext(NetworkContext)
  const toast = useContext(ToasterContext);
  const {
    token,
    networkId,
    showSonicNotification,
    setShowSonicNotification,
    refreshInfo,
  } = useContext(SonicContext);

  const [accumulativeDays, setAccumulativeDays] = useState(0);
  const [isChekingIn, setIsChekingIn] = useState(false);
  const [hasChecked, setHasChecked] = useState(true);
  const totalDays = 14;
  const {
    data: dataCheckInInfo,
    isLoading: loadingCheckInInfo,
    refetch: refetchCheckInInfo,
  } = useQuery({
    queryKey: ["queryCheckInInfo", walletPubkey],
    queryFn: () => fetchCheckinStatus({ token, networkId }),
    enabled: !!token && !!walletPubkey,
  });

  const mutationCheckIn = useMutation({
    mutationKey: ["mutationCheckIn", walletPubkey],
    mutationFn: () =>
      fetchFinishCheckin({ token, hash: transactionHash, networkId }),
    onSuccess({ data, status }) {
      if (data.checked) {
        setHasChecked(true);
        refetchCheckInInfo();
        refreshInfo();
        let rewards =
          Math.ceil((data.accumulative_days || 1) / (totalDays / 2)) || 1;
        // if (
        //   isInWalletCampaignTime(networkId) &&
        //   hasExtraWalletBonus(wallet, networkId)
        // ) {
        //   rewards++;
        // }
        console.warn("Check-in task completed. You get ", rewards, "boxes");
        toast(
          <BoxToast amount={rewards} message='"Check-in" task completed.' />,
          BASE_TOAST_CONFIG
        );
      }
    }
  });

  const triggerTransaction = async (transactionString: string) => {
    if (!walletPubkey || !solanaRpc || !client) {
      console.error("Wallet not connected");
      return;
    }

    try {
      const tx = Transaction.from(Buffer.from(transactionString, "base64"));

      const { txid, slot } = await sendLegacyTransaction(
        client,
        solanaRpc.signTransaction,
        tx,
        "confirmed"
      );

      if (!txid) {
        throw new Error("Could not retrieve transaction hash");
      }

      transactionHash = txid;

      const result = await confirmTransaction(
        client,
        transactionHash,
        "confirmed"
      );

      mutationCheckIn.mutate();
    } catch (error) {
      console.error("Transaction failed:", error);
    }

    setIsChekingIn(false);
  };

  const getTransactionHash = useMutation({
    mutationKey: ["buildCheckinTx", walletPubkey],
    mutationFn: () => fetchCheckinTransaction({ token, networkId }),
    onSuccess({ data }) {
      if (data?.hash) {
        triggerTransaction(data.hash);
      } else {
        setIsChekingIn(false);
      }
    },
  });

  const handleCheckIn = () => {
    if (
      !hasChecked &&
      !isChekingIn
      // && !isInMaintenance
    ) {
      setIsChekingIn(true);
      getTransactionHash.mutate();
    }
    // trackClick({ text: "Check-in" });
  };

  useEffect(() => {
    if (showSonicNotification) {
      setShowSonicNotification(false);
    }
  }, [showSonicNotification]);

  useEffect(() => {
    if (dataCheckInInfo?.data) {
      const { checked, accumulative_days } = dataCheckInInfo?.data;
      setHasChecked(checked);
      setAccumulativeDays(accumulative_days);
    }
  }, [dataCheckInInfo]);

  return (
    <>
      <Card
        title={
          <div className="text-xl font-bold">
            You have checked in for{" "}
            <span className="text-[#FC78FF]">{accumulativeDays}</span> day
          </div>
        }
      >
        <>
          <LoadingOverlay isLoading={loadingCheckInInfo} />
          <TimeLine days={accumulativeDays} className="-my-2"/>
          <div className="flex justify-between">
            <div className="flex items-center gap-1 text-lg text-[#979AB6]">
              1 - 7 days rewards:{" "}
              <div className="text-gray-50 flex items-baseline gap-1">
                x 1 <IconFont name="gift" size="xl" />
              </div>
            </div>
            <Button
              isLoading={isChekingIn}
              onClick={handleCheckIn}
              disabled={hasChecked}
              variant="primary"
            >
              Check-in
            </Button>
          </div>
        </>
      </Card>
      <FoldingCard
        title="Rules"
        content={
          <div className=" font-normal">
            <ul className="list-disc flex flex-col gap-3 ms-3">
              <li>
                Request test SOL first.{" "}
                <a
                  className="text-[#FC78FF] font-bold"
                  href="https://faucet.sonic.game/#/?network=testnet"
                  target="_blank"
                >
                  Request here.
                </a>
              </li>
              <li>
                Click "Check-in" button to complete the task and automatically
                receive the corresponding reward.
              </li>
              <li>
                If you miss a day, the reward count will reset and start over.
              </li>
              <li>
                <div className="flex flex-col gap-2">
                  Rewards Detail:
                  <ol className="flex flex-col gap-2">
                    <li>
                      a. Check in for 1-7 days to earn{" "}
                      <span className="text-amber-400">
                        1x Ring Mystery Box.
                      </span>
                    </li>
                    <li>
                      b. Check in for 8-14 days to earn{" "}
                      <span className="text-amber-400">
                        2x Ring Mystery Boxes.
                      </span>
                    </li>
                    <li>
                      c. Check in for over 14 days to earn{" "}
                      <span className="text-amber-400">
                        3x Ring Mystery Boxes.
                      </span>
                    </li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        }
      />
      {/* <FoldingCard
          title="History"
          content={
            !mysteryBoxHistory.length ? (
              <div className="text-gray-500 font-bold text-xl">
                No history yet...
              </div>
            ) : (
              <div className="text-gray-500 font-bold text-xl">
                Have some history
              </div>
            )
          }
        /> */}
    </>
  );
};

const TimeLine = ({
  days,
  className,
}: {
  days: number;
  className?: string;
}) => {
  const lineRef = useRef<HTMLInputElement | null>(null);
  const startValue = 0;
  const maxValue = 14;
  const maxWidth = 645;
  const minWidth = 15;

  const startWidth = (maxWidth / maxValue) * startValue + minWidth;
  const lineWidth = (maxWidth / maxValue) * days + minWidth;
  const endWidth = lineWidth > maxWidth ? maxWidth : lineWidth;

  useEffect(() => {
    const relativeDuration =
      Math.floor(((endWidth - startWidth) / maxWidth) * 30) / 10;

    const duration = endWidth === 0 ? 0 : relativeDuration;
    const ease =
      endWidth + maxWidth / 10 > maxWidth ? "easy.out" : "elastic.out(0.2,0.1)";
    const delay = 0.5;

    const timeObj = {
      delay: delay,
      repeatDelay: 0.5,
    };

    const tl =
      days > 0
        ? gsap.timeline(timeObj).fromTo(
            lineRef.current,
            {
              attr: { x2: startWidth },
            },
            {
              attr: { x2: endWidth },
              ease: ease,
              duration: duration,
            }
          )
        : null;
    return () => {
      tl?.clear();
    };
  }, [startWidth, endWidth, maxWidth]);

  return (
    <div className={twMerge("w-full flex flex-col gap-2", className)}>
      <svg
        className="-mx-[15px]"
        width="calc(100% + 30px)"
        height="36"
        viewBox="0 0 664 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 18C12 14.6863 14.6863 12 18 12H646C649.314 12 652 14.6863 652 18C652 21.3137 649.314 24 646 24H18C14.6863 24 12 21.3137 12 18Z"
          fill="#1B182B"
        />
        <g filter="url(#filter0_d_16703_16651)">
          {/* <path
              d="M12 18C12 14.6863 14.6863 12 18 12H646C649.314 12 652 14.6863 652 18C652 21.3137 649.314 24 646 24H18C14.6863 24 12 21.3137 12 18Z"
              fill="url(#paint0_linear_16703_16651)"
            /> */}
          {days > 0 ? (
            <line
              ref={lineRef}
              x1={minWidth}
              y1="18"
              x2={minWidth}
              y2="18"
              strokeWidth="13"
              stroke="url(#paint0_linear_16703_16651)"
              stroke-linecap="round"
            />
          ) : null}
        </g>
        <defs>
          <filter
            id="filter0_d_16703_16651"
            x="0"
            y="0"
            width="664"
            height="36"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="6" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.988235 0 0 0 0 0.470588 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_16703_16651"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_16703_16651"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_16703_16651"
            x1="12"
            y1="12"
            x2="643.819"
            y2="76.6331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#A550FF" />
            <stop offset="1" stop-color="#FC78FF" />
          </linearGradient>
        </defs>
      </svg>

      <div className="flex justify-between">
        <div className="text-[#979AB6] uppercase font-bold">1 day</div>
        <div className="text-[#979AB6] uppercase font-bold">7 days</div>
        <div className="text-[#979AB6] uppercase font-bold">14 days</div>
      </div>
    </div>
  );
};
export default CheckInTab;
