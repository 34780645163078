// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropdownInput .tippy-content {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}
.DropdownInput .tippy-box {
  border-radius: 0;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/dropdown-input/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,6BAA6B;AAC/B","sourcesContent":[".DropdownInput .tippy-content {\n  background-color: transparent;\n  border-radius: 0;\n  padding: 0;\n}\n.DropdownInput .tippy-box {\n  border-radius: 0;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
