import { useContext, useEffect, useState } from "react";

import { useModal } from "react-modal-hook";
import { RegistrationModal } from "../modals/RegistrationModal";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";
import {PlayerTokenContext} from "../contexts";
import { getData } from "../utils/supabase/supabase";
import { PublicKey } from "@solana/web3.js";

export function useOnboardingHandler() {
  const { walletPubkey } = useContext(WrappedWalletContext)
  const {
    playerToken, playerMeta, setPlayerMeta, hasNoPlayerTokenState
  } = useContext(PlayerTokenContext);
  const [currentStep, setCurrentStep] = useState(0);

  const [showRegistrationModal, hideRegistrationModal] = useModal(
    ({ in: open }) => (
      <RegistrationModal
        open={open}
        closeModal={() => {
          hideRegistrationModal();
          setCurrentStep(0);
        }}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
    ),
    [currentStep],
  );

  useEffect(() => {
    async function checkShowRegistration(wallet: PublicKey) {
      try {
        const data = await getData({
          wallet: wallet.toString()
        })

        // RECOGNISED THE USER IN THE DB, NO NEED TO SHOW T+Cs
      } catch (err) {
        console.warn({
          err
        })

        setCurrentStep(0);
        showRegistrationModal();
      }
    }

    // CHECK WALLET CONNCTED AND THAT PLAYER TOKEN OWNER IS EQUAL TO WALLET
    if (walletPubkey == null || playerToken == null || walletPubkey.toString() != playerToken.ownerPubkey.toString()) {
      return
    }

    // CHECK THE DB TO SEE IF WE RECOGNISE THIS WALLET

    if (hasNoPlayerTokenState == true && !playerMeta?.signedTerms) {
      checkShowRegistration(walletPubkey)
    }
  }, [hasNoPlayerTokenState, playerMeta, walletPubkey, playerToken]);

  const { solanaRpc } = useContext(WrappedWalletContext)

  // WIPE LOCAL STORAGE VALUES IF NEW WALLET IS CONNECTED
  useEffect(() => {
    const wallet = walletPubkey?.toString();

    if (walletPubkey == null) {
      if (playerMeta != null) {
        setPlayerMeta(undefined)
      } 
      
      return;
    }

    // WAIT UNTIL SOLANA RPC READY
    if (solanaRpc == null) {
      return
    }


    if (playerMeta?.walletPubkey == null || playerMeta?.walletPubkey != wallet) {
      setPlayerMeta({
        walletPubkey: wallet,
        signedTerms: false
      }, walletPubkey, solanaRpc);
    }
  }, [solanaRpc]);
}
