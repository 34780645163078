import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import bs58 from "bs58";
import { Commitment, Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, sendAndConfirmTransaction } from "@solana/web3.js";
import { NetworkContext } from "./NetworkContext";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { ProgramContext } from "./ProgramContext";
import { sendTransaction } from "../sdk/transactions";
import { APP_NETWORK_TYPE } from "../types/chain";

const ZEEBIT_AUTO_LAMPORTS_KEY = "zeebit-auto-signing-lamports"

export interface ISessionAuthorityContext {
  signerKp: string;
  setSignerKp: React.Dispatch<React.SetStateAction<string>>;
  signerPublicKey: PublicKey | undefined;
  allowsAutoSigning: boolean;
  setAllowsAutoSigning: React.Dispatch<React.SetStateAction<boolean>>;
  lamportBalance: number;
  withdrawSol: (solAmount?: number) => Promise<string>;
  topUpSol: (solAmount?: number) => Promise<string>;
  allowsAutoDeposit: boolean;
  setAllowsAutoDeposit: Function;
}

export const SessionAuthorityContext = createContext<ISessionAuthorityContext>({} as ISessionAuthorityContext);

interface Props {
  children: any;
}

export const SessionAuthorityProvider = ({ children }: Props) => {
  // WARNING IF LAMPORTS LEFT IN SESSION ACCOUNT
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const walletSolBalance = window.localStorage.getItem(ZEEBIT_AUTO_LAMPORTS_KEY)

      if (walletSolBalance != null && Number(walletSolBalance) > 0) {
        const message = 'There is some sol left in your session authority. Are you sure you want to leave? Withdraw the sol back to your wallet.';
        event.preventDefault();
        event.returnValue = message; // For most browsers
        return message; // For some older browsers
      }
    };

    console.error("Adding message")
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const [signerKp, setSignerKp] = useLocalStorage(`zeebit-signer`, undefined);

  useEffect(() => {
    if (signerKp == null) {
      setSignerKp(bs58.encode(new Keypair().secretKey))
    }
  }, [signerKp])

  const signerPubkey = useMemo(() => {
    if (signerKp == null) {
      return
    }

    try {
      return Keypair.fromSecretKey(bs58.decode(signerKp)).publicKey
    } catch (err) {
      console.warn({
        err
      })
    }
  }, [signerKp])

  const [allowsAutoSigning, setAllowsAutoSigning] = useLocalStorage(`zeebit-auto-signing`, undefined);
  
  // ON BY DEFAULT
  useEffect(() => {
    if (allowsAutoSigning == null) {
      setAllowsAutoSigning(true) 
    }
  }, [allowsAutoSigning])

  const [lamportBalance, setLamportBalance] = useLocalStorage(ZEEBIT_AUTO_LAMPORTS_KEY, 0);
  const { client, recentBlockhash } = useContext(NetworkContext)
  // LOAD BALANCE AND START WS TO GET WALLET UPDATES
  const wsId = useRef<number>()
  useEffect(() => {
    async function loadSolBalanceAndMonitorChanges(pubkey: PublicKey, connection: Connection, commitment: Commitment = "processed") {
      if (wsId.current != null) {
        try {
          await connection?.removeAccountChangeListener(wsId.current)
        } catch(err) {
          console.warn({
            err
          })
        }
      }

      const balance = await connection?.getAccountInfo(pubkey, commitment)
      setLamportBalance(balance?.lamports)

      wsId.current = connection.onAccountChange(pubkey, (accInfo, context) => {
        setLamportBalance(accInfo.lamports)
      }, {
        commitment: commitment
      })
    }

    if (signerPubkey == null || client == null) {
      return
    }


    loadSolBalanceAndMonitorChanges(signerPubkey, client)
  }, [signerPubkey, client])

  const { solanaRpc, walletPubkey, isWeb3AuthWallet } = useContext(WrappedWalletContext)
  const { meta } = useContext(ProgramContext)

  // DONT USE AUTO SIGNING FOR WEB3 auth wallet
  useEffect(() => {
    if (walletPubkey != null && isWeb3AuthWallet == true && allowsAutoSigning) {
      console.log(`SETTING TO TRUE FOR AUTO SIGNING`)
      setAllowsAutoSigning(false)
    }
  }, [allowsAutoSigning, walletPubkey, isWeb3AuthWallet])

  // METHODS TO TOPUP AND WITHDRAW THIS SOL
  const { chain } = useContext(NetworkContext)

  const topUpSol = useCallback(async (solAmount: number): Promise<string | undefined> => {
    if (signerPubkey == null || walletPubkey == null || client == null) throw new Error("Issue with session authority or wallet.")

    const lamports = Math.round(solAmount * LAMPORTS_PER_SOL)
    
    const transferIx = SystemProgram.transfer({
        fromPubkey: walletPubkey,
        toPubkey: signerPubkey,
        lamports: lamports,
      })
    

    return await sendTransaction(
      [transferIx], 
      client, 
      walletPubkey, 
      true, 
      chain,
      meta?.errorByCodeByProgram, 
      undefined, 
      solanaRpc?.signTransaction, 
      undefined, 
      undefined, 
      "confirmed", 
      undefined, 
      true)
  }, [chain, signerPubkey, walletPubkey, solanaRpc, client, recentBlockhash, meta])

  const withdrawSol = useCallback(async (solAmount?: number) => {
      let lamportsToWithdraw = solAmount != null ? Math.round(solAmount * LAMPORTS_PER_SOL): lamportBalance


      const txnFee = chain == APP_NETWORK_TYPE.SONIC ? 2500: 5000;
      
      const transferIx = SystemProgram.transfer({
          fromPubkey: signerPubkey,
          toPubkey: walletPubkey,
          lamports: lamportsToWithdraw - txnFee
        })

    // NEED TO CALC THE COMPUTE UNITS AND PRIORITY FEES
    const signerKeypair = Keypair.fromSecretKey(bs58.decode(signerKp))

    return await sendTransaction(
      [transferIx],
      client, 
      signerKeypair.publicKey,
      true,
      chain,
      meta?.errorByCodeByProgram,
      undefined,
      undefined,
      signerKeypair,
      undefined,
      "confirmed",
      undefined,
      true
    )
  }, [chain, signerKp, walletPubkey, client, lamportBalance, recentBlockhash, signerPubkey, chain])

  const [allowsAutoDeposit, setAllowsAutoDeposit] = useLocalStorage(`zeebit-auto-deposit`, false);

  // TODO: Hide and make always true as per Cian's request
  useEffect(() => {
    if(!allowsAutoDeposit) {
      setAllowsAutoDeposit(true);
    }
  }, [allowsAutoDeposit]);

  return (
    <SessionAuthorityContext.Provider
      value={useMemo(
        () => ({
          signerKp: signerKp,
          allowsAutoSigning: allowsAutoSigning,
          setAllowsAutoSigning: setAllowsAutoSigning,
          setSignerKp: setSignerKp,
          lamportBalance: lamportBalance,
          topUpSol: topUpSol,
          withdrawSol: withdrawSol,
          signerPublicKey: signerPubkey,
          allowsAutoDeposit,
          setAllowsAutoDeposit
        }),
        [
          signerKp,
          allowsAutoSigning,
          setAllowsAutoSigning,
          setSignerKp,
          lamportBalance,
          topUpSol,
          withdrawSol,
          signerPubkey,
          allowsAutoDeposit,
          setAllowsAutoDeposit
        ],
      )}
    >
      {children}
    </SessionAuthorityContext.Provider>
  );
};
