import React, { useMemo, useContext } from "react";
import {twMerge} from "tailwind-merge";

import Button from "../../components/common/button/Button";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import { NetworkContext } from "../../contexts";
import { APP_NETWORK_TYPE } from "../../types/chain";

interface ITermsAndConditionsStepProps {
  setCurrentStep: Function;
}

export const NetworkOnboardingStep: React.FC<ITermsAndConditionsStepProps> = ({
  setCurrentStep,
}) => {
  const { chain } = useContext(NetworkContext);

  const onboardingItems = useMemo(() => ({
    [APP_NETWORK_TYPE.SOLANA]: [
      {
        icon: "/static/networks-icons/magic-block.png",
        title: "MagicBlock Ephemeral Rollup",
        content: (<span>
          Zeebit gameplay is executed on
          &nbsp;<a href="/" target="_blank" className="underline text-pink-500">
            MagicBlock Ephemeral Rollup
          </a>&nbsp;
          making it super-fast and gas-free!
          Transactions are continuously settled to Solana and funds remain on Solana Mainnet.
        </span>)
      },
      {
        icon: "/static/play-chips-icon.png",
        title: "Play Balances",
        content: (<>
          You’ll need to delegate some “play funds” to use in your session. This is a type of account abstraction
          , and your funds remaining your control at all times.
          Zeebit is a decentralized protocol and your funds are not held by anyone else.
        </>)
      },
      {
        icon: "/static/auto-signing-icon.png",
        title: "Auto-signing",
        content: (<>
          Auto-signing is enabled by creating a temporary wallet in your browser.
          This wallet can do only two things: play games, or transfer the funds back to your wallet.
        </>)
      },
    ],
    [APP_NETWORK_TYPE.SONIC]: [
      {
        icon: "/static/auto-signing-icon.png",
        title: "Auto-signing",
        content: (<>
          Auto-signing is enabled by creating a temporary wallet in your browser. This wallet can do only two things
          : play games, or transfer the funds back to your wallet. This wallet needs a small balance of SOL for signing
          , and may need to be topped up occasionally. Be sure to collect this balance when you’re finished playing.
        </>)
      },
      {
        icon: "/static/play-chips-icon.png",
        title: "Play Balances",
        content: (<>
          To make your experience smoother, fund your “play balance”. This way you won’t need to sign for every transaction
          . This is a type of account abstraction, and your funds remaining your control at all times
          . Zeebit is a decentralized protocol and your funds are not held by anyone else.
        </>)
      },
      {
        icon: "/static/bridging-icon.png",
        title: "Bridging",
        content: (<>
          Gameplay is in Sonic SOL and Sonic SPL tokens. If you don’t have funds on Sonic
          , you can use the Bridging Tool to move funds from your Solana wallet to your Sonic wallet (and back again).
        </>)
      },
    ],
  }), [])

  return (
    <div className="flex w-full flex-col gap-y-3 items-center">

      <img src={getS3StaticFolderUrl("/static/zeebit-logo.png")} className="flex w-1/3"/>
      {
        chain === APP_NETWORK_TYPE.SONIC
          ? <img src={getS3StaticFolderUrl("/static/on-sonic-title.png")} className="flex h-4"/>
          : <img src={getS3StaticFolderUrl("/static/on-solana-title.png")} className="flex h-4"/>
      }
      <div
        className={`
          flex flex-col items-start gap-y-2 self-stretch mt-2
          [&>div]:rounded-lg [&>div]:bg-gray-600 [&>div]:p-3 [&>div>div]:self-start
          [&>div]:text-gray-50
        `}
      >
        {
          onboardingItems[chain === APP_NETWORK_TYPE.SONIC ? APP_NETWORK_TYPE.SONIC : APP_NETWORK_TYPE.SOLANA]
            .map(({icon, title, content}) => (
              <div
                className={twMerge(
                  "flex w-full text-gray-200 gap-4",
                )}
              >
                <div className="flex min-w-24 h-24 p-2 bg-gray-800 rounded-md justify-center items-center">
                  <img src={getS3StaticFolderUrl(icon)} className="flex h-full aspect-square"/>
                </div>
                <div className="flex flex-col basis-full gap-y-2">
                  <div className="flex font-semibold text-base">
                    {title}
                  </div>
                  <div className="flex font-normal text-gray-200 leading-5">
                    {content}
                  </div>
                </div>
              </div>
            ))
        }
      </div>
      <div className="flex items-start gap-x-3 self-stretch">
        <Button
          className="w-full"
          variant={"primary"}
          size="md"
          onClick={() => {
            setCurrentStep(step => step + 1);
          }}
        >
          I understand, let’s go
        </Button>
      </div>
    </div>
  );
};
