import React, { useCallback, useContext } from "react";
import { useModal } from "react-modal-hook";

import { BeforeLeavingModal } from "../../components/modals/BeforeLeavingModal";
import { AggregatedBalancesContext, NetworkContext, WrappedWalletContext } from "../../contexts";
import { APP_NETWORK_TYPE } from "../../types/chain";
import { useLocalStorage } from "../useLocalStorage";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";

export const ZEEBIT_SHOULD_HIDE_BEFORE_LEAVING_MODAL = "zeebit-should-hide-before-leaving-modal";

export const useBeforeLeavingModal = () => {
  const [shouldHideBeforeLeavingModal] = useLocalStorage(ZEEBIT_SHOULD_HIDE_BEFORE_LEAVING_MODAL, false);

  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { disconnect } = useContext(WrappedWalletContext);
  const { chain } = useContext(NetworkContext);

  const [showBeforeLeavingModal, hideBeforeLeavingModal] = useModal(
    ({ in: open }) => (
      <BeforeLeavingModal
        visible={open}
        hideModal={hideBeforeLeavingModal}
      />
    ),
    [shouldHideBeforeLeavingModal]
  );

  const { lamportBalance } = useContext(SessionAuthorityContext)

  const handleDisconnectWithModal = useCallback(() => {
    if (
      (mergedTokens?.every(mergedToken => (mergedToken?.playerToken?.playBalance || 0) === 0) && (lamportBalance || 0) == 0)
      || shouldHideBeforeLeavingModal
      // TODO: Temporary disable it for MagicBlock
      || chain === APP_NETWORK_TYPE.MB_AND_SOLANA
    ) {
      disconnect();
    } else {
      showBeforeLeavingModal();
    }
  }, [mergedTokens, disconnect, lamportBalance])

  return handleDisconnectWithModal;
};
