import { PublicKey } from "@solana/web3.js";

import NftStaking from "./nftStaking";
import { ATTRIBUTE_NAMES } from "./zeebrosCollection";

export enum DistributionStatus {
    Uninitialized = 'uninitialized',
    Initialized = 'initialized',
    Accruing = 'accruing',
    AwaitingAttributeRandomness = 'awaitingAttributeRandomness',
    AccruingAttributeSelected = 'accruingAttributeSelected',
    AwaitingSettleRandomness = 'awaitingSettleRandomness',
    TraitAndIndividualSelected = 'traitAndIndividualSelected',
    DistributionComplete = 'distributionComplete'
}


export default class Distribution {

    private _nftStakingInstance: NftStaking;
    private _state: any;
    private _instanceNonce: number;
    private _publicKey: PublicKey;

    constructor( 
        nftStakingInstance: NftStaking,
        pubkey: PublicKey,
        instanceNonce: number
    ) {
        this._nftStakingInstance = nftStakingInstance;
        this._instanceNonce = instanceNonce
        this._publicKey = pubkey;
    };

    static loadFromState(
        nftStakingInstance: NftStaking,
        pubkey: PublicKey,
        instanceNonce: number,
        accountState: any
    ) {
        const distribution = new Distribution(
            nftStakingInstance,
            pubkey,
            instanceNonce
        );
        distribution._state = accountState;
        return distribution;
    }

    get publicKey() {
        return this._publicKey
    }
    get instanceNonce() {
        return this._instanceNonce
    }
    get mainPubkey() {
        return this._state.main
    }
    get nftStakingInstance() {
        return this._nftStakingInstance
    }
    get state() {
        return this._state
    }
    get selectedAttribute() {
        return (this._state != undefined && this._state.selectedAttribute != 255 ? this._state.selectedAttribute : null)
    }
    get selectedAttributeName() {
        return this.selectedAttribute != null ? ATTRIBUTE_NAMES[this.selectedAttribute]: null
    }
    get selectedTraits() {
        return (this._state != undefined ? this._state.selectedTraits : null)
    }
    get individualVsTraitSplit() {
        return (this._state.individualVsTraitSplitPerThousand / 1000)
    }
    get status(): DistributionStatus | null {
        return (this._state ? Object.keys(this._state.status)[0] as DistributionStatus : null)
    }
    get distributionTime() {
        return (this._state ? new Date(Number(this._state.distributionTime)*1000) : null)
    }
    get attributeSelectionTime() {
        return (this._state ? new Date(Number(this._state.attributeSelectionTime)*1000) : null)
    }
    get individualTraitRecordsLeftToUpdate() {
        return (this._state ? Number(this._state.individualTraitRecordsLeftToUpdate) : null)
    }

    get discretionaryAmount() {
        return this._state ? Number(this._state.discretionaryAmount) : 0
    }

    get platformProfitShareAmount() {
        return this._state ? Number(this._state.platformProfitShareAmount) : 0
    }

    get royaltiesShareAmount() {
        return this._state ? Number(this._state.royaltiesShareAmount) : 0
    }

    get rolloverAmount() {
        return this._state ? Number(this._state.rolloverAmount) : 0
    }

    get unstakingShareAmount() {
        return this._state ? Number(this._state.unstakingShareAmount) : 0
    }

    get totalPrizePool() {
        return this.discretionaryAmount + this.platformProfitShareAmount + this.royaltiesShareAmount + this.unstakingShareAmount
    }

    get jackpotPrizePercentage() {
        return this.individualVsTraitSplit
    }

    get traitPrizePercentage() {
        return 1 - this.jackpotPrizePercentage
    }

    get jackpotPrize() {
        return (this.totalPrizePool * this.jackpotPrizePercentage) + this.rolloverAmount
    }

    get jackpotPrizeUI() {
        return this.jackpotPrize / Math.pow(10, this.nftStakingInstance.tokenDecimals)
    }

    get traitSelectedPrize() {
        return this.totalPrizePool * this.traitPrizePercentage
    }

    get traitSelectedPrizeUI() {
        return this.traitSelectedPrize / Math.pow(10, this.nftStakingInstance.tokenDecimals)
    }

    get freezeTime(): Date | undefined {
        return this.state?.freezeTime != null ? new Date(Number(this.state?.freezeTime) * 1000): undefined
    }

    // CHECK THE STATUS AND THE FREEZE
    get canTakeActions(): boolean {
        if (this.state == null) {
            return false
        }

        // CHCEK THE STATUS IS OK
        if (this.nftStakingInstance.isMainFrozen == true) {
            return false
        }

        // CHECK ITS NOT PAST THE FREEZE TIME...
        if (this.freezeTime == null) {
            return false
        }

        const currentTime = new Date()
        if (this.freezeTime > currentTime) {
            return false
        }

        return true
    }
}

