import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { ModalProvider as ReactModalProvider } from "react-modal-hook";

import "react-toastify/dist/ReactToastify.css";

import { ErrorHandlingProvider } from "./contexts/ErrorHandlingContext";
import { BalanceProvider } from "./contexts/BalanceContext";
import { HealthProvider } from "./contexts/HealthContext";
import { BrowserPreferenceProvider } from "./contexts/BrowserPreferenceContext";
import { HouseProvider } from "./contexts/HouseContext";
import { AggregatedBalancesProvider } from "./contexts/AggregatedBalancesContext";
import { BetstreamingProvider } from "./contexts/BetstreamingContext";
import { NetworkProvider } from "./contexts/NetworkContext";
import { ProgramProvider } from "./contexts/ProgramContext";
import { ModalProvider } from "./contexts/ModalContext";
import { NavProvider } from "./contexts/NavContext";
import { UserPreferenceProvider } from "./contexts/UserPreferenceContext";
import { BannerProvider } from "./contexts/BannerContext";
import { AudioProvider } from "./contexts/AudioContext";

import { ToasterContext } from "./contexts/ToasterContext";
import { MainContainer } from "./pages/MainContainer";
import { GamePagePreloader } from "./pages/preloaders/GamePagePreloader";
import { WrappedWalletProvider } from "./contexts/WrappedWalletContext";
import { SolanaWalletProvider } from "./contexts/SolanaWalletProvider";
import { PlayerTokenProvider } from "./contexts/PlayerTokenContext";
import { SocialWalletProvider } from "./contexts/SocialWalletContext";
import { SessionAuthorityProvider } from "./contexts/SessionAuthorityContext";
import { DataCachingProvider } from "./contexts/DataCachingContext";
import { HomepagePreloader } from "./pages/preloaders/HomepagePreloader";
import { TopBannerProvider } from "./contexts/TopBannerContext";
import { SonicProvider } from "./contexts/SonicContext";
import QueryClientProvider from "./contexts/QueryClientProvider";
import { NftStakingProvider } from "./contexts/NftStakingContext";
import ZeebrosPage from "./pages/ZeebrosPage";
import { BettingHistory } from "./components/txn-history/BettingHistory";
import { DynamicPreloader } from "./pages/preloaders/DynamicPreloader";

const HomePage = lazy(() => import("./pages/Homepage"));
const Error404Page = lazy(() => import("./pages/Error404Page"));
const GameContainer = lazy(() => import("./containers/GameContainer"));
const LeaderboardPage = lazy(() => import('./pages/LeaderboardPage'));
const Referrals = lazy(() => import('./pages/Referrals'));
const TransactionPage = lazy(() => import('./pages/TransactionPage'));

export const App = () => {
  return (
    <ToasterContext.Provider value={toast}>
      <ErrorHandlingProvider>
        <QueryClientProvider>
          <NetworkProvider>
            <HealthProvider>
              <ProgramProvider>
                <SolanaWalletProvider>
                  <SocialWalletProvider>
                    <WrappedWalletProvider>
                      <UserPreferenceProvider>
                        <BrowserPreferenceProvider>
                          <BalanceProvider>
                            <HouseProvider>
                              <DataCachingProvider>
                                <SessionAuthorityProvider>
                                  <PlayerTokenProvider>
                                    <NftStakingProvider>
                                      <BetstreamingProvider>
                                        <AggregatedBalancesProvider>
                                          <SonicProvider>
                                            <ModalProvider>
                                              <ReactModalProvider
                                                rootComponent={TransitionGroup}
                                              >
                                                <NavProvider>
                                                  <TopBannerProvider>
                                                    <BannerProvider>
                                                      <AudioProvider>
                                                        <BrowserRouter>
                                                          <Routes>
                                                            <Route
                                                              path=""
                                                              element={
                                                                <MainContainer />
                                                              }
                                                            >
                                                              <Route
                                                                path="zeebros"
                                                                element={
                                                                  <Suspense fallback={<HomepagePreloader />}>
                                                                    <ZeebrosPage />
                                                                  </Suspense>
                                                                }
                                                              />
                                                              <Route
                                                                path="referrals"
                                                                element={
                                                                  <Suspense fallback={<DynamicPreloader
                                                                    schema={[
                                                                      { type: 'tab', number: 4 },
                                                                      { type: 'box', heightClass: "h-28" },
                                                                      { type: 'box', heightClass: "h-60" },
                                                                      { type: 'box', heightClass: "h-80" },

                                                                    ]} />}>
                                                                    <Referrals />
                                                                  </Suspense>
                                                                }
                                                              />
                                                              <Route
                                                                path="transactions"
                                                                element={
                                                                  <Suspense fallback={<DynamicPreloader
                                                                    schema={[
                                                                      { type: 'tab', number: 3 },
                                                                      { type: 'tab', number: 2 },
                                                                      { type: 'table' }
                                                                    ]} />}>
                                                                    <TransactionPage />
                                                                  </Suspense>
                                                                }
                                                              />
                                                              <Route
                                                                path="profile"
                                                                element={
                                                                  <Suspense fallback={<HomepagePreloader />}>
                                                                    <BettingHistory />
                                                                  </Suspense>
                                                                }
                                                              />
                                                              <Route
                                                                path="leaderboard"
                                                                element={
                                                                  <Suspense fallback={<HomepagePreloader />}>
                                                                    <LeaderboardPage />
                                                                  </Suspense>
                                                                }
                                                              />

                                                              {/* GAME CONTEXT - spec state and instance state, logs listener, game result history, bet result history. load state, start WS */}
                                                              <Route
                                                                path="game/:gameSpecPubkeyString"
                                                                element={
                                                                  <Suspense
                                                                    fallback={
                                                                      <GamePagePreloader />
                                                                    }
                                                                  >
                                                                    <GameContainer />
                                                                  </Suspense>
                                                                }
                                                              />

                                                              <Route
                                                                path="*"
                                                                element={
                                                                  <Suspense
                                                                    fallback={
                                                                      <></>
                                                                    }
                                                                  >
                                                                    <Error404Page
                                                                      backHome={``}
                                                                    />
                                                                  </Suspense>
                                                                }
                                                              />
                                                              <Route
                                                                index
                                                                element={
                                                                  <Suspense
                                                                    fallback={
                                                                      <HomepagePreloader />
                                                                    }
                                                                  >
                                                                    <HomePage />
                                                                  </Suspense>
                                                                }
                                                              />
                                                            </Route>

                                                            <Route
                                                              path="*"
                                                              element={
                                                                <Suspense
                                                                  fallback={
                                                                    <></>
                                                                  }
                                                                >
                                                                  <Error404Page
                                                                    backHome={``}
                                                                  />
                                                                </Suspense>
                                                              }
                                                            />
                                                          </Routes>
                                                        </BrowserRouter>
                                                      </AudioProvider>
                                                    </BannerProvider>
                                                  </TopBannerProvider>
                                                </NavProvider>
                                              </ReactModalProvider>
                                            </ModalProvider>
                                          </SonicProvider>
                                        </AggregatedBalancesProvider>
                                      </BetstreamingProvider>
                                    </NftStakingProvider>
                                  </PlayerTokenProvider>
                                </SessionAuthorityProvider>
                              </DataCachingProvider>
                            </HouseProvider>
                          </BalanceProvider>
                        </BrowserPreferenceProvider>
                      </UserPreferenceProvider>
                    </WrappedWalletProvider>
                  </SocialWalletProvider>
                </SolanaWalletProvider>
              </ProgramProvider>
            </HealthProvider>
          </NetworkProvider>
        </QueryClientProvider>
      </ErrorHandlingProvider>
    </ToasterContext.Provider>
  );
};
