import { createContext, useCallback, useContext, useMemo, useState, useEffect } from "react";
import { BalanceContext, IChainBalance, IChainBalances } from "./BalanceContext";
import { HouseContext } from "./HouseContext";
import { ICasinoToken } from "../types/token";
import { PlayerTokenContext } from "./PlayerTokenContext";
import PlayerToken from "../sdk/playerToken";
import HouseToken from "../sdk/houseToken";
import { NATIVE_MINT } from "@solana/spl-token";
import { NetworkContext } from "./NetworkContext";

export interface IMergedToken {
  houseToken: HouseToken
  playerToken: PlayerToken | undefined;
  wallet: IChainBalance | undefined;
  context: ICasinoToken | undefined;
  combined?: IChainBalance; // PLAYER TOKEN AND WALLET COMBINED
  isUpdatingBalance?: boolean; 
}

export interface IAggregatedBalancesContext {
  solBalances: IChainBalances | undefined;
  mergedTokens: IMergedToken[] | undefined;
  mergedTokenByMint: Map<string, IMergedToken> | undefined
  primaryCombinedBalance: IChainBalance | undefined;
  tokenMetaByMint: Map<string, ICasinoToken>

  // UPDATING BALANCES
  removeBalancesBeingUpdated: (tokenMints: string[]) => string[]
  addBalancesBeingUpdated: (tokenMints: string[]) => string[]
  balancesBeingUpdated: string[]
}

export const AggregatedBalancesContext = createContext<IAggregatedBalancesContext>(
  {} as IAggregatedBalancesContext,
);

interface Props {
  children: any;
}

// BASIC CONTEXT TO COMBINE ATAs, PLAYER ACCOUNT TOKENS, HOUSE AND PLATFORM TOKEN CONFIGS
export const AggregatedBalancesProvider = ({ children }: Props) => {
  const { solBalances, selectedTokenMeta, balancesByMint, acceptingUpdates } = useContext(BalanceContext);

  // USED TO FILTER TOKENS
  const { houseTokens, houseTokenByMint } = useContext(HouseContext);
  const { playerToken, playerTokenByMint } = useContext(PlayerTokenContext);
  const { platformTokenMetaByPubkey } = useContext(NetworkContext)
  const tokenMetaByMint = useMemo(() => {
    return platformTokenMetaByPubkey
  }, [platformTokenMetaByPubkey]);

  // ARRAY TO HOLD ANY BALANCES BEING UPDATED
  const [balancesBeingUpdated, setBalancesBeingUpdated] = useState<string[]>()
  
  const addBalancesBeingUpdated = useCallback((tokenMints: string[]) => {
    const updatingSet = new Set<string>(balancesBeingUpdated || [])

    tokenMints.forEach((tokenMint) => {
      updatingSet.add(tokenMint)
    })

    const updated = Array.from(updatingSet)

    setBalancesBeingUpdated(updated)
    
    return updated;
  }, [balancesBeingUpdated])

  const removeBalancesBeingUpdated = useCallback((tokenMints: string[]) => {
    const updatingSet = new Set<string>(balancesBeingUpdated || [])
    tokenMints.forEach((tokenMint) => {
      if (updatingSet.has(tokenMint)) {
        updatingSet.delete(tokenMint)
      }
    })
    const updated = Array.from(updatingSet)

    setBalancesBeingUpdated(updated)

    return updated;
  }, [balancesBeingUpdated])

  const [mergedTokens, setMergedTokens] = useState<IMergedToken[] | undefined>()

  useEffect(() => {

    if (houseTokens == null && solBalances?.native != null) {
      setMergedTokens([
        {
          houseToken: undefined,
          playerToken: undefined,
          wallet: solBalances.native,
          context: platformTokenMetaByPubkey.get(NATIVE_MINT.toString()),
          combined: solBalances.native,
          isUpdatingBalance: false
        }
      ])
    }

    // ONLY TAKE UPDATES WHEN READY
    if (acceptingUpdates == false) {
      return
    }

    setMergedTokens(houseTokens?.map((hToken) => {
      const houseTokenMint = hToken.tokenMintPubkey.toString()

      const playerTokenForMint = playerTokenByMint?.get(houseTokenMint)
      const contextForMint = platformTokenMetaByPubkey.get(houseTokenMint)
      const walletBalance = houseTokenMint == NATIVE_MINT.toString() ? solBalances?.native : balancesByMint?.get(houseTokenMint)
      const walletBasis = walletBalance?.basis || 0
      const walletUi = (walletBalance?.uiAmount || 0)
      const playBalanceBasis = playerTokenForMint?.playBalance || 0
      const playBalanceUi = (playerTokenForMint?.playBalance || 0) / Math.pow(10, contextForMint?.decimals || 6)

      return {
        houseToken: hToken,
        playerToken: playerTokenForMint,
        wallet: walletBalance,
        context: contextForMint,
        combined: {
          ...walletBalance,
          basis: walletBasis + playBalanceBasis,
          uiAmount: walletUi + playBalanceUi
        },
        isUpdatingBalance: balancesBeingUpdated?.includes(hToken.tokenMintPubkey.toString()) || false
      }
    }))
  }, [houseTokens, playerTokenByMint, balancesByMint, playerToken, platformTokenMetaByPubkey, solBalances?.native, balancesBeingUpdated, acceptingUpdates])

  const mergedTokenByMint = useMemo(() => {
    return mergedTokens?.reduce((result, item) => {
      if (item.houseToken == null) return result

      result.set(item.houseToken.publicKey.toString(), item)

      return result
    }, new Map<string, IMergedToken>)
  }, [mergedTokens])

  const [combinedBalances, setCombinedBalances] = useState<IChainBalances>()
  
  useEffect(() => {
    // WAIT UNTIL ACCEPTING UPDATES
    if (acceptingUpdates == false) {
      return
    }

    setCombinedBalances({
      native: {
        ...solBalances?.native,
        meta: {
          context: tokenMetaByMint?.get(NATIVE_MINT.toString()),
          houseToken: houseTokenByMint?.get(NATIVE_MINT.toString()),
          playerToken: playerTokenByMint?.get(NATIVE_MINT.toString())
        }
      },
      primary: {
        ...solBalances?.primary,
        meta: {
          context: tokenMetaByMint?.get(selectedTokenMeta.mint),
          houseToken: houseTokenByMint?.get(selectedTokenMeta.mint),
          playerToken: playerTokenByMint?.get(selectedTokenMeta.mint)
        }
      },
      secondaries: solBalances?.secondaries?.map((secondary) => {
        return {
          ...secondary,
          meta: {
            context: tokenMetaByMint?.get(secondary.identifier),
            houseToken: houseTokenByMint?.get(secondary.identifier),
            playerToken: playerTokenByMint?.get(secondary.identifier)
          }
        }
      })
    })
  }, [solBalances, selectedTokenMeta, playerTokenByMint, houseTokenByMint, tokenMetaByMint, selectedTokenMeta, acceptingUpdates])

  return (
    <AggregatedBalancesContext.Provider
      value={useMemo(
        () => ({
          solBalances: combinedBalances,
          mergedTokens: mergedTokens,
          primaryCombinedBalance: combinedBalances?.primary,
          mergedTokenByMint: mergedTokenByMint,
          tokenMetaByMint: tokenMetaByMint,

          balancesBeingUpdated: balancesBeingUpdated,
          addBalancesBeingUpdated: addBalancesBeingUpdated,
          removeBalancesBeingUpdated: removeBalancesBeingUpdated
        }),
        [mergedTokens, combinedBalances, mergedTokenByMint, tokenMetaByMint, balancesBeingUpdated, addBalancesBeingUpdated, removeBalancesBeingUpdated],
      )}
    >
      {children}
    </AggregatedBalancesContext.Provider>
  );
};
