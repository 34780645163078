import { createContext, useCallback, useContext, useMemo } from "react";
import base58 from "bs58";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { createReferralScheme, fetchReferralMetrics, fetchReferralSchemes, fetchReferralSettlements, fetchReferralStats, fetchReferredPlayers, getData, getJwt, loadBettingHistory, loadTxnHistory, setData, validateReferralIdentifier } from "../utils/supabase/supabase";
import { IBetHistory, IGetDataResp, IReferralMetas, IReferralMetric, IReferralScheme, IReferralSettlement, ISetDataResp, ITransactionHistory } from "../utils/supabase/types";
import { PublicKey } from "@solana/web3.js";
import { ISolanaRpc } from "../utils/solana/rpc";

interface IJwt {
    jwt: string
    expiry: Date
    walletPubkey: string
}

export interface ICachedDataInput {
    username?: string
    emailAddress?: string
    acceptPlatformNotifications?: boolean,
    acceptProjectUpdateNotifications?: boolean,
}

export interface IDataCachingContext {
    cachedData: IGetDataResp | undefined
    jwt: IJwt | undefined
    getCachedData: () => Promise<IGetDataResp | undefined>
    updateCachedData: (data: ICachedDataInput, wallet?: PublicKey, rpc?: ISolanaRpc) => Promise<ISetDataResp | undefined>,
    setReferredBy: React.Dispatch<React.SetStateAction<string | undefined>>,
    referredBy: string | undefined
    validateReferralId: (referralId: string, wallet?: PublicKey, rpc?: ISolanaRpc) => Promise<boolean>,
    createReferral: (referralId: string, wallet?: PublicKey, rpc?: ISolanaRpc) => Promise<IReferralScheme>,
    getReferralSchemeMeta: (wallet?: PublicKey, rpc?: ISolanaRpc) => Promise<IReferralMetas>,
    loadReferralMetrics: (wallet?: PublicKey, rpc?: ISolanaRpc, referralId?: string, startTime?: number, endTime?: number) => Promise<IReferralMetric[]>,
    loadBets: (wallet?: PublicKey, rpc?: ISolanaRpc, chain?: string, timeFrom?: number, timeTo?: number, tokenMint?: PublicKey | string, gameSpec?: PublicKey, gameName?: string, page?: number, pageSize?: number) => Promise<IBetHistory[]>,
    loadTransactionHistory: (wallet?: PublicKey, rpc?: ISolanaRpc, chain?: string, timeFrom?: number, timeTo?: number, tokenMint?: PublicKey | string, page?: number, pageSize?: number, includeDeposits?: boolean, includeWithdrawals?: boolean) => Promise<ITransactionHistory[]>,
    loadReferralSettlements: (wallet?: PublicKey, rpc?: ISolanaRpc, chain?: string, timeFrom?: number, timeTo?: number, page?: number, pageSize?: number) => Promise<IReferralSettlement[]>
}

export const DataCachingContext = createContext<IDataCachingContext>({} as IDataCachingContext);

interface Props {
    children: any;
}

export const DataCachingProvider = ({ children }: Props) => {
    // STATE VARS
    const [jwt, setJwt] = useLocalStorage('zeebit-caching-acccess', undefined)
    const [cachedData, setCachedData] = useLocalStorage('zeebit-cached-data', undefined)
    const [referredBy, setReferredBy] = useLocalStorage('zeebit-referred-by', undefined)

    // CONTEXT
    const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)

    const loadJwt = useCallback(async (wallet?: PublicKey, rpc?: ISolanaRpc) => {
        const walletToUse = wallet != null ? wallet : walletPubkey;
        const rpcToUse = rpc != null ? rpc : solanaRpc;

        if (rpcToUse == null || walletToUse == null) {
            console.error('Wallet pubkey null, or solana client issue when loading access.')
            return undefined
        }

        const currentTime = new Date()

        const jwtMeta = jwt || {}
        const expiryDate = jwt != null ? new Date(jwt.expiry) : undefined
        const hasExpiredJwt = (!!expiryDate && (expiryDate < currentTime))
        const hasDifferentWallet = jwtMeta.walletPubkey != walletPubkey?.toString()
        const needsJwtUpdate = jwt == null || hasExpiredJwt || hasDifferentWallet

        if (needsJwtUpdate) {
            const currentTime = Math.floor(Date.now() / 1000);

            const message = `sign in at: ${currentTime}`;

            const signature = await rpcToUse.signMessage!(new TextEncoder().encode(message));
            const encodedSignature = base58.encode(signature);
            const newJwt = await getJwt({
                message,
                signature: encodedSignature,
                wallet: walletToUse?.toBase58(),
            })
            const expiry = new Date()
            expiry.setHours(expiry.getHours() + 1)
            const updatedJwt = {
                jwt: newJwt.jwt,
                expiry: expiry,
                walletPubkey: walletToUse.toString()
            }

            setJwt(updatedJwt)

            return updatedJwt
        }

        return jwtMeta
    }, [walletPubkey, solanaRpc, jwt, setJwt])

    const getCachedData = useCallback(async () => {
        if (walletPubkey == null) {
            console.error('Wallet pubkey null when fetching data')
            return
        }

        try {
            const jwt = await loadJwt()

            if (jwt == null) {
                return
            }

            const newData = await getData({
                isPrivate: true,
                wallet: walletPubkey.toBase58(),
                jwt: jwt.jwt
            })

            if (newData != null) {
                setCachedData(newData)
            }

            return newData
        } catch (err) {
            console.error("Issue loading cached data", err)
            return
        }
    }, [walletPubkey, jwt, loadJwt])

    const updateCachedData = useCallback(async (data: ICachedDataInput, wallet?: PublicKey, rpc?: ISolanaRpc) => {
        const rpcToUse = rpc || solanaRpc
        const walletToUse = wallet || walletPubkey

        if (walletToUse == null || rpcToUse == null) {
            console.error('Wallet pubkey null when setting data')
            return
        }

        try {
            const jwt = await loadJwt(walletToUse, rpcToUse)

            if (jwt == null) {
                throw new Error("A valid JWT is required to access this API.")
            }

            const dataPayload = {
                ...data,
                isPrivate: true,
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt,
                referredBy: referredBy
            }


            return await setData(dataPayload);
        } catch (err) {
            console.error({
                err
            })
            throw new Error("Issue updating the player.")
        }
    }, [walletPubkey, jwt, loadJwt, referredBy, solanaRpc])

    // VALIDATE REFERRAL SCHEME
    const validateReferralId = useCallback(async (referralIdentifier: string, wallet?: PublicKey, rpc?: ISolanaRpc): Promise<boolean> => {
        const walletToUse = wallet != null ? wallet : walletPubkey;

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A JWT is needed to interact with the API.")
            }

            const dataPayload = {
                wallet: walletToUse.toBase58(),
                identifier: referralIdentifier,
                jwt: jwt.jwt
            }

            try {
                const validated = await validateReferralIdentifier(dataPayload);

                return true
            } catch (err) {
                return false
            }
        } catch (err) {
            console.error("Issue validating the referral id", err)
            return false
        }
    }, [walletPubkey, jwt, loadJwt])

    // CREATE REFERRAL SCHEME
    const createReferral = useCallback(async (referralIdentifier: string, wallet?: PublicKey, rpc?: ISolanaRpc): Promise<IReferralScheme> => {
        const walletToUse = wallet != null ? wallet : walletPubkey

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to create a referral scheme.")
            }

            const dataPayload = {
                wallet: walletToUse.toBase58(),
                identifier: referralIdentifier,
                jwt: jwt.jwt
            }


            return await createReferralScheme(dataPayload);
        } catch (err) {
            throw new Error("Issue creating the referral scheme.")
        }
    }, [walletPubkey, jwt, loadJwt])

    const getReferralSchemeMeta = useCallback(async (wallet?: PublicKey, rpc?: ISolanaRpc): Promise<IReferralMetas> => {
        const walletToUse = wallet != null ? wallet : walletPubkey;

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to interact with the API.")
            }

            const schemesPayload = {
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt
            }


            const schemes = await fetchReferralSchemes(schemesPayload);

            const statsPayload = {
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt
            }
            const stats = await fetchReferralStats(statsPayload);

            const playersPayload = {
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt
            }
            const players = await fetchReferredPlayers(playersPayload);

            const metricsPayload = {
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt
            }
            const metrics = await fetchReferralMetrics(metricsPayload);

            const settlementsPayload = {
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt
            }
            const settlements = await fetchReferralSettlements(settlementsPayload);

            return {
                referralSchemes: schemes,
                referralStats: stats,
                referredPlayers: players,
                metrics: metrics,
                settlements: settlements
            }
        } catch (err) {
            throw new Error("Issue fetching the referred players.")
        }
    }, [walletPubkey, jwt, loadJwt])

    const loadReferralMetrics = useCallback(async (wallet?: PublicKey, rpc?: ISolanaRpc, referralId?: string, startTime?: number, endTime?: number): Promise<IReferralMetric[]> => {
        const walletToUse = wallet != null ? wallet : walletPubkey;

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to interact with the API.")
            }

            const metricsPayload = {
                wallet: walletToUse.toBase58(),
                jwt: jwt.jwt,
                referralId: referralId,
                startTime: startTime,
                endTime: endTime
            }

            console.log({
                metricsPayload
            })

            return await fetchReferralMetrics(metricsPayload);
        } catch (err) {
            throw new Error("Issue fetching the referred players.")
        }
    }, [walletPubkey, jwt, loadJwt])


    // LOAD BETS
    const loadBets = useCallback(async (wallet?: PublicKey, rpc?: ISolanaRpc, chain?: string, timeFrom?: number, timeTo?: number, tokenMint?: PublicKey, gameSpec?: PublicKey, gameName?: string, page?: number, pageSize?: number): Promise<IBetHistory[]> => {
        const walletToUse = wallet != null ? wallet : walletPubkey

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null when loading bets')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to create a referral scheme.")
            }

            const dataPayload = {
                wallet: walletToUse.toBase58(),
                chain: chain,
                timeFrom: timeFrom,
                timeTo: timeTo,
                tokenMint: tokenMint?.toString(),
                gameSpec: gameSpec?.toString(),
                gameName: gameName,
                jwt: jwt.jwt,
                page: page,
                pageSize: pageSize,
            }


            return await loadBettingHistory(dataPayload);
        } catch (err) {
            throw new Error("Issue creating the referral scheme.")
        }
    }, [walletPubkey, jwt, loadJwt])

    // LOAD TXN HISTORY
    const loadTransactionHistory = useCallback(async (wallet?: PublicKey, rpc?: ISolanaRpc, chain?: string, timeFrom?: number, timeTo?: number, tokenMint?: PublicKey, page?: number, pageSize?: number, includeDeposits?: boolean, includeWithdrawals?: boolean): Promise<ITransactionHistory[]> => {
        const walletToUse = wallet != null ? wallet : walletPubkey

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null when loading bets')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to create a referral scheme.")
            }

            const dataPayload = {
                wallet: walletToUse.toBase58(),
                chain: chain,
                timeFrom: timeFrom,
                timeTo: timeTo,
                tokenMint: tokenMint?.toString(),
                jwt: jwt.jwt,
                page: page,
                pageSize: pageSize,
                includeDeposits: includeDeposits,
                includeWithdrawals: includeWithdrawals
            }

            return await loadTxnHistory(dataPayload);
        } catch (err) {
            throw new Error("Issue creating the referral scheme.")
        }
    }, [walletPubkey, jwt, loadJwt])

    const loadReferralSettlements = useCallback(async (wallet?: PublicKey, rpc?: ISolanaRpc, referralId?: string, startTime?: number, endTime?: number, page?: number, pageSize?: number): Promise<IReferralSettlement[]> => {
        const walletToUse = wallet != null ? wallet : walletPubkey

        if (walletToUse == null) {
            throw new Error('Wallet pubkey null when loading bets')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt(wallet, rpc)

            if (jwt == null) {
                throw new Error("A valid JWT is required to load referral settlements.")
            }

            const dataPayload = {
                wallet: walletToUse.toBase58(),
                timeFrom: startTime,
                timeTo: endTime,
                jwt: jwt.jwt,
                page: page,
                pageSize: pageSize,
                referralId: referralId
            }

            return await fetchReferralSettlements(dataPayload);
        } catch (err) {
            throw new Error("Issue creating the referral scheme.")
        }
    }, [walletPubkey, jwt, loadJwt])

    return (
        <DataCachingContext.Provider
            value={useMemo(
                () => ({
                    cachedData: cachedData,
                    jwt: jwt,
                    getCachedData: getCachedData,
                    updateCachedData: updateCachedData,
                    validateReferralId: validateReferralId,
                    createReferral: createReferral,
                    getReferralSchemeMeta: getReferralSchemeMeta,
                    referredBy: referredBy,
                    setReferredBy: setReferredBy,
                    loadBets: loadBets,
                    loadTransactionHistory: loadTransactionHistory,
                    loadReferralMetrics: loadReferralMetrics,
                    loadReferralSettlements: loadReferralSettlements
                }),
                [cachedData, jwt, getCachedData, updateCachedData, validateReferralId, createReferral, getReferralSchemeMeta, referredBy, loadBets, loadTransactionHistory, loadReferralMetrics, loadReferralSettlements],
            )}
        >
            {children}
        </DataCachingContext.Provider>
    );
};
