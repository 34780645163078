import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import base58 from "bs58";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { createReferralScheme, fetchReferralSchemes, getData, getJwt, setData, validateReferralIdentifier } from "../utils/supabase/supabase";
import { IGetDataResp, IReferralScheme, ISetDataResp } from "../utils/supabase/types";

interface IJwt {
    jwt: string
    expiry: Date
    walletPubkey: string
}

export interface ICachedDataInput {
    username?: string
    emailAddress?: string
    acceptPlatformNotifications?: boolean,
    acceptProjectUpdateNotifications?: boolean,
}

export interface IDataCachingContext {
    cachedData: IGetDataResp | undefined
    jwt: IJwt | undefined
    getCachedData: () => Promise<IGetDataResp | undefined>
    updateCachedData: (data: ICachedDataInput) => Promise<ISetDataResp | undefined>,
    setReferredBy: React.Dispatch<React.SetStateAction<string | undefined>>,
    referredBy: string | undefined
}

export const DataCachingContext = createContext<IDataCachingContext>({} as IDataCachingContext);

interface Props {
    children: any;
}

export const DataCachingProvider = ({ children }: Props) => {
    // STATE VARS
    const [jwt, setJwt] = useLocalStorage('zeebit-caching-acccess', undefined)
    const [cachedData, setCachedData] = useLocalStorage('zeebit-cached-data', undefined)
    const [referredBy, setReferredBy] = useLocalStorage('zeebit-referred-by', undefined)

    // CONTEXT
    const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)

    const loadJwt = useCallback(async () => {
        if (solanaRpc == null || walletPubkey == null) {
            console.error('Wallet pubkey null, or solana client issue when loading access.')
            return undefined
        }

        const currentTime = new Date()

        const jwtMeta = jwt || {}
        const expiryDate = jwt != null ? new Date(jwt.expiry): undefined
        const hasExpiredJwt = (!!expiryDate && (expiryDate < currentTime))
        const hasDifferentWallet = jwtMeta.walletPubkey != walletPubkey?.toString()
        const needsJwtUpdate = jwt == null || hasExpiredJwt || hasDifferentWallet

        if (needsJwtUpdate) {
            const currentTime = Math.floor(Date.now() / 1000);

            const message = `sign in at: ${currentTime}`;
            const signature = await solanaRpc.signMessage!(new TextEncoder().encode(message));
            const encodedSignature = base58.encode(signature);
            const newJwt = await getJwt({
                message,
                signature: encodedSignature,
                wallet: walletPubkey?.toBase58(),
            })
            const expiry = new Date()
            expiry.setHours(expiry.getHours() + 1)
            const updatedJwt = {
                jwt: newJwt.jwt,
                expiry: expiry,
                walletPubkey: walletPubkey.toString()
            }

            setJwt(updatedJwt)

            return updatedJwt
        }

        return jwtMeta
    }, [walletPubkey, solanaRpc, jwt, setJwt])

    const getCachedData = useCallback(async () => {
        if (walletPubkey == null) {
            console.error('Wallet pubkey null when fetching data')
            return
        }

        try {
            const jwt = await loadJwt()

            if (jwt == null) {
                return
            }

            const newData = await getData({
                isPrivate: true,
                wallet: walletPubkey.toBase58(),
                jwt: jwt.jwt
            })

            if (newData != null) {
                setCachedData(newData)
            }

            return newData
        } catch (err) {
            console.error("Issue loading cached data", err)
            return
        }
    }, [walletPubkey, jwt, loadJwt])

    const updateCachedData = useCallback(async (data: ICachedDataInput) => {
        if (walletPubkey == null) {
            console.error('Wallet pubkey null when setting data')
            return
        }

        try {
            const jwt = await loadJwt()

            if (jwt == null) {
                throw new Error("A valid JWT is required to access this API.")
            }

            const dataPayload = {
                ...data,
                isPrivate: true,
                wallet: walletPubkey.toBase58(),
                jwt: jwt.jwt,
                referredBy: referredBy
            }


            return await setData(dataPayload);
        } catch (err) {
            console.error({
                err
            })
            throw new Error("Issue updating the player.")
        }
    }, [walletPubkey, jwt, loadJwt, referredBy])

    // VALIDATE REFERRAL SCHEME
    const validateReferralId = useCallback(async (referralIdentifier: string): Promise<boolean> => {
        if (walletPubkey == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt()

            if (jwt == null) {
                throw new Error("A JWT is needed to interact with the API.")
            }

            const dataPayload = {
                wallet: walletPubkey.toBase58(),
                identifier: referralIdentifier,
                jwt: jwt.jwt
            }

            try {
                const validated = await validateReferralIdentifier(dataPayload);
                
                return true
            } catch (err) {
                return false
            }
        } catch (err) {
            console.error("Issue validating the referral id", err)
            return false
        }
    }, [walletPubkey, jwt, loadJwt])

    // CREATE REFERRAL SCHEME
    const createReferral = useCallback(async (referralIdentifier: string): Promise<IReferralScheme> => {
        if (walletPubkey == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt()

            if (jwt == null) {
                throw new Error("A valid JWT is required to create a referral scheme.")
            }

            const dataPayload = {
                wallet: walletPubkey.toBase58(),
                identifier: referralIdentifier,
                jwt: jwt.jwt
            }


            return await createReferralScheme(dataPayload);
        } catch (err) {
            throw new Error("Issue creating the referral scheme.")
        }
    }, [walletPubkey, jwt, loadJwt])

    // RETRIEVE REFERRAL SCHEMES
    const getReferralSchemes = useCallback(async () => {
        if (walletPubkey == null) {
            throw new Error('Wallet pubkey null when validating referral scheme')
        }

        try {
            // NEED A JWT TO CALL THE API
            const jwt = await loadJwt()

            if (jwt == null) {
                throw new Error("A valid JWT is required to interact with the API.")
            }

            const dataPayload = {
                wallet: walletPubkey.toBase58(),
                jwt: jwt.jwt
            }


            return await fetchReferralSchemes(dataPayload);
        } catch (err) {
            throw new Error("Issue fetching the referral schemes.")
        }
    }, [walletPubkey, jwt, loadJwt])

    // LEFT IN TO SHOW REFERRALS FLOW - VALIDATE A NEW REFERRAL CODE, CREATE A NEW REFERRAL SCHEME, AND LOAD A PLAYERS REFERRAL SCHEME
    // REMOVE AFTER THE PAGE IS BUILT
    useEffect(() => {
        async function checkReferralId() {
            const referralId = 'tester-3'

            try {
                const canCreate = await validateReferralId(referralId)
                console.log({
                    canCreate
                })

                if (canCreate) {
                    const referral = await createReferral(referralId)
                    console.log({
                        referral
                    })
                }
            } catch (err) {
                console.error({
                    err
                })
            }

            try {
                const referrals = await getReferralSchemes()
                console.log({
                    referrals
                })
            } catch (err) {
                console.error({
                    err
                })
            }
        }

        if (walletPubkey == null) {
            return 
        }

        // checkReferralId()
    }, [walletPubkey])
    
    return (
        <DataCachingContext.Provider
            value={useMemo(
                () => ({
                    cachedData: cachedData,
                    jwt: jwt,
                    getCachedData: getCachedData,
                    updateCachedData: updateCachedData,
                    validateReferralId: validateReferralId,
                    createReferral: createReferral,
                    getReferralSchemes: getReferralSchemes,
                    referredBy: referredBy,
                    setReferredBy: setReferredBy
                }),
                [cachedData, jwt, getCachedData, updateCachedData, validateReferralId, createReferral, getReferralSchemes, referredBy],
            )}
        >
            {children}
        </DataCachingContext.Provider>
    );
};
