import wagers from "../../config/daily-wagers.json";

export interface IDailyWager {
  minimum: number;
  level: string;
  backgroundColor: string;
  glow: string;
  textColor: string;
}

export const getDailyWagers = (): IDailyWager[] => {
  return wagers;
};

export const getWagerMeta = (dailyWager: number): IDailyWager => {
  const allWagers = getDailyWagers();

  // WANT TO FIND CURRENT AND NEXT RANK
  if (dailyWager == 0) {
    allWagers[0];
  }

  const nextRankIndex = allWagers.findIndex((wager) => {
    return wager.minimum > dailyWager;
  });

  if (nextRankIndex > 0) {
    return allWagers[nextRankIndex - 1];
  }

  // ON THE TOP
  return allWagers[allWagers.length - 1];
};
