import React, { FC, useContext } from "react";
import sonicRing from "../../../static/sonic-rewards/sonic-ring.png";
import sonicBox from "../../../static/sonic-rewards/sonic-box.png";
import zeebitPoint from "../../../static/zeebit-point-icon.png";
import { SonicContext } from "../../../contexts/SonicContext";
import { useModal } from "react-modal-hook";
import RewardsModal, { RewardsModalTabs } from "./rewards-modal/RewardsModal";
import { MultiRewardBadge } from "./commonComponents";
import { twMerge } from "tailwind-merge";
import { RunningNumbersAnimation } from "../../common";
import { formatZeebitNumber, NumberType } from "../../../utils/currency/formatting";

export interface RewardsBarProps {
  className?: string;
}

const RewardsBar: FC<RewardsBarProps> = ({ className }) => {
  const {
    ringAmount,
    showSonicNotification,
    mysteryBoxAmount,
    sonicAvailable,
    playerZeebitPoints,
  } = useContext(SonicContext);

  const [showSonicRewardsModal, hideSonicRewardsModal] = useModal(
    ({ in: open }): JSX.Element => (
      <RewardsModal
        tab={RewardsModalTabs.CheckIn}
        visible={open}
        hideModal={hideSonicRewardsModal}
      />
    ),
    []
  );
  const [showZeebitQuestModal, hideZeebitQuestModal] = useModal(
    ({ in: open }): JSX.Element => (
      <RewardsModal
        tab={RewardsModalTabs.ZeebitQuests}
        visible={open}
        hideModal={hideZeebitQuestModal}
      />
    ),
    []
  );

  if (!sonicAvailable) return;

  return (
    <>
      <MultiRewardBadge
        title="Zeebit Points"
        value={
          <RunningNumbersAnimation
            key={playerZeebitPoints}
            stringToAnimate={(playerZeebitPoints || 0).toString()}
          />
        }
        iconUrl={zeebitPoint}
        classes={{
          button: twMerge(
            "border-pink-700 bg-zeebit-points-gradient",
            className
          ),
          title: "text-[#FC78FF]",
        }}
        onClick={showZeebitQuestModal}
      />
      <MultiRewardBadge
        title="Sonic Rewards"
        value={
          <RunningNumbersAnimation
            key={ringAmount}
            stringToAnimate={(formatZeebitNumber(ringAmount || 0, NumberType.DOLLAR_AMOUNT)).toString()}
          />
        }
        iconUrl={sonicRing}
        secondValue={String(mysteryBoxAmount)}
        secondIconUrl={sonicBox}
        showNotification={showSonicNotification}
        classes={{
          button: twMerge(
            "border-amber-700 bg-sonic-rewards-gradient",
            className
          ),
          title: "text-amber-400",
        }}
        onClick={showSonicRewardsModal}
      />
    </>
  );
};

export default RewardsBar;
