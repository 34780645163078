import React, { FC, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IPlatformGame } from "../../types/game";
import { BrowserPreferenceContext } from "../../contexts/BrowserPreferenceContext";
import NavItem from "../common/navigation-item/NavItem";
import { IconName } from "../common/iconFont/IconFont";
import Scrollbar from "../common/scrollbar/Scrollbar";
import Zeebros from "../../assets/zeebros/zeebros_z.png";
import { NftStakingContext } from "../../contexts/NftStakingContext";
import { ZeebrosPageTabs } from "../../pages/ZeebrosPage";


export interface NavProps {
  leftBarIsOpen: boolean;
  games: IPlatformGame[];
  toggleLeftPanel: (value?: boolean | undefined) => void;
  closeAfterClick?: boolean;
  activePage?: string | undefined;
  footerContent?: any;
}

const Nav: FC<NavProps> = ({
  leftBarIsOpen,
  games,
  toggleLeftPanel,
  closeAfterClick = false,
  activePage = undefined,
  footerContent,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { language } = useContext(BrowserPreferenceContext);
  const { setCurrentTab } = useContext(NftStakingContext);

  return (
    <Scrollbar handleColor="var(--gray-700)">
      <div className={`flex grow-0 flex-col md:grow `}>
        <nav
          className={`${leftBarIsOpen ? "mr-2" : ""} mb-2  flex flex-col`}
          onClick={() => {
            if (leftBarIsOpen == false) {
              toggleLeftPanel();
            }
          }}
        >
          <ul role="list" className="flex flex-1 flex-col gap-y-1">
            <NavItem
              isSelected={
                (activePage != null && activePage == "home") || pathName === "/"
              }
              isTopLevel
              key={"home"}
              iconName="home"
              collapsed={!leftBarIsOpen}
              title={"Home"}
              onClick={() => {
                navigate(``);
                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />
            <NavItem
              isSelected={
                (activePage != null && activePage == "zeebros") || pathName === "/zeebros"
              }
              isTopLevel
              key={"zeebros"}
              icon={<img src={Zeebros} className="drop-shadow-white-glow" />}
              collapsed={!leftBarIsOpen}
              title={"Zeebros NFT"}
              onClick={() => {
                setCurrentTab(ZeebrosPageTabs.General);
                navigate(`/zeebros`);
                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />
            <NavItem
              isSelected={false}
              isTopLevel
              key="games"
              iconName="play"
              collapsed={!leftBarIsOpen}
              title={"Games"}
              dropdownVisibleDefault={true}
            >
              {games.filter((game) => {
                return game.name != null
              }).map(
                (
                  { isComingSoon, name, isNew, gameSpecPubkey, icon },
                  index
                ) => (
                  <NavItem
                    isSelected={
                      (activePage != null && activePage == gameSpecPubkey) ||
                      pathName === `/game/${gameSpecPubkey}`
                    }
                    key={name[language]}
                    iconName={icon || ""}
                    collapsed={!leftBarIsOpen}
                    title={name[language]}
                    isNew={isNew}
                    isSoon={isComingSoon}
                    onClick={() => {
                      navigate(`/game/${gameSpecPubkey}`);

                      if (closeAfterClick == true) {
                        toggleLeftPanel(false);
                      }
                    }}
                  />
                )
              )}
            </NavItem>

            <NavItem
              isTopLevel
              isSelected={pathName === "/leaderboard"}
              key={"Leaderboard"}
              iconName="leadrboard"
              collapsed={!leftBarIsOpen}
              title={"Leaderboard"}
              onClick={() => {
                navigate(`/leaderboard`);

                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />

            {footerContent != null ? footerContent : ""}
          </ul>
        </nav>
      </div>
    </Scrollbar>
  );
};

export default Nav;
