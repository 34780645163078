import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";

import { ModalProps } from "./types";
import { BaseModal, Button, Tooltip } from "../common";
import { AwaitingModal } from "./AwaitingModal";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import {
  NetworkContext,
  PlayerTokenContext,
  BalanceContext,
  ToasterContext,
  AggregatedBalancesContext,
  WrappedWalletContext,
  HouseContext
} from "../../contexts";
import { BASE_TOAST_CONFIG, BaseToast } from "../toast/BaseToast";
import { getPlatformTokenMetaByPubkey, getS3StaticFolderUrl } from "../../utils/config/utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ZEEBIT_SHOULD_HIDE_BEFORE_LEAVING_MODAL } from "../../hooks/modals/useBeforeLeavingModal";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import { twMerge } from "tailwind-merge";
import { SessionAuthorityContext } from "../../contexts/SessionAuthorityContext";
import { NATIVE_MINT } from "@solana/spl-token";

export const BeforeLeavingModal: FC<ModalProps> = ({
  visible,
  hideModal,
}) => {
  const [hideBeforeLeavingModal, setHideBeforeLeavingModal] =
    useLocalStorage(ZEEBIT_SHOULD_HIDE_BEFORE_LEAVING_MODAL, false);

  const { mergedTokens } = useContext(AggregatedBalancesContext);

  const { disconnect } = useContext(WrappedWalletContext);
  const { selectedTokenMeta } = useContext(BalanceContext);
  const selectedMerged = useMemo(() => {
    return mergedTokens?.find((token) => {
      return token.context?.pubkey == selectedTokenMeta.mint
    })
  }, [mergedTokens, selectedTokenMeta])

  const toast = useContext(ToasterContext)

  const { processWithdrawalAll } = useContext(PlayerTokenContext)
  const { lamportBalance, withdrawSol } = useContext(SessionAuthorityContext)

  const [actionLoading, setActionLoading] = useState(false)
  const { client, recentBlockhash } = useContext(NetworkContext)
  const [statusMessageEr, setStatusMessageEr] = useState<string>()
  const [action, setAction] = useState<'deposit' | 'withdrawal'>()
  const actionHeading = useMemo(() => {
    if (action == null || statusMessageEr == null) {
      return
    }

    return action == 'deposit' ? 'Processing deposit': 'Processing withdrawal'
  }, [action, statusMessageEr])

  const { chain } = useContext(NetworkContext)
  const { houseTokenByMint } = useContext(HouseContext)
  const nativeContext = useMemo(() => {
    if (houseTokenByMint == null || chain == null) {
      return
    }

    const houseTokenSol = houseTokenByMint.get(NATIVE_MINT.toString())
    const context = getPlatformTokenMetaByPubkey(chain).get(NATIVE_MINT.toString())

    return {
      houseToken: houseTokenSol,
      context: context
    }
  }, [houseTokenByMint, chain])

  const filteredMergedTokens = useMemo(
    () => {
      const filtered = mergedTokens
      ?.filter(mergedToken => {
        return (mergedToken?.playerToken?.playBalance || 0) > 0
      })

      const solRow = filtered?.find((row) => {
        return row.playerToken?.houseToken.tokenMintPubkey.toString() == NATIVE_MINT.toString()
      })

      const needRowForSessionKeys = (lamportBalance || 0) > 0 && solRow == null

      return needRowForSessionKeys ? [
        {
          playerToken: undefined,
          houseToken: nativeContext?.houseToken,
          context: nativeContext?.context
        },
        ...(filtered || [])
      ]: filtered
    }, [mergedTokens, lamportBalance, nativeContext])

  const [showStatusLoadingModal, hideStatusLoadingModal] = useModal(
    ({ in: open }) => (
      <AwaitingModal
        visible={open}
        hideModal={hideStatusLoadingModal}
        mainText={actionHeading}
        secondaryText={statusMessageEr}
      />
    ),
    [statusMessageEr, actionHeading],
  );

  useEffect(() => {
    if (action == null) {
      hideStatusLoadingModal?.()
    } else {
      showStatusLoadingModal?.()
    }
  }, [action, showStatusLoadingModal, hideStatusLoadingModal])

  const handleWithdrawAll = useCallback(async () => {
    try {
      setActionLoading(true)

      const withAvailableBalance = (mergedTokens || []).filter((token) => {
        return (token.playerToken?.availableBalance || 0) > 0
      })

      if (withAvailableBalance.length > 0) {
        if (selectedMerged?.playerToken?.houseToken.isDelegated == false) {
          setAction(undefined)
          setStatusMessageEr(undefined)
        } else {
          setAction('withdrawal')
        }
  
        await processWithdrawalAll(withAvailableBalance, setStatusMessageEr);
      }

      // ALSO WANT TO WITHDRAW AUTO SIGNER BALANCE...
      if ((lamportBalance || 0) > 0) {
        await withdrawSol()
      }

      toast(
        <BaseToast
          message={`Successfully withdrew all tokens to the wallet.`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      disconnect();
      hideModal();
    } catch (err) {
      console.error({ err });
      toast(
        <BaseToast
          message={`There was an issue withdrawing tokens.`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    } finally {
      setActionLoading(false);
      setAction(undefined)
    }
  }, [selectedMerged, processWithdrawalAll, client, recentBlockhash, lamportBalance, withdrawSol, mergedTokens]);

  return (
    <BaseModal
    open={visible}
    withHeading={false}
    onClose={hideModal}
    classes={{
      dialog: `w-[342px] sm:max-w-[420px] bg-gray-800 p-5`
    }}
  >
    <div
      data-id="transfer-to-auto-balance"
      className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
    >
      <div
        className={twMerge(
          "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
          SCROLLBAR_CLASSES_BLACK
        )}
      >
        <div className="flex w-full flex-col gap-y-4 items-center">
          <div className="flex w-full mt-3 justify-center">
            <img src={getS3StaticFolderUrl("/static/wallet-icon.png")} className="flex w-1/2 max-w-[120px]" />
          </div>

          <div
            className="flex flex-col items-center gap-y-2.5 self-stretch text-center"
          >
            <div className="text-xl font-semibold">Withdraw all funds</div>
            <div className="subtitle text-base font-normal text-gray-200">
              Transfer all funds from ‘Play Balance’ and ‘Auto-Signing’ keys back to your wallet before leaving?
            </div>
          </div>

          <div
            className="flex flex-col items-center gap-y-2 self-stretch text-center"
          >
           {(filteredMergedTokens || []).map((
            token, index
           ) => {
            const hasAutoSignLamports = token.houseToken?.tokenMintPubkey.toString() == NATIVE_MINT.toString() && lamportBalance > 0
            const playBalance =  (token?.playerToken?.playBalance || 0)
            const balanceAndPtBalance = hasAutoSignLamports ? playBalance + lamportBalance: playBalance
            const decimals = token.context?.decimals || 6

            return <div className="flex flex-row w-full bg-gray-600 p-3 justify-between rounded-md" key={index}>
              <div className="flex items-center gap-x-2">
                <img src={getS3StaticFolderUrl(token.context?.imageDarkPng || '')} className="w-[18px] h-[18px]" />
                {formatZeebitNumber(balanceAndPtBalance / Math.pow(10, decimals), NumberType.TOKEN_AMOUNT, undefined, decimals)}
              </div>
              <div className="flex items-center gap-x-2">
                {/* AUTO SIGNER LAMPORTS */}
                {hasAutoSignLamports ? <Tooltip content={"SOL left in session keys."}>
                  <img src={getS3StaticFolderUrl("/static/auto-signing-icon.png")} className="w-[30px]" />
                </Tooltip>: ''}
                {playBalance > 0 ? <Tooltip content={"Play balance."}>
                  <img src={getS3StaticFolderUrl("/static/play-chips-icon.png")} className="w-[30px]" />
                </Tooltip>: ''}
              </div>
            </div>
           })}
          </div>
        </div>

        <div className="flex w-full gap-x-3">
        <Button
            className="w-full"
            variant="gray"
            size="sm"
            onClick={hideModal}
          >
            Continue Playing
          </Button>
          <Button
            className="w-full"
            variant="primary"
            size="sm"
            isLoading={actionLoading}
            onClick={handleWithdrawAll}
          >
            Withdraw all
          </Button>
        </div>
      </div>
    </div>
  </BaseModal>
  );
};
