import React from 'react';
import { twMerge } from "tailwind-merge";

import { HomePageTableLoading } from "../../components/common/table/Table";

export const HomepagePreloader = ({}) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full gap-x-3">
        {
          [1, 2, 3].map((item) => (
            <div
              key={item}
              className={twMerge(
                "flex bg-animated-gradient-gray-700-gray-800 h-auto w-full rounded-md",
                "md:w-[calc(50%-6px)] lg:w-[calc(33.33%-8px)] aspect-[2/1]"
              )}
            />
          ))
        }
      </div>
      <div className="flex justify-between h-8 mt-7">
        <div
          className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md"
        />
        <div className="flex h-full gap-x-2">
          {
            [1, 2].map((item) => (
              <div key={item} className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-8 rounded-md"/>
            ))
          }
        </div>
      </div>
      <div className="flex w-full mt-5 gap-x-3">
        {
          [1, 2, 3, 4, 5, 6].map((item) => (
            <div
              key={item}
              className={twMerge(
                "flex bg-animated-gradient-gray-700-gray-800 rounded-md",
                "w-[calc(40%)] sm:w-[calc(33.33%-8px)] md:w-[calc(25%-9px)]",
                "lg:w-[calc(20%-6.9px)] xl:w-[calc(16.67%-10px)] aspect-[4/5.5]"
              )}
            />
          ))
        }
      </div>
      <div className="flex justify-between h-8 mt-7">
        <div
          className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-36 rounded-md"
        />
        <div className="flex h-full gap-x-2">
          {
            [1, 2, 3].map((item) => (
              <div key={item} className="flex bg-animated-gradient-gray-700-gray-800 h-auto w-24 rounded-md"/>
            ))
          }
        </div>
      </div>
      <div className="flex justify-between mt-7">
        <HomePageTableLoading numberRows={5} />
      </div>
    </div>
  );
};