import { createElement } from "react";

const innerFunction = (element, props) => {
  const tagName = element.tagName;
  let _props = props || {};

  for (let i = 0; i < element.attributes.length; i++) {
    _props[element.attributes[i].nodeName] = element.attributes[i].nodeValue;
  }

  let children = Array.from(element.children).map((item) => innerFunction(item));

  return createElement(tagName, _props, children);
};

export const convertDocEleToReact = (element, props) => {
  try {
    return innerFunction(element, props);
  } catch (ex) {
    return createElement("span", {}, "Error loading svg image");
  }
};
