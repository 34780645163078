import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
import { ModalProps } from "./types";
import { BaseModal } from "../common";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import { Image } from "../zeebros-page/commonComponents";
import signingPictureUrl from "../../static/signing-picture.png";

interface ISigningModalProps extends ModalProps {
  mainText?: string;
  secondaryText?: string;
};

export const SigningModal: FC<ISigningModalProps> = ({
  visible,
  hideModal,
  mainText,
  secondaryText,
}) => (
  <BaseModal
    open={visible}
    onClose={hideModal}
    classes={{
      dialog: `w-[342px] sm:max-w-[400px] bg-gray-800 p-5`
    }}
    withHeading={false}
  >
    <div
      data-id="awaiting-modal"
      className="flex items-center max-h-[70vh] relative"
    >
      <div
        className={twMerge(
          "flex flex-col overflow-y-auto w-full p-3 -mr-3 gap-y-5",
          SCROLLBAR_CLASSES_BLACK
        )}
      >
        <div className="flex w-full flex-col items-center font-normal gap-y-1.5">
          <Image url={signingPictureUrl} classes={{ wrapper: "mb-6 bg-transparent", image: 'w-[160px] h-[160px]' }} />

          {
            mainText
            && (<div className="flex font-semibold">{mainText}</div>)
          }
          {
            secondaryText
            && (<div className="flex text-gray-300">{secondaryText}</div>)
          }
        </div>
      </div>
    </div>
  </BaseModal>
);
