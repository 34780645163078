import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { Connection } from "@solana/web3.js";
import {
  APP_NETWORK_TYPE,
  NetworkType,
  defaultNetworkType,
} from "../types/chain";
import { defaultNetwork } from "../utils/chain/network";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  APP_NETWORK,
  ENV_NAME,
  getRpcReadEndpoint,
  getRpcWriteEndpoints,
} from "../utils/env/env";
import { BLOCKHASH_COMMITMENT } from "../utils/solana/rpc";
import { RPC_ENDPOINT_URL_MAGICBLOCK } from "../sdk/constants";
import {
  getChainName,
  getPlatformGames,
  getPlatformTokenMetaByPubkey,
  getPlatformTokens,
} from "../utils/config/utils";
import { ICasinoToken } from "../types/token";
import { IPlatformGame } from "../types/game";
import { ENABLED_NETWORKS } from "../constants/chains";

export interface IRecentBlockhash {
  blockhash: string;
  lastValidBlockHeight: number;
}

export interface INetworkContext {
  chain: APP_NETWORK_TYPE;
  chainDisplayName: string;
  setChain: React.Dispatch<React.SetStateAction<APP_NETWORK_TYPE | undefined>>;
  network: NetworkType;
  networkUrl: string;
  setNetworkUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
  client: Connection | undefined;
  erClient: Connection | undefined;
  recentBlockhash: IRecentBlockhash;
  networkCounter: number;
  platformTokens: ICasinoToken[];
  platformTokenMetaByPubkey: Map<string, ICasinoToken>;
  platformGames: IPlatformGame[];
  writeEndpoints: string[];
}

export const NetworkContext = createContext<INetworkContext>(
  {} as INetworkContext
);

interface Props {
  children: any;
}

export const NetworkProvider = ({ children }: Props) => {
  const [chain, setChain] = useLocalStorage(`zeebit-app-chain`, APP_NETWORK);
  const [network, setNetwork] = useState<NetworkType>(defaultNetworkType);
  const [networkUrl, setNetworkUrl] = useLocalStorage(
    `rpc-endpoint-${ENV_NAME}`,
    getRpcReadEndpoint(APP_NETWORK) || ""
  );
  const [writeEndpoints, setWriteEndpoints] = useLocalStorage(
    `rpc-write-endpoint-${ENV_NAME}`,
    getRpcWriteEndpoints(APP_NETWORK) || ""
  );

  useEffect(() => {
    if (chain == null) {
      return;
    }

    const allowedChain = ENABLED_NETWORKS.includes(chain) ? chain: ENABLED_NETWORKS[0]

    if (chain != allowedChain) {
      setChain(allowedChain)
    }

    setNetworkUrl(getRpcReadEndpoint(allowedChain));
    setWriteEndpoints(getRpcWriteEndpoints(allowedChain));
  }, [chain]);

  const client = useMemo(() => {
    try {
      return new Connection(networkUrl, {
        commitment: "confirmed",
      });
    } catch (e) {
      console.warn("Issue loading the client", e);
    }
  }, [networkUrl]);

  const erClient = useMemo(() => {
    try {
      return new Connection(RPC_ENDPOINT_URL_MAGICBLOCK, {
        commitment: "confirmed",
      });
    } catch (e) {
      console.warn("Issue loading the er client", e);
    }
  }, []);
  const [recentBlockhash, setRecentBlockhash] = useState<IRecentBlockhash>();

  const intervalRef = useRef<number>();
  const clientRef = useRef<Connection>();
  const counterRef = useRef<number>(0);
  const [counter, setCounter] = useState<number>();

  useEffect(() => {
    async function loadRecentBlockhash() {
      try {
        // LOAD RECENT BLOCKHASH CONFIRMED/FINALIZED (PROCESSED CAN BE DROPPED...)
        const recentHash =
          await clientRef.current?.getLatestBlockhash(BLOCKHASH_COMMITMENT);
        setRecentBlockhash(recentHash);

        counterRef.current += 1;
        setCounter(counterRef.current);
      } catch (err) {
        console.warn("Error loading blockhash", err);
      }
    }

    if (client != null) {
      clientRef.current = client;
      loadRecentBlockhash();

      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }

      intervalRef.current = setInterval(() => {
        loadRecentBlockhash();
      }, 10000);
    }

    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [client]);

  const platformTokens = useMemo(() => {
    if (chain == null) return [];

    return getPlatformTokens(defaultNetwork, chain);
  }, [chain]);

  const platformTokenMetaByPubkey = useMemo(() => {
    return getPlatformTokenMetaByPubkey(chain);
  }, [chain]);

  const platformGames = useMemo(() => {
    return getPlatformGames(chain);
  }, [chain]);

  const chainDisplayName = useMemo(() => {
    return getChainName(chain);
  }, [chain]);

  return (
    <NetworkContext.Provider
      value={useMemo(
        () => ({
          network: network,
          networkUrl: networkUrl,
          client: client,
          erClient: erClient,
          recentBlockhash: recentBlockhash,
          networkCounter: counter,
          setNetworkUrl: setNetworkUrl,
          chain: chain,
          setChain: setChain,
          platformTokens: platformTokens,
          platformTokenMetaByPubkey: platformTokenMetaByPubkey,
          platformGames: platformGames,
          writeEndpoints: writeEndpoints,
          chainDisplayName: chainDisplayName,
        }),
        [
          network,
          networkUrl,
          client,
          recentBlockhash,
          counter,
          setNetworkUrl,
          erClient,
          chain,
          platformTokens,
          platformTokenMetaByPubkey,
          platformGames,
          writeEndpoints,
          chainDisplayName,
        ]
      )}
    >
      {children}
    </NetworkContext.Provider>
  );
};
