import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";

import { ModalProps } from "./types";
import { BaseModal, Button } from "../common";
import { CheckBox, CheckboxSize } from "../common/check-box/check-box";
import { AwaitingModal } from "./AwaitingModal";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import {
  NetworkContext,
  PlayerTokenContext,
  BalanceContext,
  ToasterContext,
  AggregatedBalancesContext,
  WrappedWalletContext
} from "../../contexts";
import { BASE_TOAST_CONFIG, BaseToast } from "../toast/BaseToast";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ZEEBIT_SHOULD_HIDE_BEFORE_LEAVING_MODAL } from "../../hooks/modals/useBeforeLeavingModal";

export const BeforeLeavingModal: FC<ModalProps> = ({
  visible,
  hideModal,
}) => {
  const [hideBeforeLeavingModal, setHideBeforeLeavingModal] =
    useLocalStorage(ZEEBIT_SHOULD_HIDE_BEFORE_LEAVING_MODAL, false);

  const { mergedTokens } = useContext(AggregatedBalancesContext);

  const { disconnect } = useContext(WrappedWalletContext);
  const { selectedTokenMeta } = useContext(BalanceContext);
  const selectedMerged = useMemo(() => {
    return mergedTokens?.find((token) => {
      return token.context?.pubkey == selectedTokenMeta.mint
    })
  }, [mergedTokens, selectedTokenMeta])

  const toast = useContext(ToasterContext)

  const { processWithdrawalAll } = useContext(PlayerTokenContext)

  const [actionLoading, setActionLoading] = useState(false)
  const { client, recentBlockhash } = useContext(NetworkContext)
  const [statusMessageEr, setStatusMessageEr] = useState<string>()
  const [action, setAction] = useState<'deposit' | 'withdrawal'>()
  const actionHeading = useMemo(() => {
    if (action == null || statusMessageEr == null) {
      return
    }

    return action == 'deposit' ? 'Processing deposit': 'Processing withdrawal'
  }, [action, statusMessageEr])

  const filteredMergedTokens = useMemo(
    () => mergedTokens
      ?.filter(mergedToken => (mergedToken?.playerToken?.playBalance || 0) > 0)
    , [mergedTokens]
  )

  const [showStatusLoadingModal, hideStatusLoadingModal] = useModal(
    ({ in: open }) => (
      <AwaitingModal
        visible={open}
        hideModal={hideStatusLoadingModal}
        mainText={actionHeading}
        secondaryText={statusMessageEr}
      />
    ),
    [statusMessageEr, actionHeading],
  );

  useEffect(() => {
    if (action == null) {
      hideStatusLoadingModal?.()
    } else {
      showStatusLoadingModal?.()
    }
  }, [action, showStatusLoadingModal, hideStatusLoadingModal])

  const handleWithdrawAll = useCallback(async () => {
    try {
      setActionLoading(true)

      if (selectedMerged?.playerToken?.houseToken.isDelegated == false) {
        setAction(undefined)
        setStatusMessageEr(undefined)
      } else {
        setAction('withdrawal')
      }

      await processWithdrawalAll(mergedTokens, setStatusMessageEr);

      toast(
        <BaseToast
          message={`Successfully withdrew all tokens to the wallet.`}
          type={"success"}
        />,
        BASE_TOAST_CONFIG,
      );

      disconnect();
      hideModal();
    } catch (err) {
      console.error({ err });
      toast(
        <BaseToast
          message={`There was an issue withdrawing tokens.`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    } finally {
      setActionLoading(false);
      setAction(undefined)
    }
  }, [selectedMerged, processWithdrawalAll, client, recentBlockhash]);

  return (
    <BaseModal
      open={visible}
      title="Leaving Zeebit"
      onClose={() => {
        hideModal();
        // hideAutoSigningAgreementModal();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[560px] bg-gray-800 p-5`
      }}
    >
      <div
        data-id="leaving-zeebit"
        className="flex w-full flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div className="flex w-full [&>div]:justify-center [&>div]:items-center my-5">
          <div className="flex flex-col gap-y-1 grow-0 px-4">
            <img src={getS3StaticFolderUrl("/static/play-chips-icon.png")} className="flex w-20"/>
            <div className="flex whitespace-nowrap font-semibold text-sm">Play Balance</div>
          </div>

          <div className="flex relative w-full">
            <div className="flex w-full absolute top-1/2 -z-10">
              <div className="flex w-full border-b-2 border-gray-400 -translate-y-1/2 absolute"/>
              <div className="flex absolute border-t-2 border-r-2 border-gray-400 h-3 w-3 -top-1.5 rotate-45 right-0"/>
            </div>
            <div className="flex flex-col rounded-lg border-2 border-gray-400 p-4 gap-y-3 bg-gray-800">
              {
                filteredMergedTokens
                  ?.map(mergedToken => (
                    <div className="flex gap-x-1">
                      <img src={mergedToken?.context?.imageDarkPng || ""} className="h-4"/>
                      {
                        formatZeebitNumber(
                          (mergedToken?.playerToken?.playBalance || 0) / (10 ** (mergedToken?.context?.decimals || 0)),
                          NumberType.TOKEN_AMOUNT,
                          10,
                          mergedToken?.context?.decimals || 6
                        )
                      }
                    </div>
                  ))
              }
            </div>
          </div>

          <div className="flex flex-col gap-y-1 grow-0 px-4">
            <img src={getS3StaticFolderUrl("/static/wallet-icon.png")} className="flex w-20"/>
            <div className="flex font-semibold text-sm">8fgh0.....99hs1</div>
          </div>
        </div>
        <div className="flex text-center font-normal leading-5">
          Looks like you’re leaving. Do you want to transfer all funds from ‘Play Balance’ back to your wallet before leaving?

          {/*Looks like you’re switching network. Do you want to transfer all funds from ‘Play Balance’ and ‘Session Keys’*/}
          {/*back to your wallet before leaving?*/}
        </div>

        <div className="flex items-start self-stretch text-gray-200">
          <label className="flex gap-x-2">
            <div className="self-center">
              <CheckBox
                size={CheckboxSize.sm}
                checked={hideBeforeLeavingModal}
                setChecked={setHideBeforeLeavingModal}
              />
            </div>

            <div className="flex font-normal text-sm items-center">
              <span>Don’t show this message again</span>
            </div>
          </label>
        </div>

        <div className="flex w-full flex-col gap-y-3">
          <Button
            variant="primary"
            className="w-full disabled:border-0 disabled:bg-gray-600"
            size="sm"
            isLoading={actionLoading}
            onClick={handleWithdrawAll}
          >
            Withdraw All
          </Button>
          <Button
            variant="primary"
            className="w-full bg-gray-600"
            size="sm"
            disabled={actionLoading}
            onClick={disconnect}
          >
            Leave them
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
