import { PropsWithChildren } from "react";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";

interface ISideBar extends PropsWithChildren {
  sidebarOpen: boolean;
}

export function SideBarMobile({ sidebarOpen, children }: ISideBar) {
  return (
    <div className={`side-bar-mobile relative md:hidden ${sidebarOpen ? "z-30" : ""}`}>
      <div
        className={`absolute inset-0 flex h-screen-minus-both-bars flex-col gap-y-5 bg-gray-900 px-3 pb-3 pt-5 transition-[clip-path] md:h-screen-minus-bar ${SCROLLBAR_CLASSES} ${sidebarOpen
          ? `clip-unfold overflow-y-auto ${SCROLLBAR_CLASSES}`
          : "clip-fold overflow-y-hidden"
          }`}
      >
        {children}
      </div>
    </div>
  );
}
