import { NetworkType, defaultNetwork } from "../../utils/chain/network";

export const countPopulation = 3333;
export const countAttributes = 5;

const countTraits_DEVNET = 96;
const countTraits_MAINNET = 95;

export const countTraits = defaultNetwork == NetworkType.MAINNET ? countTraits_MAINNET: countTraits_DEVNET

export const ATTRIBUTES = {
    'Background': 0,
    'Eyes & Accessories': 1,
    'Headwear': 2,
    'Clothes': 3,
    'Mouth': 4
}
export const ATTRIBUTE_NAMES = {
    0: 'Background',
    1: 'Eyes & Accessories',
    2: 'Headwear',
    3: 'Clothes',
    4: 'Mouth'
}

const ATTRIBUTE_TRAIT_COUNTS_DEVNET = {
    0: 9,
    1: 27,
    2: 19,
    3: 23,
    4: 18,
};

const ATTRIBUTE_TRAIT_COUNTS_MAINNET = {
    0: 9,
    1: 26,
    2: 19,
    3: 23,
    4: 18,
};

export const ATTRIBUTE_TRAIT_COUNTS = defaultNetwork == NetworkType.MAINNET ? ATTRIBUTE_TRAIT_COUNTS_MAINNET: ATTRIBUTE_TRAIT_COUNTS_DEVNET

export const TRAITS_BACKGROUND = {
    'Black' : 0,
    'Blue': 1,
    'Dark': 2,
    'Gold': 3,
    'Green' : 4,
    'Pink': 5,
    'Purple': 6,
    'White': 7,
    'Yellow': 8,
}
export const TRAITS_BACKGROUND_NAMES = {
    0: 'Black',
    1: 'Blue',
    2: 'Dark',
    3: 'Gold',
    4: 'Green' ,
    5: 'Pink',
    6: 'Purple',
    7: 'White',
    8: 'Yellow',
}

//

export const TRAITS_EYES_AND_ACCESSORIES_MAINNET = {
    '3D Glasses': 0,
    'Angry Eyes': 1,
    'Bandit Mask': 2,
    'Bitcoin Eyes': 3,
    'Black Eyes': 4,
    'Bloodshot Eyes': 5,
    'Blue Eyes': 6,
    'Brown Eyes': 7,
    'Closed Eyes': 8,
    'Eyepatch': 9,
    'Glow Eyes Gold': 10,
    'Glow Eyes Pink': 11,
    'Green Eyes': 12,
    'Hypnotic Glasses': 13,
    'Money Eyes': 14,
    'Monocle': 15,
    'Pixel Shades': 16,
    'Shady Look': 17,
    'Shocked Eyes': 18,
    'Shutter Shades Green': 19,
    'Shutter Shades Pink': 20,
    'Shutter Shades White': 21,
    'Shutter Shades Yellow': 22,
    'Solana Eyes'  : 23,
    'Solana Shades': 24,
    'Winking Eyes': 25,
}
export const TRAITS_EYES_AND_ACCESSORIES_NAMES_MAINNET = {
    0: '3D Glasses',
    1: 'Angry Eyes',
    2: 'Bandit Mask',
    3: 'Bitcoin Eyes',
    4: 'Black Eyes',
    5: 'Bloodshot Eyes',
    6: 'Blue Eyes',
    7: 'Brown Eyes',
    8: 'Closed Eyes',
    9: 'Eyepatch',
   10: 'Glow Eyes Gold',
   11: 'Glow Eyes Pink',
   12: 'Green Eyes',
   13: 'Hypnotic Glasses',
   14: 'Money Eyes',
   15: 'Monocle',
   16: 'Pixel Shades',
   17: 'Shady Look',
   18: 'Shocked Eyes',
   29: 'Shutter Shades Green',
   20: 'Shutter Shades Pink',
   21: 'Shutter Shades White',
   22: 'Shutter Shades Yellow',
   23: 'Solana Eyes'  ,
   24: 'Solana Shades',
   25: 'Winking Eyes',
}

//

const TRAITS_EYES_AND_ACCESSORIES_DEVNET = {
    '3D Glasses': 0,
    'Angry Eyes': 1,
    'Bandit Mask': 2,
    'Bitcoin Eyes': 3,
    'Black Eyes': 4,
    'Bloodshot Eyes': 5,
    'Blue Eyes': 6,
    'Brown Eyes': 7,
    'Closed Eyes': 8,
    'Eyepatch': 9,
    'Glow Eyes Gold': 10,
    'Glow Eyes Pink': 11,
    'Glow Eyes Yellow': 12,
    'Green Eyes': 13,
    'Hypnotic Glasses': 14,
    'Money Eyes': 15,
    'Monocle': 16,
    'Pixel Shades': 17,
    'Shady Look': 18,
    'Shocked Eyes': 19,
    'Shutter Shades Green': 20,
    'Shutter Shades Pink': 21,
    'Shutter Shades White': 22,
    'Shutter Shades Yellow': 23,
    'Solana Eyes'  : 24,
    'Solana Shades': 25,
    'Winking Eyes': 26,
}

export const TRAITS_EYES_AND_ACCESSORIES = defaultNetwork == NetworkType.MAINNET ? TRAITS_EYES_AND_ACCESSORIES_MAINNET: TRAITS_EYES_AND_ACCESSORIES_DEVNET

const TRAITS_EYES_AND_ACCESSORIES_NAMES_DEVNET = {
     0: '3D Glasses',
     1: 'Angry Eyes',
     2: 'Bandit Mask',
     3: 'Bitcoin Eyes',
     4: 'Black Eyes',
     5: 'Bloodshot Eyes',
     6: 'Blue Eyes',
     7: 'Brown Eyes',
     8: 'Closed Eyes',
     9: 'Eyepatch',
    10: 'Glow Eyes Gold',
    11: 'Glow Eyes Pink',
    12: 'Glow Eyes Yellow',
    13: 'Green Eyes',
    14: 'Hypnotic Glasses',
    15: 'Money Eyes',
    16: 'Monocle',
    17: 'Pixel Shades',
    18: 'Shady Look',
    19: 'Shocked Eyes',
    20: 'Shutter Shades Green',
    21: 'Shutter Shades Pink',
    22: 'Shutter Shades White',
    23: 'Shutter Shades Yellow',
    24: 'Solana Eyes'  ,
    25: 'Solana Shades',
    26: 'Winking Eyes',
}

export const TRAITS_EYES_AND_ACCESSORIES_NAMES = defaultNetwork == NetworkType.MAINNET ? TRAITS_EYES_AND_ACCESSORIES_NAMES_MAINNET: TRAITS_EYES_AND_ACCESSORIES_NAMES_DEVNET

export const TRAITS_HEADWEAR = {
    'Beer Hat':0 ,
    'Captains Hat': 1,
    'Crocodile Hunter Hat': 2,
    'Crown': 3,
    'Fisherman Hat': 4,
    'Gold Laurels': 5,
    'Hair': 6,
    'Headband Red': 7,
    'Headband White': 8,
    'Napoleon Hat': 9,
    'Party Hat': 10,
    'Pirate Hat': 11,
    'Roman Helmet': 12,
    'Tophat Black': 13,
    'Tophat Gold': 14,
    'Tophat Purple': 15,
    'Tophat Red': 16,
    'Tophat White': 17,
    'Umbrella Hat': 18,
}
export const TRAITS_HEADWEAR_NAMES = {
    0: 'Beer Hat',
    1: 'Captains Hat',
    2: 'Crocodile Hunter Hat',
    3: 'Crown',
    4: 'Fisherman Hat',
    5: 'Gold Laurels',
    6: 'Hair',
    7: 'Headband Red',
    8: 'Headband White',
    9: 'Napoleon Hat',
    10: 'Party Hat',
    11: 'Pirate Hat',
    12: 'Roman Helmet',
    13: 'Tophat Black',
    14: 'Tophat Gold',
    15: 'Tophat Purple',
    16: 'Tophat Red',
    17: 'Tophat White',
    18: 'Umbrella Hat',
}
export const TRAITS_CLOTHES = {
    'Adventure Waistcoat': 0,
    'Baseball Shirt': 1,
    'Bomber Jacket': 2,
    'Country Club': 3,
    'Dungarees': 4,
    'Gold Chain Shirt': 5,
    'Hero Costume': 6,
    'Nineties Pop Shirt': 7,
    'Pimp Coat White': 8,
    'Pyjamas Blue': 9,
    'Suspenders Black': 10,
    'Suspenders Gold': 11,
    'Suspenders Purple': 12,
    'Suspenders Red': 13,
    'Tropical Shirt Blue': 14,
    'Tropical Shirt Purple': 15,
    'Tropical Shirt Red': 16,
    'Turtle Neck': 17,
    'Tuxedo Black': 18,
    'Tuxedo Gold': 19,
    'Tuxedo Purple': 20,
    'Tuxedo Red': 21,
    'Tuxedo White': 22,
}
export const TRAITS_CLOTHES_NAMES = {
     0: 'Adventure Waistcoat',
     1: 'Baseball Shirt',
     2: 'Bomber Jacket',
     3: 'Country Club',
     4: 'Dungarees',
     5: 'Gold Chain Shirt',
     6: 'Hero Costume',
     7: 'Nineties Pop Shirt',
     8: 'Pimp Coat White',
     9: 'Pyjamas Blue',
    10: 'Suspenders Black',
    11: 'Suspenders Gold',
    12: 'Suspenders Purple',
    13: 'Suspenders Red',
    14: 'Tropical Shirt Blue',
    15: 'Tropical Shirt Purple',
    16: 'Tropical Shirt Red',
    17: 'Turtle Neck',
    18: 'Tuxedo Black',
    19: 'Tuxedo Gold',
    20: 'Tuxedo Purple',
    21: 'Tuxedo Red',
    22: 'Tuxedo White',
}
export const TRAITS_MOUTH = {
    'Bubblegum': 0,
    'Bull Ring': 1,
    'Casino Chip': 2,
    'Cheesey Grin': 3,
    'Cigar': 4,
    'Dice': 5,
    'Golden Carrot': 6,
    'Grass': 7,
    'Moustache Gringo': 8,
    'Moustache Monopoly': 9,
    'Moustache Poirot': 10,
    'Party Popper': 11,
    'Pipe': 12,
    'Smirk': 13,
    'Teeth Roll': 14,
    'Tongue Out': 15,
    'Toothpick': 16,
    'Whistling': 17,
}
export const TRAITS_MOUTH_NAMES = {
    0: 'Bubblegum',
    1: 'Bull Ring',
    2: 'Casino Chip',
    3: 'Cheesey Grin',
    4: 'Cigar',
    5: 'Dice',
    6: 'Golden Carrot',
    7: 'Grass',
    8: 'Moustache Gringo',
    9: 'Moustache Monopoly',
    10: 'Moustache Poirot',
    11: 'Party Popper',
    12: 'Pipe',
    13: 'Smirk',
    14: 'Teeth Roll',
    15: 'Tongue Out',
    16: 'Toothpick',
    17: 'Whistling',
}

// ENUM OF ALL ATTRIBUTES AVAILABLE
export enum ZEEBRO_ATTRIBUTE {
    BACKGROUND=0,
    EYES_AND_ACCESSORIES=1,
    HEADWEAR=2,
    CLOTHES=3,
    MOUTH=4
}

export const ZEEBRO_TRAITS_BY_ATTRIBUTE = {
    0: TRAITS_BACKGROUND_NAMES,
    1: TRAITS_EYES_AND_ACCESSORIES_NAMES,
    2: TRAITS_HEADWEAR_NAMES,
    3: TRAITS_CLOTHES_NAMES,
    4: TRAITS_MOUTH_NAMES
}