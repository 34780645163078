import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Program } from "@coral-xyz/anchor";
import { Metadata } from "@metaplex-foundation/js";
import { SwitchboardProgram } from "@switchboard-xyz/solana.js";
import { PublicKey } from "@solana/web3.js";

import { ProgramContext } from "./ProgramContext";
import NftStaking from "../sdk/nftStaking/nftStaking";

import { ZEEBROS_NFT_COLLECTION_PUBKEY } from "../sdk/nftStaking/constants";
import Zeebro from "../sdk/nftStaking/zeebro";
import { ZEEBRO_ATTRIBUTE, ZEEBRO_TRAITS_BY_ATTRIBUTE } from "../sdk/nftStaking/zeebrosCollection";
import { getMetaplexZeebroJsonByMint } from "../utils/config/utils";
import { ZeebrosPageTabs } from "../pages/ZeebrosPage";
import { WrappedWalletContext } from "./WrappedWalletContext";

export interface IZeebrosMeta {
  allZeebros: Zeebro[] | undefined
  zeebroByMint: Map<string, Zeebro> | undefined
  zeebroByNumber: Map<number, Zeebro> | undefined
  zeebrosByAttributeByTrait: Map<number, Map<number, Zeebro[]>> | undefined
}

export interface IZeebroOwnerMeta {
  avatarApplied?: Zeebro
  rakebackboostApplied?: Zeebro
}

export interface IZeebrosOwner {
  zeebrosForOwner: Zeebro[]
  ownerZeebroByMint: Map<string, Zeebro> | undefined
  ownedZeebroMeta: IZeebroOwnerMeta
}

export interface INftStakingContext {
  nftStaking: NftStaking | undefined
  loading: boolean
  zeebrosMeta: IZeebrosMeta | undefined
  zeebrosOwnerMeta: IZeebrosOwner
  updateNftStaking: () => Promise<void>
  currentTab: ZeebrosPageTabs
  setCurrentTab: React.Dispatch<React.SetStateAction<ZeebrosPageTabs>>
}

export const NftStakingContext = createContext<INftStakingContext>({} as INftStakingContext);

interface Props {
  children: any;
}

export const NftStakingProvider = ({ children }: Props) => {
  // CONTEXTS NEEDED
  const { meta } = useContext(ProgramContext)
  const nftProgram = useMemo(() => {
    return meta?.nftStakingProgram
  }, [meta?.nftStakingProgram]);
  const switchboardProgram = useMemo(() => {
    return meta?.switchboardProgram
  }, [meta?.switchboardProgram]);

  // REFS FOR REFRESH platform, nftProgram, switchboardProgram, loadZeebrosMeta
  const refreshRef = useRef<{
    loading: boolean
    updateNftStaking: () => Promise<void>
  }>()

  // STATE
  const [nftStaking, setNftStaking] = useState<NftStaking>()
  const [counter, setCounter] = useState(0)
  const [loading, setLoading] = useState(false)
  const [zeebrosMeta, setZeebrosMeta] = useState<IZeebrosMeta>()
  const [zeebrosOwnerMeta, setZeebrosOwnerMeta] = useState<IZeebrosOwner>()

  // FOR MY ZEEBROS PAGE, CONTROL TAB
  const [currentTab, setCurrentTab] = useState<ZeebrosPageTabs>(ZeebrosPageTabs.General);

  // CACHED METAPLEX DATA...
  const metaplexMetaForCollection = useRef<any>()

  const getOwnedZeebroMeta = useCallback((zeebsForOwner: Zeebro[]) => {
    let rakebackApplied: Zeebro | undefined
    let avatarApplied: Zeebro | undefined

    zeebsForOwner?.forEach((zeebro) => {
      if (zeebro.rakebackBoostApplied == true) {
        rakebackApplied = zeebro
      }

      if (zeebro.avatarApplied == true) {
        avatarApplied = zeebro
      }
    })

    return {
      rakebackboostApplied: rakebackApplied,
      avatarApplied: avatarApplied
    }
  }, [])
  const getOwnerZeebroByMint = useCallback((zeebsForOwner: Zeebro[]) => {
    if (zeebsForOwner == null) {
      return
    }

    return zeebsForOwner.reduce((result, item) => {
      result.set(item.mintPublicKey.toString(), item)

      return result
    }, new Map<string, Zeebro>())
  }, [])

  const loadAllZeebros = useCallback(async (nftStaking: NftStaking) => {
    // LOAD ALL NFT META DATA - USE CACHED IF AVAILABLE
    let allZeebroMeta

    if (metaplexMetaForCollection.current != null) {
      allZeebroMeta = metaplexMetaForCollection.current
    } else {
      // LOAD AND CACHE
      allZeebroMeta = await Zeebro.getAllMetadataForCollection(nftStaking)
      metaplexMetaForCollection.current = allZeebroMeta
    }

    const zeebroByMint = allZeebroMeta.reduce((result, item) => {
      if (item != null) {
        result.set(item?.mintAddress.toString(), item)
      }

      return result
    }, new Map<string, Metadata>());

    // COMBINE WITH COLLECTION TABLE DATA
    const allZeebros: Zeebro[] = []
    const metaplexMetaByMint = getMetaplexZeebroJsonByMint();

    nftStaking.collectionTable.rows.forEach((row, index) => {
      const meta = zeebroByMint.get(row.nftMint.toString());
      const stakeReceipt = undefined // TODO - GET STAKE RECEIPTS

      let zeeb = Zeebro.loadFromPrefetched(nftStaking, row.nftMint, meta, stakeReceipt, index)
      const json = metaplexMetaByMint[zeeb.mintPublicKey.toString()];

      if (json != null) {
        zeeb = zeeb.setMetaplexJson(json)
      }

      allZeebros.push(zeeb)
    })

    // RETURN THE ZEEBROS
    return allZeebros
  }, [])

  const loadZeebrosMeta = useCallback(async (nftStaking: NftStaking): Promise<IZeebrosMeta> => {
    const metaplexJsonByMint = getMetaplexZeebroJsonByMint()
    const allZeebs = await loadAllZeebros(nftStaking)

    // USE THE CACHE TO SET THE METAS WHERE POSSIBLE
    const allZeebros = allZeebs.map((zeeb) => {
      const json = metaplexJsonByMint[zeeb.mintPublicKey.toString()]

      if (json != null) {
        return zeeb.setMetaplexJson(json)
      }

      return zeeb
    })

    const zeebroByMint = new Map<string, Zeebro>()
    const zeebroByNumber = new Map<number, Zeebro>()

    const zeebrosByAttributeByTrait = getEmptyZeebrosByAttributeByTrait()

    // KEY BY RELEVANT VALUES FOR FILTERING
    allZeebros.forEach((zeebro: Zeebro) => {
      zeebroByMint.set(zeebro.mintPublicKey.toString(), zeebro)
      zeebroByNumber.set(zeebro.id, zeebro)

      // UPDATE MAP WITH EACH ATTRIBUTE TRAIT
      zeebrosByAttributeByTrait.get(ZEEBRO_ATTRIBUTE.BACKGROUND)?.get(zeebro.backgroundTrait.id)?.push(zeebro)
      zeebrosByAttributeByTrait.get(ZEEBRO_ATTRIBUTE.CLOTHES)?.get(zeebro.clothesTrait.id)?.push(zeebro)
      zeebrosByAttributeByTrait.get(ZEEBRO_ATTRIBUTE.EYES_AND_ACCESSORIES)?.get(zeebro.eyesAndAccessoriesTrait.id)?.push(zeebro)
      zeebrosByAttributeByTrait.get(ZEEBRO_ATTRIBUTE.HEADWEAR)?.get(zeebro.headwearTrait.id)?.push(zeebro)
      zeebrosByAttributeByTrait.get(ZEEBRO_ATTRIBUTE.MOUTH)?.get(zeebro.mouthTrait.id)?.push(zeebro)
    })
    const meta = {
      allZeebros: allZeebros,
      zeebroByMint: zeebroByMint,
      zeebroByNumber: zeebroByNumber,
      zeebrosByAttributeByTrait: zeebrosByAttributeByTrait
    }

    setZeebrosMeta(meta)

    return meta
  }, [loadAllZeebros])

  // LOAD ZEEBROS BY OWNER
  const { walletPubkey } = useContext(WrappedWalletContext)

  const loadNftsByOwner = useCallback(async (nftStaking: NftStaking) => {
    if (nftStaking == null || walletPubkey == null) {
      return
    }

    const metaplexJsonByMint = getMetaplexZeebroJsonByMint()
    let ownersZeebs = await Zeebro.loadAllForOwner(nftStaking, walletPubkey)
    ownersZeebs = ownersZeebs.map((zeeb) => zeeb.setMetaplexJson(metaplexJsonByMint[zeeb.mintPublicKey.toString()]))

    const byMint = getOwnerZeebroByMint(ownersZeebs)
    const ownerMetas = getOwnedZeebroMeta(ownersZeebs)

    setZeebrosOwnerMeta({
      zeebrosForOwner: ownersZeebs,
      ownerZeebroByMint: byMint,
      ownedZeebroMeta: ownerMetas
    })
  }, [walletPubkey, counter])

  useEffect(() => {
    async function loadNftMeta(nftProgram: Program, switchboardProgram: SwitchboardProgram, mainPubkey: PublicKey) {
      try {
        setLoading(true)

        // LOAD NFT STAKING CLASS
        console.log({ nftProgram, switchboardProgram, mainPubkey });

        const nftStak = await NftStaking.load(nftProgram, switchboardProgram, mainPubkey)
        console.log({ nftStak });

        // LOAD ALL ZEEBROS
        await loadNftsByOwner(nftStak)
        const meta = await loadZeebrosMeta(nftStak)
        console.log({ meta });

        nftStak.setAverageZeebroExpectancy(meta.allZeebros || [])

        // SET THE AVERAGE EXPECTANCIES
        setNftStaking(nftStak)
      } catch (err) {
        console.error(`Issue loading NFT meta`, err)
      } finally {
        setLoading(false)
      }
    }

    if (nftProgram == null || switchboardProgram == null) {
      return
    }

    const mainPubkey = NftStaking.deriveMainPubkey(ZEEBROS_NFT_COLLECTION_PUBKEY, nftProgram.programId)

    loadNftMeta(nftProgram, switchboardProgram, mainPubkey)
  }, [nftProgram, switchboardProgram, loadZeebrosMeta, loadNftsByOwner])

  const updateNftStaking = useCallback(async () => {
    if (nftStaking == null || meta?.switchboardProgram == null) {
      return
    }

    try {

      const updated = await NftStaking.load(nftStaking.program, meta.switchboardProgram, nftStaking.mainPubkey, "processed")


      // LOAD ALL ZEEBROS + LOAD NFTS FOR OWNER
      await loadNftsByOwner(updated)
      const zeebrosMeta = await loadZeebrosMeta(updated)

      updated.setAverageZeebroExpectancy(zeebrosMeta.allZeebros || [])
      setNftStaking(updated)

      setCounter(counter + 1)
    } catch (err) {
      console.error("Error loading the NFT staking instance")
      console.error({ err })
    }
  }, [nftStaking, counter, meta?.switchboardProgram, loadZeebrosMeta, loadNftsByOwner])

  useEffect(() => {
    refreshRef.current = {
      loading: refreshRef.current?.loading || false,
      updateNftStaking: updateNftStaking
    }
  }, [updateNftStaking])

  // REFRESH THE NFT DATA
  useEffect(() => {
    const interval = setInterval(async () => {
      if (refreshRef.current?.loading == true) {
        console.warn(`In the middle of loading NFT Meta, waiting to next cycle`)
        return
      }

      if (refreshRef.current?.updateNftStaking != null) {
        try {
          refreshRef.current.loading = true
          await refreshRef.current?.updateNftStaking()
        } catch (err) {
          console.error(`Issue Updating NFT Meta`, err)
        } finally {
          refreshRef.current.loading = false
        }
      }
    }, 120000)

    return () => {
      try {
        if (interval != null) {
          clearInterval(interval)
        }
      } catch (err) { }
    }
  }, [])

  return (
    <NftStakingContext.Provider
      value={useMemo(
        () => ({
          nftStaking: nftStaking,
          loading: loading,
          zeebrosMeta: zeebrosMeta,
          updateNftStaking: updateNftStaking,
          zeebrosOwnerMeta: zeebrosOwnerMeta,
          currentTab: currentTab,
          setCurrentTab: setCurrentTab
        }),
        [nftStaking, loading, zeebrosMeta, updateNftStaking, counter, zeebrosOwnerMeta, currentTab],
      )}
    >
      {children}
    </NftStakingContext.Provider>
  );
};

const getEmptyZeebrosByAttributeByTrait = (): Map<number, Map<number, Zeebro[]>> => {
  // TODO SET MAPS HERE FOR ATTRIBUTES/TRAITS
  const zeebrosByAttributeByTrait = new Map<number, Map<number, Zeebro[]>>()

  // TODO - REFACTOR TO REMOVE REDUNDANT CODE...

  // INIT MAPS FOR EACH ATTRIBUTE
  zeebrosByAttributeByTrait.set(ZEEBRO_ATTRIBUTE.BACKGROUND, Object.keys(ZEEBRO_TRAITS_BY_ATTRIBUTE[ZEEBRO_ATTRIBUTE.BACKGROUND]).reduce((result, value) => {
    result.set(Number(value), [])
    return result
  }, new Map()))
  zeebrosByAttributeByTrait.set(ZEEBRO_ATTRIBUTE.CLOTHES, Object.keys(ZEEBRO_TRAITS_BY_ATTRIBUTE[ZEEBRO_ATTRIBUTE.CLOTHES]).reduce((result, value) => {
    result.set(Number(value), [])
    return result
  }, new Map()))
  zeebrosByAttributeByTrait.set(ZEEBRO_ATTRIBUTE.EYES_AND_ACCESSORIES, Object.keys(ZEEBRO_TRAITS_BY_ATTRIBUTE[ZEEBRO_ATTRIBUTE.EYES_AND_ACCESSORIES]).reduce((result, value) => {
    result.set(Number(value), [])
    return result
  }, new Map()))
  zeebrosByAttributeByTrait.set(ZEEBRO_ATTRIBUTE.HEADWEAR, Object.keys(ZEEBRO_TRAITS_BY_ATTRIBUTE[ZEEBRO_ATTRIBUTE.HEADWEAR]).reduce((result, value) => {
    result.set(Number(value), [])
    return result
  }, new Map()))
  zeebrosByAttributeByTrait.set(ZEEBRO_ATTRIBUTE.MOUTH, Object.keys(ZEEBRO_TRAITS_BY_ATTRIBUTE[ZEEBRO_ATTRIBUTE.MOUTH]).reduce((result, value) => {
    result.set(Number(value), [])
    return result
  }, new Map()))

  return zeebrosByAttributeByTrait
}
