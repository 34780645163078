import { IdlErrorCode } from "@project-serum/anchor/dist/cjs/idl";
import { ComputeBudgetProgram, Connection, Finality, Keypair, PublicKey, RpcResponseAndContext, SendOptions, SimulatedTransactionResponse, Transaction, TransactionError, TransactionInstruction, TransactionMessage, TransactionSignature, VersionedTransaction } from "@solana/web3.js";
import * as bs58 from 'bs58'
import { IS_MAINNET } from "../utils/solana/rpc";
import { PriorityFeeLevel } from "../contexts/NetworkContext";

export function computeBudgetIxns(
    units: number = 400_000,
    microLamportsPerUnit: number = 1,
): TransactionInstruction[] {
    return [
        ComputeBudgetProgram.setComputeUnitLimit({ units: units }),
        ComputeBudgetProgram.setComputeUnitPrice({ microLamports: microLamportsPerUnit })
    ]
}

const HELIUS_ENDPOINT = `https://mainnet.helius-rpc.com/?api-key=f6d9ddce-6566-49e8-a3a1-f0694d483043`
//const HELIUS_ENDPOINT = `https://mainnet.helius-rpc.com/?api-key=f618df15-b507-49a0-80e1-cbb8e4a8b456` // JUST USED FOR LOCAL TESTING
const PRIORITY_FEES_BUFFER = 1.0; // AS ON HIGH

const getPriorityFees = async (versionedTransaction: VersionedTransaction) => {
    const response = await fetch(HELIUS_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            jsonrpc: '2.0',
            id: 'zeebit-fee-estimate',
            method: 'getPriorityFeeEstimate',
            params: [
                {
                    transaction: bs58.encode(versionedTransaction.serialize()),
                    options: {
                        // recommended: true,
                        // priorityLevel: "High"
                        "includeAllPriorityFeeLevels": true
                    },
                }
            ],
        }),
    });

    const respJson = await response.json()

    // CHECK WHAT LEVEL TO USE FROM LOCAL STORAGE
    let feeLevel = window.localStorage.getItem("zeebit-priority-fee-level") || PriorityFeeLevel.MID_LEVEL


    const medium = respJson.result.priorityFeeLevels.medium
    const high = respJson.result.priorityFeeLevels.high
    const low = respJson.result.priorityFeeLevels.low

    switch (feeLevel) {
        case PriorityFeeLevel.HIGH:
            return high;
        case PriorityFeeLevel.MEDIUM:
            return medium;
        case PriorityFeeLevel.MID_LEVEL:
            return Math.ceil(high - ((high - medium) / 2))
        case PriorityFeeLevel.LOW:
            return low;
        default:
            return Math.ceil(high - ((high - medium) / 2))
    }
}

const simulateTransaction = async (versionedTransaction: VersionedTransaction, client: Connection, errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>): Promise<SimulatedTransactionResponse> => {

    const simulated = await client.simulateTransaction(versionedTransaction, {
        replaceRecentBlockhash: true,
        sigVerify: false,
    })

    if (simulated.value.err != null) {
        // IF THERE IS AN ERROR WE WANT TO PARSE IT
        console.warn({
            simulated: simulated.value
        })
        const errors = handleSimulatedTransactionV2(simulated, errorByCodeByProgram)
        console.log({
            errors
        })

        if (errors != null) {
            throw new Error(`The transaction failed simulation. ${JSON.stringify(errors.map((err) => {
                return err.msg || 'Unknown error'
            }))}`)
        }
        throw new Error(`The transaction failed simulation. ${JSON.stringify(simulated.value.err)}`)
    } else {
        console.log({
            simulated
        })
    }
    return simulated.value;
}

// METHOD TO SEND BASE TXNS
export const sendTransaction = async (
    instructions: TransactionInstruction[],
    client: Connection,
    feePayer: PublicKey,
    isBaseTransaction: boolean,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
    blockhashMeta?: {
        blockhash: string;
        lastValidBlockHeight: number;
    },
    signTransaction?: (tx: VersionedTransaction | Transaction) => Promise<VersionedTransaction | Transaction>, // IF NO SIGNER IS PASSED WE USE THIS METHOD TO SIGN THE TXN
    signer?: Keypair, // IF PASSED USE THIS
    sendOptions: SendOptions = {
        skipPreflight: true,
        maxRetries: 0
    },
    confirmFinality?: Finality,
    onSuccessfulSend?: (sig: string) => void,
    skipPriorityFees: boolean = false
) => {

    console.log(`FEE PAYER -> `, { feePayer: feePayer.toString() })

    // CREATE A VERSIONED TX
    const blockMeta = blockhashMeta?.blockhash != null ? blockhashMeta : (await client.getLatestBlockhash("confirmed"))
    const addPriorityFees = skipPriorityFees == false && IS_MAINNET == true && isBaseTransaction == true

    let messageV0 = new TransactionMessage({
        payerKey: feePayer,
        recentBlockhash: blockMeta.blockhash,
        instructions: [
            ...(addPriorityFees ? computeBudgetIxns(1_000_000, 100_000) : []),
            ...instructions
        ], // ADD PRIORITY FEES TO BASE TXNS
    }).compileToV0Message();


    let transaction = new VersionedTransaction(messageV0);

    // SIMULATE THE TXN
    const simulated = await simulateTransaction(transaction, client, errorByCodeByProgram)
    const computeUnits = Math.ceil(simulated.unitsConsumed * 1.1)

    // ONLY ADD PRIORITY FEES ON MAINNET
    // TODO - ADD SWITCH ON SONIC HERE
    const priorityFees = addPriorityFees ? Math.ceil((await getPriorityFees(transaction)) * PRIORITY_FEES_BUFFER) : undefined

    messageV0 = new TransactionMessage({
        payerKey: feePayer,
        recentBlockhash: blockMeta.blockhash,
        instructions: [
            ...computeBudgetIxns(computeUnits, priorityFees),
            ...instructions
        ], // ADD PRIORITY FEES TO BASE TXNS
    }).compileToV0Message();

    transaction = new VersionedTransaction(messageV0);
    
    if (signer != null) {
        transaction.sign([signer]);
    }

    if (signTransaction != null) {
        transaction = await signTransaction(transaction)
    }

    let signature

    // FOR MAINNET, WE WANT TO TRY MANY RPCS
    if (IS_MAINNET == true && isBaseTransaction == true) {
        // TRY SENDING TO MULTIPLE RPCS
        const clients = [
            client,
            new Connection('https://fragrant-patient-film.solana-mainnet.quiknode.pro/7f8315e41796433f2bdfcd96d5a628317b0ed94b/', "processed"),
            new Connection('https://api.mainnet-beta.solana.com', "processed"), // mainnet rpc
        ]

        signature = await Promise.any(clients.map((connection, index) => {
            return sendTransactionPolled(transaction, connection, sendOptions, index == 0 ? onSuccessfulSend : undefined)
        }))
    } else {
        signature = await sendTransactionPolled(transaction, client, sendOptions, onSuccessfulSend)
    }

    if (signature == null) {
        throw new Error("The transaction didnt confirm during the timeout period.")
    }

    console.log(`Successfully sent the txn to ${client.rpcEndpoint}`, { signature })
    if (confirmFinality) {
        const confirmMeta = await client.confirmTransaction({
            signature: signature,
            ...blockMeta
        })

        if (confirmMeta.value.err != null) {
            throw new Error(
                JSON.stringify(
                    confirmMeta.value.err
                )
            )
        } else {
            console.log(`Successfully confirmed the txn`, { signature })
        }
    }

    return signature;
}

const sendTransactionPolled = async (
    versionedTransaction: VersionedTransaction,
    client: Connection,
    sendOptions: SendOptions = {
        skipPreflight: true,
        maxRetries: 0
    },
    onSuccessfulSend?: (sig: string) => void
) => {
    try {
        const timeout = 60_000;
        const startTime = Date.now();
        let txtSig;
        let tries = 0

        while ((Date.now() - startTime) < timeout) {
            try {
                try {
                    txtSig = await client.sendRawTransaction(versionedTransaction.serialize(), {
                        skipPreflight: sendOptions.skipPreflight,
                        ...sendOptions,
                    });

                    console.log(`
                    successfully sent the txn ${txtSig}
                `)

                    // ONLY WANT TO SEND TO HANDLER ONCE...
                    if (tries == 0) {
                        onSuccessfulSend?.(txtSig)
                    }
                } catch (err) {
                    // IF IT DIDNT EVEN SEND SUCCESSFULLY WE SHOULD REJECT
                    return Promise.reject(`Transaction not sent successfully with, ${client.rpcEndpoint}. Waiting for other to succeed/fail.`)
                } finally {
                    tries += 1
                }

                return await pollTransactionConfirmation(txtSig, client);
            } catch (error) {
                console.log(`Didnt land trying again`)
                continue;
            }
        }
    } catch (error) {
        throw new Error(`Error sending smart transaction: ${error}`);
    }
}

const pollTransactionConfirmation = async (txtSig: TransactionSignature, client: Connection): Promise<TransactionSignature> => {
    // 5 second timeout
    const timeout = 5_000;
    // 1 second retry interval
    const interval = 2_000;
    let elapsed = 0;

    return new Promise<TransactionSignature>((resolve, reject) => {
        const intervalId = setInterval(async () => {
            elapsed += interval;

            if (elapsed >= timeout) {
                clearInterval(intervalId);
                reject(new Error(`Transaction ${txtSig}'s confirmation timed out`));
            }

            try {
                console.log(`LOADING THE TXN`, { txtSig })
                const txnStatus = await client.getSignatureStatus(txtSig)

                if (txnStatus?.value?.confirmationStatus != null) {
                    clearInterval(intervalId);
                    resolve(txtSig);
                } else if (txnStatus?.value?.err != null) {
                    clearInterval(intervalId);
                    reject(new Error(`Transaction ${txtSig} did not succeed.`));
                } else {
                    console.log({
                        txnStatus
                    })
                }
            } catch (err) {
                console.warn(`Txn not seen yet...`, { err })
            }
        }, interval);
    });
}

// PARSING TRANSACTION ERRORS
export const updateErrorContext = (
    txError: TransactionError | null | any[],
    program: string,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
): IdlErrorCode[] => {
    const parsedErrors: IdlErrorCode[] = [];
    if (Array.isArray(txError)) {
        txError
            .forEach((error) => {
                // TODO - LOOK FOR STANDARD ERRORS HERE
                if (typeof error == "object" && "Custom" in error) {
                    const parsed = {};

                    const errorCode = error["Custom"];
                    const parsedError = errorByCodeByProgram?.get(program)?.get(errorCode);
                    parsed.msg = parsedError?.msg;
                    parsed.name = parsedError?.name;
                    parsed.code = parsedError?.code;

                    parsedErrors.push(parsed);
                } else if (typeof error == "number") {
                    const parsed = {};

                    if (error == 2) {
                        parsed.msg = "Insufficient funds for rent.";
                        parsed.name = "Solana Base Error";
                        parsed.code = error;
                    } else if (error == 3) {
                        parsed.msg = "Insufficient funds for transfer.";
                        parsed.name = "Solana Base Error";
                        parsed.code = error;
                    } else {
                        parsed.msg = "Solana Base Error";
                        parsed.name = "Solana Base Error";
                        parsed.code = error;
                    }

                    parsedErrors.push(parsed);
                }
            });
    } else if (txError != null) {
        if (typeof txError == "object" && "Custom" in txError) {
            const parsed = {};

            const errorCode: number = txError["Custom"];
            const parsedError = errorByCodeByProgram?.get(program)?.get(errorCode);
            parsed.msg = parsedError?.msg;
            parsed.name = parsedError?.name;
            parsed.code = parsedError?.code;

            return [parsed];
        } else if (typeof txError == "number") {
            const parsed = {};

            parsed.msg = "Solana base error.";
            parsed.name = "Solana base error.";
            parsed.code = txError;

            return [parsed];
        }
    }

    return parsedErrors;
};

export const handleSimulatedTransactionV2 = (
    response: RpcResponseAndContext<SimulatedTransactionResponse>,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
): IdlErrorCode[] | undefined => {
    const error = response.value.err; // ERROR CAN BE STRING, OBJECT, OBJECT[]

    // IF NO ERROR, SIMULATION PASSED
    if (error == null) {
        return
    }


    // if there are no logs, we use the error object
    if (response.value.logs == null) {
        // TRY AND MAKE READABLE STRINGS HERE
        return [
            {
                msg: JSON.stringify(response.value.err),
                code: 0,
                name: JSON.stringify(response.value.err)
            }
        ]
    }

    // TRY PICK OUT THE ERROR LINES
    const errorLines = response.value.logs.reduce((result, item) => {
        // PROGRAM ERROR
        if (item.includes("Program log: Error: ")) {
            result.push(item.replace("Program log: Error: ", ""))
            
            // ANCHOR ERROR
        } else if (item.includes("Program log: AnchorError ")) {
            result.push(item.replace("Program log: AnchorError ", ""))

            // insufficient lamports, insufficient funds for rent
        } else if (item.includes("insufficient")) {
            result.push(item)

            // program issue
        } else if (item.includes("Program log: panicked")) {
            result.push("Program failed to complete.")
        }

        return result
    }, new Array<string>())

    if (errorLines.length > 0) {
        return errorLines.map((errLine) => {
            return {
                msg: errLine,
                name: 'Program Error',
                code: 0,
            }
        })
    }

    return [
        {
            msg: JSON.stringify(response.value.err),
            code: 0,
            name: JSON.stringify(response.value.err)
        }
    ]
}

export const handleSimulatedTransaction = (
    response: RpcResponseAndContext<SimulatedTransactionResponse>,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
): IdlErrorCode[] | undefined => {
    // PROGRAM DEPTH
    const programContext: string[] = [];
    const error = response.value.err; // ERROR CAN BE STRING, OBJECT, OBJECT[]

    // IF NO ERROR, SIMULATION PASSED
    if (error == null) {
        return
    }

    // STRING EXAMPLE
    if (typeof error == "string") {
        if (error == "AccountNotFound") {
            return [
                {
                    name: "Account",
                    msg: "Account not found.",
                    code: 0,
                },
            ]
        } else {
            return [
                {
                    name: "String type errror",
                    msg: error,
                    code: 0,
                },
            ]
        }
    }

    const unsignedContext = error?.InstructionError;

    const logs = response.value.logs;
    logs?.forEach((log) => {
        const words = log.split(" ");
        if (words.includes("invoke")) {
            programContext.push(words[1]);
        } else if (words.includes("success")) {
            programContext.pop();
        }
    });

    const programPubkey =
        programContext != null && programContext.length > 0
            ? programContext[programContext.length - 1]
            : null;

    // SHOULD GIVE AN OBJECT WITH MESSAGE, CODE...
    const contextAfterUpdate =
        programPubkey != null
            ? updateErrorContext(unsignedContext, programPubkey, errorByCodeByProgram)
            : null;

    if (contextAfterUpdate != null) {
        return contextAfterUpdate
    }

    return [
        {
            name: "Unknown Error",
            code: 0,
            msg: JSON.stringify(error),
        },
    ]
};