import React, { FC, useContext, useState } from "react";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";

import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { Button } from "../common/button/Button";
import { WalletDropdown } from "./WalletDropdown";
import { WalletTab } from "../../modals/WalletModal";
import { useWalletModal } from "../../hooks/modals/useWalletModal";
import {
  AddFundsAndPlayModal,
  IFundOrWithdrawModalTabs,
} from "../modals/AddFundsAndPlayModal";
import { SelectTokenModal } from "../modals/SelectTokenModal";
import { NetworkContext, AggregatedBalancesContext, WrappedWalletContext, NavContext } from "../../contexts";
import { getS3StaticFolderUrl } from "../../utils/config/utils";
import ConnectionModal from "../modals/ConnectionModal";
import { UserDropdown } from "./UserDropdown";
import IconFont from "../common/iconFont/IconFont";
import { AirdropModal } from "../../modals/AirdropModal";
import { truncatePubkey } from "../../utils/string/string";
import Icon from "../common/icon/Icon";
import { networksData } from "../../constants/chains";
import { FundsModal } from "../modals/FundsModal";
import { defaultNetwork, NetworkType } from "../../utils/chain/network";

export const TopNav: FC<{}> = () => {
  const navigate = useNavigate();
  const { loggedIn, walletPubkey, disconnect } = useContext(WrappedWalletContext);
  const { chain } = useContext(NetworkContext);
  const { toggleLeftPanel } = useContext(NavContext);
  const { setWalletTab, showWalletModal } = useWalletModal();

  const [walletModalSelectedTab, setWalletModalSelectedTab] =
    useState<IFundOrWithdrawModalTabs>(IFundOrWithdrawModalTabs.DEPOSIT);

  const [showConnectionModal, hideConnectionModal] = useModal(
    ({ in: open }) => (
      <ConnectionModal
        open={open}
        closeModal={hideConnectionModal}
      />
    ),
    []
  );

  const { mergedTokens } = useContext(AggregatedBalancesContext);

  const [showFundsAndPlayModal, hideFundsAndPlayModal] = useModal(
    ({ in: open }) => (
      <AddFundsAndPlayModal
        showModal={showFundsAndPlayModal}
        visible={open}
        hideModal={() => {
          hideFundsAndPlayModal();
        }}
        walletModalSelectedTab={walletModalSelectedTab}
      />
    ),
    [mergedTokens, walletModalSelectedTab]
  );

  const [showFundTokenModal, hideFundTokenModal] = useModal(
    ({ in: open }) => (
      <SelectTokenModal
        visible={open}
        hideModal={hideFundTokenModal}
        showFundsAndPlayModal={(selectedTab: IFundOrWithdrawModalTabs) => {
          setWalletModalSelectedTab(selectedTab);
          showFundsAndPlayModal();
        }}
      />
    ),
    []
  );

  const [showFundsModal, hideFundsModal] = useModal(
    ({ in: open }) => (
      <FundsModal
        visible={open}
        hideModal={hideFundsModal}
        showFundsAndPlayModal={(selectedTab: IFundOrWithdrawModalTabs) => {
          setWalletModalSelectedTab(selectedTab);
          showFundsAndPlayModal();
        }}
      />
    ),
    []
  );

  const [showAirdropModal, hideWalletModal] = useModal(
    ({ in: open }) => (
      <AirdropModal visible={open} hideModal={hideWalletModal} />
    ),
    []
  );

  const { chainDisplayName } = useContext(NetworkContext);

  return (
    <ul className="flex justify-between h-top-bar-height bg-gray-800 px-4 py-0 items-center relative z-50 shadow-black-regular">
      {/* HAMBURGER AND LOGO */}
      <li className="text-white flex align-center">
        <div
          role="button"
          className="hidden sm:block h-6 w-6 my-auto"
          onClick={() => toggleLeftPanel()}
        >
          <HamburgerIcon />
        </div>
        {/* TODO - REPLACE WITH LOGO */}
        <div
          className="flex align-center cursor-pointer ml-0 md:ml-5"
          onClick={() => {
            navigate(``);
          }}
        >
          <div className="">
            <div className="hidden sm:block h-[30px]">
              <img src={getS3StaticFolderUrl("/static/zeebit-beta-full.svg")} />
            </div>

            <img
              src={getS3StaticFolderUrl("/static/zeebit-beta-mobile.svg")}
              className="flex sm:hidden w-[90px]"
            />
          </div>
        </div>
      </li>

      {/* USERS TOKENS AND WALLET DROPDOWN */}
      <li className="hidden sm:block">
        {loggedIn && (
          <div className="flex gap-x-2">
            <WalletDropdown
              openGasModal={() => {
                setWalletTab(WalletTab.GAS);
                showWalletModal();
              }}
              setWalletModalOpen={showFundsModal}
            />
            {defaultNetwork !== NetworkType.MAINNET ?
              <Button
                size={"md"}
                variant={!loggedIn ? "primary" : "gray"}
                className="h-10"
                children={
                  <div className="flex gap-x-2 items-center">
                    <IconFont name="download" size="xl" className="mb-0.5" />
                    Faucet
                  </div>
                }
                onClick={showAirdropModal}
              /> : null}
          </div>
        )}
      </li>
      {/* CONNECT, REGISTER, OR PROFILE */}
      <li className="flex items-center gap-2">
        {/* <RingPopover /> */}
        <Button
          size={"md"}
          variant={!loggedIn ? "primary" : "gray"}
          className="px-2"
          children={
            !loggedIn ? (
              "Connect"
            ) : (
              <div className="flex gap-x-2 items-center">
                <div className="flex flex-col">
                  <div className="flex font-normal text-xs text-gray-300 items-center">{chainDisplayName}</div>
                  <div className="flex text-sm">
                    <Icon
                      iconUrl={networksData[chain].icon}
                      className="w-4 h-4 mr-1 mb-0.5"
                    />
                    {truncatePubkey(walletPubkey?.toString() || "", 4)}
                  </div>
                </div>
                <IconFont name="globe" size="xl" className="mb-0.5 text-gray-300 text-2xl" />
              </div>
            )
          }
          onClick={showConnectionModal}
        />
        {loggedIn && (
          <div className="hidden sm:block">
            <UserDropdown />
          </div>
        )}
      </li>
    </ul>
  );
};
