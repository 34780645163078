import React, { useEffect, useState } from "react";
import Input, { InputProps } from "../../common/input/Input";
import InputButton from "../../common/input/InputButton";
import Icon from "../../common/icon/Icon";

type actionTypes = "half" | "max" | "2x" | "1/2" | "min";
interface BetAmountFieldProps {
    minWager: number;
    error?: string;
    wager: number | string;
    setWager: (value: React.SetStateAction<number | string>) => void;
    iconUrl?: string;
    disabled?: boolean;
    isPreview?: boolean;
    allowEmptyValue?: boolean;
    name?: string;
    className?: string;
    isLoading?: boolean;
    actions?: actionTypes[];
    maxWager?: number;
    classes?: InputProps["classes"]
}
export const TokenAmountField = ({
    minWager,
    error,
    wager,
    setWager,
    iconUrl,
    isPreview,
    disabled,
    name,
    className,
    allowEmptyValue,
    isLoading,
    actions = ["1/2", "2x"],
    maxWager,
    classes,
    ...props
}: BetAmountFieldProps) => {
    const [stringWager, setStringWager] = useState(String(wager));

    // CHECK TO ENSURE WAGER AND STRING VERSION STAY IN SYNC (ONLY PREVIEW FOR NOW)
    useEffect(() => {
        if (isPreview && wager !== 0 && !Number.isNaN(wager)) {
            const strWager = String(wager);
            if (strWager != stringWager) {
                setStringWager(strWager);
            }
        }
    }, [wager, stringWager]);

    const renderAction = (action: actionTypes) => {
        switch (action) {
            case "2x":
                return (
                    <InputButton
                        onClick={() => {
                            setWager((prev) => {
                                const newVal = Math.floor(Number(prev) * 10000 * 2) / 10000;
                                setStringWager(String(newVal));

                                return newVal;
                            });
                        }}
                    >
                        2x
                    </InputButton>
                )
            case "1/2":
                return (
                    <InputButton
                        onClick={() => {
                            setWager((prev) => {
                                const newVal = Math.floor((Number(prev) * 10000) / 2) / 10000;
                                setStringWager(String(newVal));
                                return newVal;
                            });
                        }}
                    >
                        1/2
                    </InputButton>
                )
            case "half":
                if (maxWager != null) {
                    return (
                        <InputButton
                            className="px-2 w-auto"
                            onClick={() => {
                                setStringWager(String(maxWager / 2));
                                setWager(maxWager / 2);
                            }}>
                            Half
                        </InputButton>
                    )
                }
                return
            case "max":
                if (maxWager != null) {
                    return (
                        <InputButton
                            className="px-2 w-auto"
                            onClick={() => {
                                setStringWager(String(maxWager));
                                setWager(maxWager);
                            }}>
                            Max
                        </InputButton>
                    )
                }
                return
            case "min":
                if (minWager != null) {
                    return (
                        <InputButton
                            className="px-2 w-auto"
                            onClick={() => {
                                setStringWager(String(minWager));
                                setWager(minWager);
                            }}>
                            Min
                        </InputButton>
                    )
                }
                return
        }
    }

    return (
        <Input
            {...props}
            isLoading={isLoading}
            classes={{ ...classes, wrapper: className }}
            name={name || "betAmount"}
            variant="game"
            type="number"
            isPreview={isPreview}
            disabled={!!disabled || !!isPreview}
            step={minWager?.toString() || ""}
            min={minWager?.toString() || ""}
            error={error}
            value={stringWager || ""}
            onChange={(e) => {
                const value = e.target.value;
                const [firstChar, ...restChars] = value.split('');
                if (e.target.value.length > 1 && firstChar === "0" && (restChars[0] !== ".")) {
                    const wagerWithDot = [firstChar, '.', ...restChars].join('');
                    setWager(Number(wagerWithDot || '0') || 0);
                    setStringWager(wagerWithDot);
                } else {
                    setWager(Number(e.target.value || '0') || 0);
                    setStringWager(e.target.value);
                }
            }}
            onFocus={() => {
                if (stringWager === "0") {
                    setStringWager("")
                }
            }}
            onBlur={(e) => {
                if (
                    !allowEmptyValue
                    && (
                        stringWager === ""
                        || String(wager) === ""
                        || wager === ''
                        || Number.isNaN(Number(stringWager))
                    )
                ) {
                    setWager(0);
                    setStringWager("0");
                } else {
                    setWager(stringWager)
                }
            }}
            leftInfo={
                iconUrl ?
                    <Icon
                        iconUrl={iconUrl}
                        className="mb-[2px] mr-[5px] [&>img]:rounded-full"
                    /> : null
            }
            rightInfo={
                !disabled && actions && (
                    <div className="ml-[5px] flex gap-1">
                        {actions.map(renderAction)}
                    </div>
                )
            }
        />
    );
};