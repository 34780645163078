import { PublicKey } from "@solana/web3.js";
import GameSpec from "./gameSpec";
import * as anchor from '@coral-xyz/anchor'
import { ZeebitV2 } from './program-types/solana_zeebit_v2'
import PlayerToken from "./playerToken";
import { APP_NETWORK_TYPE } from "../types/chain";

export default class GameInstanceSolo {

    private _instancePubkey: PublicKey;
    private _gameSpec: GameSpec;
    private _playerToken: PlayerToken;
    private _erState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] | undefined;
    private _baseState: anchor.IdlAccounts<ZeebitV2>["instanceSolo"] | undefined;
    private _chain: APP_NETWORK_TYPE;

    constructor(
        instanceSoloPubkey: PublicKey,
        gameSpec: GameSpec,
        playerToken: PlayerToken,
        chain: APP_NETWORK_TYPE,
        baseState?: any,
        erState?: any
    ) {
        this._gameSpec = gameSpec;
        this._instancePubkey = instanceSoloPubkey;
        this._playerToken = playerToken;
        this._baseState = baseState;
        this._erState = erState;
        this._chain = chain;
    }

    async loadState() {
        const baseState = await this._gameSpec.loadInstanceState(this._instancePubkey);
        const erState = await this._gameSpec.loadErInstanceState(this._instancePubkey);

        this._baseState = baseState
        this._erState = erState
    }

    static loadFromBuffer(instancePubkey: PublicKey, gameSpec: GameSpec, playerToken: PlayerToken, chain: APP_NETWORK_TYPE, baseStateBuffer?: any, erStateBuffer?: any) {
        const instanceSolo = new GameInstanceSolo(instancePubkey, gameSpec, playerToken, chain)

        try {
            instanceSolo._baseState = baseStateBuffer != null ? gameSpec.baseProgram.coder.accounts.decode('instanceSolo', baseStateBuffer): undefined;
        } catch (err) {
            console.warn({
                err
            })
        }

        try {
            instanceSolo._erState = erStateBuffer != null ? gameSpec.erProgram.coder.accounts.decode('instanceSolo', erStateBuffer): undefined;
        } catch (err) {
            console.warn({
                err
            })
        }

        return instanceSolo;
    }

    static async load(instancePubkey: PublicKey, gameSpec: GameSpec, playerToken: PlayerToken, chain: APP_NETWORK_TYPE) {
        const instanceSolo = new GameInstanceSolo(instancePubkey, gameSpec, playerToken, chain)
        await instanceSolo.loadState();

        return instanceSolo;
    }

    updateState(updatedState: any) {
        if (this.isDelegated) {
            this._erState = {
                ...this._erState,
                ...updatedState
            }
        } else {
            this._baseState = {
                ...this._baseState,
                ...updatedState
            }
        }

        return this;
    }

    updateBet(betMeta: any) {
        let stateToUpdate = this.isDelegated ? this._erState: this._baseState

        if (stateToUpdate == null) {
            return this
        }

        let betsToUpdate = stateToUpdate?.bets || []
        const betId = betMeta.betIdx != null ? betMeta.betIdx: betMeta.idx

        if (betsToUpdate.length == 0) {
            betsToUpdate.push(betMeta)
            stateToUpdate.bets = betsToUpdate
        } else {
            let updatedBets: any[] = []

            const bet = betsToUpdate.find((bet) => {
                const comparison = bet.betIdx != null ? bet.betIdx: bet.idx
                
                return comparison == betId;
            })

            if (bet == null) {
                updatedBets = [...betsToUpdate, betMeta]
                stateToUpdate.bets = updatedBets
            } else {
                betsToUpdate.forEach((bet) => {
                    const comparison = bet.betIdx != null ? bet.betIdx: bet.idx
                    if (comparison == betId) {
                        const combined = {
                            ...bet,
                            ...betMeta
                        }

                        updatedBets.push({
                            ...combined,
                            playerLocked: combined.playerLiability || combined.playerLocked,
                            houseLocked: combined.houseLiability || combined.houseLocked
                        })
                    } else {
                        updatedBets.push(bet)
                    }
                })

                stateToUpdate.bets = updatedBets
            }
        }

        if (this.isDelegated) {
            this._erState = stateToUpdate
        } else {
            this._baseState = stateToUpdate
        }

        return this;
    }

    get state() {
        return this.isDelegated ? this.erState: this.baseState;
    }

    get baseState() {
        return this._baseState;
    }

    get erState() {
        return this._erState;
    }

    get isDelegated() {
        return this._chain == APP_NETWORK_TYPE.MB_AND_SOLANA
    }

    get instancePubkey() {
        return this._instancePubkey;
    }

    get gameSpec() {
        return this._gameSpec;
    }

    get playerToken() {
        return this._playerToken;
    }

    get hasState() {
        return this.state != null
    }

    get identifier() {
        return this.state?.identifier;
      }
}