import * as anchor from "@coral-xyz/anchor";
import { ZeebitV2 } from "./program-types/solana_zeebit_v2";
import { PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, NATIVE_MINT, TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID, createAssociatedTokenAccountIdempotent, createAssociatedTokenAccountIdempotentInstruction, getAssociatedTokenAddressSync } from '@solana/spl-token';
import House from "./house";
import { toHouseTokenStatus } from "./utils";
import { HouseTokenStatus } from "./enums";

export const BPS_DENOMINATOR = 10000;

export default class HouseToken {

    private _house: House;
    private _tokenMintPubkey: PublicKey;
    private _houseTokenPubkey: PublicKey;
    private _erState: anchor.IdlAccounts<ZeebitV2>["houseToken"];
    private _baseState: anchor.IdlAccounts<ZeebitV2>["houseToken"];
    private _tokenProgram: PublicKey;

    constructor( 
        house: House,
        tokenMintPubkey: PublicKey,
        tokenProgram: PublicKey = TOKEN_PROGRAM_ID
    ) {
        this._house = house;
        this._tokenMintPubkey = tokenMintPubkey;
        this._houseTokenPubkey = HouseToken.deriveHouseTokenPubkey(
            house.publicKey,
            tokenMintPubkey,
            house.baseProgram.programId
        );
        this._tokenProgram = tokenProgram;
    };

    static async load(
        house: House,
        tokenMintPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const houseToken = new HouseToken(
            house,
            tokenMintPubkey,
        )
        await houseToken.loadBaseState(commitmentLevel);
        if (houseToken.isDelegated) {
            houseToken.loadErState(commitmentLevel);
        }

        // SET TOKEN PROGRAM
        if (tokenMintPubkey.toString() != NATIVE_MINT.toString()) {
            try {
                const tokenAccount = await houseToken.baseProgram.provider.connection.getAccountInfo(tokenMintPubkey)
                houseToken.setTokenProgram(tokenAccount.owner)
            } catch (err) {
                console.warn({
                    err
                })
            }
        }

        return houseToken
    };

    setTokenProgram(tokenProgram: PublicKey) {
        this._tokenProgram = tokenProgram
    }

    get tokenProgram() {
        return this._tokenProgram
    }

    static async loadFromState(
        house: House,
        tokenMintPubkey: PublicKey,
        baseState?: any,
        erState?: any
    ) {
        let tokenProgram = TOKEN_PROGRAM_ID

        try {
            const tokenAccount = await house.baseProgram.provider.connection.getAccountInfo(tokenMintPubkey)
            tokenProgram = tokenAccount.owner
        } catch (err) {
            console.warn({
                err
            })
        }

        const houseToken = new HouseToken(
            house,
            tokenMintPubkey,
            tokenProgram
        )
        houseToken._baseState = baseState;
        houseToken._erState = erState;

        return houseToken
    };

    async loadBaseState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.baseProgram.account.houseToken.fetchNullable(
            this._houseTokenPubkey,
            commitmentLevel
        );
        if (state) {
            this._baseState = state;
        }
        return
    }

    async loadErState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.erProgram.account.houseToken.fetchNullable(
            this._houseTokenPubkey,
            commitmentLevel
        );
        if (state) {
            this._erState = state;
        } 
        return
    }

    static deriveHouseTokenPubkey(
        housePubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_token"),
                housePubkey.toBuffer(),
                tokenMintPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    static deriveHouseTokenBankPubkey(
        housePubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        programId: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_token_bank"),
                housePubkey.toBuffer(),
                tokenMintPubkey.toBuffer()
            ],
            programId
        );
        return pk
    };

    static deriveHouseTokenVaultPubkey(
        houseTokenPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        tokenProgram: PublicKey = TOKEN_PROGRAM_ID
    ): PublicKey {
        return getAssociatedTokenAddressSync(
            tokenMintPubkey,
            houseTokenPubkey,
            true,
            tokenProgram
        );
    };

    deriveHouseTokenUpdatePubkey(
        updateNonce: anchor.BN
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("house_token_update"),
                this.publicKey.toBuffer(),
                updateNonce.toBuffer('le', 8)
            ],
            this.baseProgram.programId
        );
        return pk
    }

    get house() {
        return this._house
    }

    get baseProgram() {
        return this.house.baseProgram
    }

    get erProgram() {
        return this.house.erProgram
    }

    get programId() {
        return this.house.baseProgram.programId
    }

    get publicKey() {
        return this._houseTokenPubkey
    }

    get tokenMintPubkey() {
        return this._tokenMintPubkey
    }

    get baseState() {
        return this._baseState
    }

    get erState() {
        return this._erState
    }

    get delegationStatus(): string | null {
        return this.baseState?.delegationStatus ? Object.keys(this.baseState.delegationStatus)[0] : null;
    }

    get isDelegated() {
        return this.delegationStatus ? (this.delegationStatus == "delegated" ? true : false) : false;
    }

    get bankPublicKey() {
        return HouseToken.deriveHouseTokenBankPubkey(this.house.publicKey, this.tokenMintPubkey, this.programId);
    }

    get incrementUnit() {
        return this.state?.incrementUnit != null ? Number(this.state?.incrementUnit): undefined
    }

    get availableBalance() {
        return this.state != null ? Number(this.state.availableBalance): undefined
    }

    get lockedBalance() {
        return this.state != null ? Number(this.state.lockedBalance): undefined
    }

    get playerBalance() {
        return this.state != null ? Number(this.state.playerBalance): undefined
    }

    get status() {
        return this.state != null ? toHouseTokenStatus(this.state.status): undefined
    }

    get isActive() {
        return this.status == HouseTokenStatus.Active
    }

    get vaultPublicKey() {
        return HouseToken.deriveHouseTokenVaultPubkey(
            this.bankPublicKey,
            this.tokenMintPubkey,
            this.tokenProgram
        )
    }

    get state() {
        return this.isDelegated == true ? (this._erState || this._baseState): this._baseState;
    }

    get proportionTotalBankrollBps() {
        return this.state?.proportionTotalBankrollBps
    }

    get outstandingLpBalance() {
        return this.state != null ? Number(this.state.outstandingLpTokens): undefined
    }

    static async airdropTokensIxns (house: House, amount: number, owner: PublicKey, tokenMint: PublicKey): Promise<TransactionInstruction[]> {
        let tokenProgram = TOKEN_PROGRAM_ID

        try {
            const tokenMintAccount = await house.baseProgram.provider.connection.getAccountInfo(tokenMint)
            tokenProgram = tokenMintAccount.owner
        } catch (err) {
            console.warn({
                err
            })
        }


        const tokenAccountPubkey = getAssociatedTokenAddressSync(tokenMint, owner, false, tokenProgram);
    
        const aidropIx = await house.baseProgram.methods.airdrop({
          amount: new anchor.BN(amount)
        }).accounts({
          owner: owner,
          house: house.publicKey,
          tokenMint: tokenMint,
          tokenAccount: tokenAccountPubkey,
          tokenProgram: tokenProgram,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId
        }).instruction()

        return [
            createAssociatedTokenAccountIdempotentInstruction(owner, tokenAccountPubkey, owner, tokenMint, tokenProgram),
            aidropIx
        ]
    }
}