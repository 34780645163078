
import { ExpectancyDirection } from "../../components/zeebros-page/commonComponents";
import { NetworkType, defaultNetwork } from "../../utils/chain/network";
import Attribute from "./attribute";
import Distribution from "./distribution";
import { ATTRIBUTES } from "./zeebrosCollection";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

export default class Trait {

    private _attribute: Attribute;
    private _traitId: number;
    private _state: any;

    constructor(
        attribute: Attribute,
        traitId: number,
        state: any
    ) {
        this._attribute = attribute;
        this._traitId = traitId;
        this._state = state;
    };

    get id() {
        return this._traitId
    }

    get countPopulation() {
        return this._state.countPopulation
    }

    get proportionPopulation() {
        return this._attribute.nftStakingInstance ? (this._state.countPopulation / this._attribute.nftStakingInstance.countPopulation) : 0
    }

    get countStaked() {
        return this._state.countStaked
    }

    get proportionStaked() {
        return this.countPopulation ? (this._state.countStaked / this.countPopulation) : 0
    }

    get proportionStakedOutOfPopulation() {
        return this.countPopulation ? (this.countStaked / this.countPopulation) : 0
    }

    get propertyOne() {
        return this._state.property1
    }

    get countTimesSelected() {
        return Number(this._state.countTimesSelected)
    }

    get pastRewardsAmountUI() {
        return Number(this._state.pastRewardsAmount) / Math.pow(10, this.attribute.nftStakingInstance.tokenDecimals)
    }

    get pastRewardsAmount() {
        return Number(this._state.pastRewardsAmount)
    }

    get pastRewardsAmountPerStaker() {
        return Number(this._state.pastRewardsAmountPerStaker)
    }

    get name() {
        return this._attribute.nameMapping[this._traitId]
    }

    get imageUrl() {
        return Trait.getImageUrl(this.attribute.id, this.id)
    }

    get chanceTraitSelected() {
        return 1 / this.attribute.countTraits
    }

    static getImageUrl(attributeId: number, traitId: number) {
        // TRAITS MISMATCH - ONE EXTRA ON DEVNET
        const oneLess = Trait.isDevnetMismatch(attributeId, traitId)

        return getS3StaticFolderUrl(
            `/static/zeebros/attribute-traits/${attributeId}/${oneLess ? traitId - 1 : traitId}.jpg`
        );
    }

    static isDevnetMismatch(attributeId: number, traitId: number) {
        return defaultNetwork == NetworkType.DEVNET && attributeId == ATTRIBUTES["Eyes & Accessories"] && traitId >= 10
    }

    get state() {
        return this._state
    }

    get attribute() {
        return this._attribute
    }

    get distribution(): Distribution | undefined {
        return this.attribute.nftStakingInstance.nextDistribution != null ? this.attribute.nftStakingInstance.nextDistribution : this._attribute.nftStakingInstance.currentDistribution
    }

    get distributionAttribute(): Attribute | undefined {
        return this.distribution?.selectedAttribute
    }

    get distributionChance() {
        return 1 / this.attribute.countTraits
    }

    get expectancyDirection(): ExpectancyDirection {
        // TODO - MAP EXPECTANCY DIRECTION
        const expectancyPercentage = this.expectancyDifference * 100

        if (expectancyPercentage > 0) {
            return expectancyPercentage > 5 ? ExpectancyDirection.UpLots : ExpectancyDirection.Up
        } else if (expectancyPercentage < 0) {
            return expectancyPercentage < 5 ? ExpectancyDirection.DownLots : ExpectancyDirection.Down
        }

        return ExpectancyDirection.None
    }

    // NEW EXPECTANCY VALUES

    // CASE WHERE ATTRIBUTE NOT SELECTED (0.8 * average) + 0.2 * the value as it this trait’s attribute has been selected
    get expectedTraitWinningAttributeNotSelected() {
        if (this.attribute.nftStakingInstance?.currentDistribution?.traitSelectedPrize == null || this.attribute.nftStakingInstance.currentDistribution.traitSelectedPrize <= 0) {
            return 0
        }

        // 0.2 * the value as it this trait’s attribute has been selected
        const traitSelectedPart = ((this.attribute.nftStakingInstance.currentDistribution.traitSelectedPrize * this.chanceTraitSelected) / Math.max(this.countStaked, 1)) / this.attribute.nftStakingInstance.countAttributes
        const averagePart = this.attribute.nftStakingInstance.averageZeebroExpectancy.basis * (1 - (1 / this.attribute.nftStakingInstance.countAttributes))

        return traitSelectedPart + averagePart
    }

    get expectedValueAttributeNotSelected() {
        return this.expectedTraitWinningAttributeNotSelected + this.expectedJackpotWinnings
    }

    get expectedJackpotWinnings() {
        if (this.attribute.nftStakingInstance.currentDistribution == null) {
            return 0
        }

        return this.attribute.nftStakingInstance.currentDistribution.jackpotPrize / this.attribute.nftStakingInstance.countPopulation
    }

    // CASE WHERE ATTRIBUTE SELECTED
    get chanceTraitSelectedAfterAttributeSelected() {
        if (this.isCurrentDistributionTrait == false) {
            return 0
        }

        return this.chanceTraitSelected
    }

    get expectedTraitWinningsAttributeSelected() {
        if (this.attribute.nftStakingInstance.currentDistribution == null || this.chanceTraitSelectedAfterAttributeSelected == null || this.isCurrentDistributionTrait == false) {
            return 0
        }

        return (this.attribute.nftStakingInstance.currentDistribution.traitSelectedPrize * this.chanceTraitSelectedAfterAttributeSelected) / Math.max(this.countStaked, 1)
    }

    get expectedValueAttributeSelected() {
        return this.expectedTraitWinningsAttributeSelected + this.expectedJackpotWinnings
    }

    get expectedValueDollarAttributeSelected() {
        return this.expectedValueAttributeSelected / Math.pow(10, this.attribute.nftStakingInstance.tokenDecimals)
    }

    get hasCurrentDistributionAttribute() {
        return this.attribute.nftStakingInstance.currentDistribution.selectedAttribute != null
    }

    get isCurrentDistributionTrait(): boolean {
        return this.hasCurrentDistributionAttribute && this.attribute.nftStakingInstance.currentDistribution.selectedAttribute == this.attribute.id
    }

    get expectedValue() {
        if (this.hasCurrentDistributionAttribute) {
            if (this.isCurrentDistributionTrait) {
                // SAME AS ZEEBRO WITH TRAIT
                return this.expectedValueAttributeSelected
            } else {
                // JACKPOT + (TRAIT POOL / COUNT STAKED)
                return this.expectedJackpotWinnings + (this.attribute.nftStakingInstance.currentDistribution.traitSelectedPrize / this.attribute.nftStakingInstance.countStaked)
            }
        } else {
            // JACKPOT + (0.8 * Average)
            return this.expectedValueAttributeNotSelected
        }
    }

    get expectedValueDollar() {
        return this.expectedValue / Math.pow(10, this.attribute.nftStakingInstance.tokenDecimals)
    }

    get expectancyDifference() {
        if (this.attribute.nftStakingInstance?.averageZeebroExpectancy?.basis == null || this.attribute.nftStakingInstance.averageZeebroExpectancy.basis <= 0) {
            return 0
        }

        // (Expectancy (pre/post attribute selection, depending on time) / baseline) - 1) * 100 [to give the % value]
        return (this.expectedValue / this.attribute.nftStakingInstance.averageZeebroExpectancy.basis) - 1
    }

    // END NEW EXPECTANCIES
}
