// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-button-prev,
.swiper-button-next, .swiper-button-prev:after,
.swiper-button-next:after, .swiper-button-prev:before,
.swiper-button-next:before {
  color: #fafcff; /* Set arrow color */
  /* font-size: 24px !important;
  width: 30px !important;
  height: 30px !important;
  margin-top: -10px; */
}


.swiper-pagination-bullet {
    background-color: #fafcff; /* Default dot color */
    opacity: 0.5;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #fafcff; /* Active dot color */
    opacity: 1;
  }

  .swiper-pagination {
    position: relative !important; /* Move dots outside the Swiper container */
    bottom: 0px !important; /* Adjust spacing below the slider */
    margin-top: 12px !important;
  }
:root {
  --swiper-navigation-size: 30px !important; 
}`, "",{"version":3,"sources":["webpack://./src/styles/swiper.css"],"names":[],"mappings":"AAAA;;;;EAIE,cAAc,EAAE,oBAAoB;EACpC;;;sBAGoB;AACtB;;;AAGA;IACI,yBAAyB,EAAE,sBAAsB;IACjD,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,qBAAqB;IAChD,UAAU;EACZ;;EAEA;IACE,6BAA6B,EAAE,2CAA2C;IAC1E,sBAAsB,EAAE,oCAAoC;IAC5D,2BAA2B;EAC7B;AACF;EACE,yCAAyC;AAC3C","sourcesContent":[".swiper-button-prev,\n.swiper-button-next, .swiper-button-prev:after,\n.swiper-button-next:after, .swiper-button-prev:before,\n.swiper-button-next:before {\n  color: #fafcff; /* Set arrow color */\n  /* font-size: 24px !important;\n  width: 30px !important;\n  height: 30px !important;\n  margin-top: -10px; */\n}\n\n\n.swiper-pagination-bullet {\n    background-color: #fafcff; /* Default dot color */\n    opacity: 0.5;\n  }\n  \n  .swiper-pagination-bullet-active {\n    background-color: #fafcff; /* Active dot color */\n    opacity: 1;\n  }\n\n  .swiper-pagination {\n    position: relative !important; /* Move dots outside the Swiper container */\n    bottom: 0px !important; /* Adjust spacing below the slider */\n    margin-top: 12px !important;\n  }\n:root {\n  --swiper-navigation-size: 30px !important; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
