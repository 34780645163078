import { PublicKey } from "@solana/web3.js"
import { AggregatorAccount, SwitchboardProgram } from "@switchboard-xyz/solana.js"
import Big from 'big.js'

export const readLatestOracleValue = async (switchboardProgram: SwitchboardProgram, oraclePubkey: PublicKey): Promise<string> => {
    const aggregatorAccount = new AggregatorAccount(switchboardProgram, oraclePubkey);

    const result: Big | null = await aggregatorAccount.fetchLatestValue();
    if (result === null) {
      throw new Error("Aggregator holds no value");
    }
    
    return result.toString()
}