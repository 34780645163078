import { PublicKey } from "@solana/web3.js";

export const ANCHOR_DISCRIMINATOR_LEN = 8;
export const COLLECTION_TABLE_HEADER_LEN = 40;
export const INDIVIDUAL_RECORD_LEN = 96;
export const MAX_INDIVIDUALS_TO_UPDATE_PER_IXN = 20;
export const MAX_UPDATE_IXNS_PER_TXN = 5;
export const MAX_INDIVIDUALS_TO_UPDATE_PER_TXN = MAX_INDIVIDUALS_TO_UPDATE_PER_IXN * MAX_UPDATE_IXNS_PER_TXN;
export const MAX_ACCOUNTS_PER_COLLECT_PER_IXN = 10;
export const RAKEBACK_ATTRIBUTE_ID = 1;
export const ZEEBROS_NFT_COLLECTION_PUBKEY = new PublicKey('99FJrcnKa79nU2RJ12ZXboNcnxsBUn4mDQ6hHAagND4K');
export const ZEEBROS_NFT_SWITCHBOARD_FEED_PUBKEY = new PublicKey('6GkeMh8br7oTvYnzHSXpRaGdzfkvGsTEpLTTNur5qANG');
export const METAPLEX_AUTHORIZATION_RULES_ACCOUNT = new PublicKey('2CR55B7NFupbEuqJLsgzmRBHk1mDqqemHZrknrrGirGU')