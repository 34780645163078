import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import NftStaking from "./nftStaking";
import Trait from "./trait";

export enum CollectionTableStatus {
    Uninitialized = 0,
    Initialized = 1,
    Populated = 2,
}
export interface ICollectionTableHeader {
    countPopulation: number
    main: PublicKey
    reserved1: number
    reserved2: number[]
    status: CollectionTableStatus
}

export interface ICollectionTableRow {
    balanceToCollect: anchor.BN
    countIndividualWinsStaked: number
    countIndividualWinsTotal: number
    nftMint: PublicKey
    reserved2: number[]
    sumIndividualWinningsFomo: anchor.BN
    sumIndividualWinningsStaked: anchor.BN
    sumTraitWinningsFomo: anchor.BN
    sumTraitWinningsStaked: anchor.BN
    timeStaked: anchor.BN
    traits: Trait[]
    updateInstanceNonce: number
}

export default class CollectionTable {

    private _nftStakingInstance: NftStaking;
    private _state: any;
    private _publicKey: PublicKey;
    private _collectionTableHeader: any;
    private _collectionTableRows: any;

    constructor( 
        nftStakingInstance: NftStaking,
        pubkey: PublicKey,
    ) {
        this._nftStakingInstance = nftStakingInstance;
        this._publicKey = pubkey;
    };

    static async load(
        nftStakingInstance: NftStaking,
        pubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const collectionTable = new CollectionTable(
            nftStakingInstance,
            pubkey,
        );
        const collectionTableState = await nftStakingInstance.program.account.collectionTable.fetchNullable(
            pubkey,
            commitmentLevel
        );
        collectionTable._collectionTableHeader = collectionTableState.header;
        var trancheOne: any[] = collectionTableState.recordsTranche1 as any[];
        collectionTable._collectionTableRows = trancheOne.concat(collectionTableState.recordsTranche2)
            .concat(collectionTableState.recordsTranche3)
            .concat(collectionTableState.recordsTranche4)
            .concat(collectionTableState.recordsTranche5)
            .slice(0, collectionTable._collectionTableHeader.countPopulation);

        return collectionTable;
    }

    get publicKey() {
        return this._publicKey
    }
    get mainPubkey() {
        return this._state.main
    }
    get nftStakingInstance() {
        return this._nftStakingInstance
    }
    get header(): ICollectionTableHeader {
        return this._collectionTableHeader
    }
    get rows(): ICollectionTableRow[] {
        return this._collectionTableRows
    }
}

