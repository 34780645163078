import { SafeEventEmitterProvider } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter, UX_MODE } from "@web3auth/openlogin-adapter";
import { SolanaPrivateKeyProvider } from "@web3auth/solana-provider";
import { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { NetworkContext } from "../../contexts/NetworkContext";
import SolanaRpc from "../../utils/solana/rpc";
import { Connection, PublicKey } from "@solana/web3.js";
import { WEB3_AUTH_NO_MODAL_CONFIG, WEB3_CHAIN_CONFIG } from "../../constants/web3";
import { WEB3_AUTH_REDIRECT } from "../../utils/env/env";
import { IWeb3Connection } from "../../types/web3";
import { APP_NETWORK_TYPE } from "../../types/chain";

export const useSolWeb3 = (): IWeb3Connection => {
  // SOLANA
  const [web3Auth, setWeb3Auth] = useState<Web3AuthNoModal>();
  const [web3Provider, setWeb3Provider] = useState<SafeEventEmitterProvider>();
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [solanaRpc, setSolanaRpc] = useState<SolanaRpc>();
  const [walletPubkey, setWalletPubkey] = useState<PublicKey | null>(null);
  const { client, chain, uptoDateWithChain } = useContext(NetworkContext);

  const updateWalletPubkey = (solRpc: SolanaRpc) => {
    solRpc
      .getPubkey()
      .then((pubkey) => {
        if (pubkey != null) {
          setWalletPubkey(new PublicKey(pubkey));
        }
      })
      .catch((e) => {
        console.warn("Issue trying to connect to social wallet. ", e);
      });
  };

  // https://web3auth.io/docs/sdk/pnp/web/no-modal/usage
  async function initWeb3Auth(client: Connection, chain: APP_NETWORK_TYPE) {
    let WEB_3_AUTH_CONFIG = { ...WEB3_AUTH_NO_MODAL_CONFIG }
    let chainConfig = { ...WEB3_CHAIN_CONFIG }
    chainConfig.rpcTarget = client.rpcEndpoint
    WEB_3_AUTH_CONFIG.chainConfig = chainConfig

    const newWeb3auth = new Web3AuthNoModal(WEB_3_AUTH_CONFIG);

    const privateKeyProvider = new SolanaPrivateKeyProvider({
      config: { chainConfig: chainConfig },
    });

    const openloginAdapter = new OpenloginAdapter({
      adapterSettings: {
        uxMode: UX_MODE.REDIRECT,
      },
      loginSettings: {
        redirectUrl: WEB3_AUTH_REDIRECT,
      },
      privateKeyProvider,
    });
    newWeb3auth.configureAdapter(openloginAdapter);

    await newWeb3auth.init();
    setWeb3Auth(newWeb3auth);

    if (newWeb3auth.provider) {
      setWeb3Provider(newWeb3auth.provider);
    }

    if (newWeb3auth.connected == true && newWeb3auth.provider != null) {
      loginAlreadyConnected(newWeb3auth.provider, client, chain);
    }

    return newWeb3auth;
  }

  useEffect(() => {
    if (client == null || uptoDateWithChain == false) {
      return;
    }

    initWeb3Auth(client, chain);
  }, [client, chain, uptoDateWithChain]);

  const loginAlreadyConnected = (provider: SafeEventEmitterProvider, client: Connection, chain: APP_NETWORK_TYPE) => {
    setWeb3Provider(provider);
    const rpc = new SolanaRpc(provider, client, chain);
    setSolanaRpc(rpc);
    updateWalletPubkey(rpc);
    setIsLoggedIn(true);
  };

  const loginSocial = useCallback(
    async (walletName: string, provider?: string) => {
      if (web3Auth == null || client == null) {
        throw new Error("Issue with web3 auth or client");
      } else if (web3Auth.connected == true && web3Auth.cachedAdapter != null) {
        if (web3Auth.provider != null && web3Auth.cachedAdapter == walletName) {
          loginAlreadyConnected(web3Auth.provider, client, chain);
          return;
        } else {
          await web3Auth.logout();
        }
      }

      const newProvider = await web3Auth.connectTo(walletName, {
        loginProvider: provider,
      });

      if (newProvider == null) {
        throw new Error("Issue Logging into wallet");
      }

      setWeb3Provider(newProvider);
      const rpc = new SolanaRpc(newProvider, client, chain);
      setSolanaRpc(rpc);
      updateWalletPubkey(rpc);
      setIsLoggedIn(true);
    },
    [web3Auth, client, chain],
  );

  const logoutSocial = useCallback(async () => {
    if (!web3Auth) {
      console.warn("web3auth not initialized yet");
      return;
    }

    if (web3Auth.connected == true) {
      await web3Auth.logout();
    }

    setSolanaRpc(undefined);
    setWeb3Provider(undefined);
    setWeb3Auth(web3Auth);
    setIsLoggedIn(false);
    setWalletPubkey(null);
  }, [web3Auth]);

  return useMemo(() => {
    return {
      loggedIn: loggedIn,
      connect: loginSocial,
      disconnect: logoutSocial,
      web3Auth: web3Auth,
      walletAddress: walletPubkey,
      rpc: solanaRpc,
    };
  }, [loggedIn, loginSocial, logoutSocial, web3Auth, walletPubkey, solanaRpc])
};
