import React, { FC, useCallback, useState } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./style.css";
import Icon from "../icon/Icon";
import { supportedLanguages } from "../../../utils/config/utils";
import Button from "../button/Button";
import enIconUrl from "../../../assets/lang-icons/UK.png";
import espIconUrl from "../../../assets/lang-icons/ESP.png";
import chiIconUrl from "../../../assets/lang-icons/CHI.png";
import gerIconUrl from "../../../assets/lang-icons/GER.png";

import Tooltip from "./Tooltip";
import { AverLanguage } from "../../../types/language";

export interface TooltipLangProps extends TippyProps {
  className?: string;
  setLang: React.Dispatch<React.SetStateAction<AverLanguage>>;
}
export const langIcon = (code: string) => {
  switch (code) {
    case "EN":
      return enIconUrl;
    case "ES":
      return espIconUrl;
    case "CN":
      return chiIconUrl;
    case "DE":
      return gerIconUrl;
    default:
      return enIconUrl;
  }
};
const TooltipLang: FC<TooltipLangProps> = ({ children, className, setLang, ...props }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const langs = supportedLanguages();

  return (
    <Tippy
      className={`${className} LangTip`}
      interactive={true}
      appendTo={document.body}
      visible={isTooltipVisible}
      onClickOutside={() => setIsTooltipVisible(false)}
      onHide={() => setIsTooltipVisible(false)}
      content={
        <div className="flex flex-col">
          {langs.map(({ displayName, code, enabled }) => (
            <Button
              key={displayName}
              toolTipPlacement="right"
              disabled={!enabled}
              className="justify-stretch bg-transparent disabled:bg-transparent"
              variant="tooltip"
              size="xxs"
              icon={<Icon iconUrl={langIcon(code)} />}
              onClick={() => setLang(code)}
            >
              {displayName}
            </Button>
          ))}
        </div>
      }
      {...props}
    >
      <div onClick={() => setIsTooltipVisible((prev) => !prev)}>{children}</div>
    </Tippy>
  );
};

export default TooltipLang;
