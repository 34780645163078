import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import House from "../house";
import GameSpec from "../gameSpec";
import PlayingCard, { PlayingCardRank } from "../utils";
import { ZeebitV2 } from "../../target/types/zeebit_v2";
import PlayerToken from "../playerToken";
import GameInstanceSolo from "../gameInstance";
import { APP_NETWORK_TYPE } from "../../types/chain";


export enum BlackjackAction {
  HIT = 'hit',
  STAND = 'stand',
  SPLIT = 'split',
  DOUBLE = 'double',
}

export default class Blackjack extends GameSpec {

  constructor(
    house: House,
    // hseTkn: HouseToken,
    gameSpecPubkey: PublicKey,
  ) {
    super(
      house,
      // hseTkn,
      gameSpecPubkey,
    )
    this.gameSpecPubkey = gameSpecPubkey;
  }
  static createNewInstance(
    game: GameSpec,
    gameSpecPubkey: PublicKey,
    playerToken: PlayerToken,
    prevInstance: BlackjackInstance,
    stateUpdate: any) {
    console.log('createNewState', { prevInstance, stateUpdate });
    console.log('state', stateUpdate);

    const newState = { stateUpdate }
    return new BlackjackInstance(
      game as Blackjack,
      gameSpecPubkey,
      playerToken,
      newState
    )
  }
  static async load(
    house: House,
    gameSpecPubkey: PublicKey,
  ) {
    const game = new Blackjack(
      house,
      gameSpecPubkey,
    )
    await game.loadAllState();
    return game
  }

  static async loadInstance(
    game: GameSpec,
    gameSpecPubkey: PublicKey,
    instancePubkey: PublicKey,
    playerToken: PlayerToken,
    baseInstanceState?: any,
    erInstanceState?: any,
  ) {
    console.log('loadInstance - INSTANCE STATE', { baseInstanceState, erInstanceState });

    const baseState = baseInstanceState || await game.loadInstanceState(instancePubkey);
    const erState = erInstanceState || await game.loadErInstanceState(instancePubkey);


    console.log('loadInstance - INSTANCE STATE', { baseState, erState });
    console.log('loadInstance - INSTANCE STATE', { identifier: (baseState || erState)?.identifier?.toString() });


    if (baseState || erState) {
      return new BlackjackInstance(
        game as Blackjack,
        gameSpecPubkey,
        playerToken,
        baseState,
        erState
      )
    }
    return null;
  }

  static loadInstanceFromBuffer(
    game: GameSpec,
    instancePubkey: PublicKey,
    playerToken: PlayerToken,
    chain: APP_NETWORK_TYPE,
    baseInstanceBuffer?: any,
    erInstanceBuffer?: any,
  ) {
    const baseState = baseInstanceBuffer != null ? game.baseProgram.coder.accounts.decode('instanceSolo', baseInstanceBuffer): undefined
    const erState = erInstanceBuffer != null ? game.erProgram.coder.accounts.decode('instanceSolo', erInstanceBuffer): undefined

    if (baseState || erState) {
      return new BlackjackInstance(
        game as Blackjack,
        instancePubkey,
        playerToken,
        chain,
        baseState,
        erState
      )
    }
    return null;
  }

  async soloBetIx(
    ownerOrAuth: PublicKey,
    playerToken: PlayerToken,
    inputs: {
      bet_idx: number,
      action: BlackjackAction,
      insurance: boolean,
      identifier: PublicKey,
    },
    wager: number,
    clientSeed: number[],
  ) {
    const instanceRequest = {
      blackjack: {},
    };

    const betRequests = [{
      blackjack: {
        wager: new anchor.BN(wager),
      },
    }];

    const actionRequest = inputs.action || inputs.insurance ? {
      blackjack: {
        betIdx: inputs.bet_idx,
        action: { [inputs.action]: {} },
        insurance: inputs.insurance
      }
    } : null;

    const numberOfBets = 1;

    return await this.soloPlayIx(
      ownerOrAuth,
      playerToken,
      numberOfBets,
      instanceRequest,
      betRequests,
      actionRequest,
      clientSeed,
      inputs.identifier,
    );
  }

  get state() {
    return this.baseState
  }

  get gameConfig() {
    return this.state ? this.state.config.blackjack : null;
  }

  get houseEdge(): number {
    if (this.baseState.config.blackjack) {
      return Number(this.baseState.config.blackjack.edgePerMillion) / 1_000_000
    } else {
      return undefined
    }
  }

  get maxMultiplier(): number {
    if (this.baseState.config.blackjack) {
      return Number(this.baseState.config.blackjack.blackjackMultiplierPerMillion) / 1_000_000
    } else {
      return undefined
    }
  }
  potentialPayout(
    wager: number
  ): number {
    return wager * this.maxMultiplier
  }
  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;
    let totalWagerBasis = 0;

    bets.forEach((bet) => {
      const multiplier = bet.multiplier;
      const payoutOnBet = multiplier * bet.wager;
      const probability = bet.probability;

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wagerBasis;
      totalWagerBasis += bet.wagerBasis;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      totalWagerBasis: totalWagerBasis,
      edgePercentage: edgeDollar / totalWagerBasis, // USED IN CALCULATING MAX BET VIA KELLY
    };
  }
}

type HandAndScores = {
  hand: PlayingCard[],
  minScore: number,
  maxScore: number,
  isNaturalBlackjack: boolean,
  bust: boolean,
  wager: number
}


function minAndMaxScoresFromBlackjackHand(
  hand: PlayingCard[]
): [number, number] {
  var minValue = 0;
  var maxValue = 0;
  hand.forEach((c) => {
    if (c.rankIcon == PlayingCardRank.ACE) {
      minValue += 1;
      maxValue += 11;
    } else {
      minValue += c.blackjackValue;
      maxValue += c.blackjackValue;
    }
  });
  return [minValue, maxValue]
}

function checkNaturalBlackjack(
  hand: PlayingCard[]
): boolean {
  if (hand.length != 2) {
    return false
  }
  if (
    (hand.length == 2) &&
    ((hand[0].rankIcon == PlayingCardRank.ACE && hand[1].blackjackValue == 10) ||
      (hand[1].rankIcon == PlayingCardRank.ACE && hand[0].blackjackValue == 10))
  ) {
    return true
  } else {
    return false
  }
}
export class BlackjackInstance extends GameInstanceSolo {

  constructor(
    gameSpec: GameSpec,
    instancePubkey: PublicKey,
    playerToken: PlayerToken,
    chain: APP_NETWORK_TYPE,
    baseState?: anchor.IdlAccounts<ZeebitV2>["instanceSolo"],
    erState?: anchor.IdlAccounts<ZeebitV2>["instanceSolo"]
  ) {
    super(instancePubkey, gameSpec, playerToken, chain, baseState, erState)
  }
  get dealersHandAndScore(): HandAndScores {
    if (!this.baseState?.state.blackjack) {
      throw Error("Invalid account state type")
    }
    const hand = [new PlayingCard(this.baseState.state.blackjack.dealersFaceUpCard)];
    const [min, max] = minAndMaxScoresFromBlackjackHand(hand);
    const isNaturalBlackjack = checkNaturalBlackjack(hand);
    return {
      hand: hand,
      minScore: min,
      maxScore: max,
      isNaturalBlackjack: isNaturalBlackjack,
      bust: false,
      wager: 0,
    } as HandAndScores
  }

  get playerHands(): HandAndScores[] {
    var hands: HandAndScores[] = [];

    this.baseState?.bets.forEach((b) => {
      if (!b.state.blackjack) {
        throw Error("Invalid bet state type")
      }
      const hand: PlayingCard[] = [];
      b.state.blackjack.hand.filter((c) => c != 255).forEach((c) => {
        if (c <= 51) { hand.push(new PlayingCard(c)) }
      });
      const [min, max] = minAndMaxScoresFromBlackjackHand(hand);
      const isNaturalBlackjack = checkNaturalBlackjack(hand);
      const onChainStatus = Object.keys(b.status)[0];
      const isBust = onChainStatus == "loss";
      hands.push({
        hand: hand,
        minScore: min,
        maxScore: max,
        isNaturalBlackjack: isNaturalBlackjack,
        bust: isBust,
        wager: Number(b.state.blackjack.wager),
        onChainStatus: onChainStatus
      } as HandAndScores)
    });
    return hands
  }

  get isPlayersTurn(): boolean {
    return this.baseState && (Object.keys(this.baseState.status)[0] == "awaitingPlayerResponse") ? true : false
  }

  get activeHandIdx(): number | null {
    return this.baseState?.state.blackjack?.nextBetIdx != null && this.isPlayersTurn ? this.baseState.state.blackjack.nextBetIdx : null
  }

  get hasActiveHand(): boolean {
    return this.activeHandIdx != null
  }

  get insuranceOffered(): boolean {
    if (
      this.baseState?.state.blackjack && 
      this.isPlayersTurn &&                                                                               // It's the player's turn 
      this.baseState?.interactionNonce == 1 &&                                                                // First interaction
      new PlayingCard(this.baseState.state.blackjack?.dealersFaceUpCard).rankIcon == PlayingCardRank.ACE     // Dealer has a face-up Ace
    ) {
      return true
    }
    return false
  }

  get hitOffered(): boolean {
    if (this.isPlayersTurn && this.hasActiveHand) {                                       // it's the player's turn 
      const hand = this.playerHands[this.activeHandIdx];
      if (
        hand?.onChainStatus == "awaitingPlayerUpdate" &&        // The hand's onchain status is "awaitingPlayerUpdate"
        hand.minScore < 21                                      // They're min is 20 or less
      ) {
        return true
      }
    }
    return false
  }

  get standOffered(): boolean {
    if (this.isPlayersTurn && this.hasActiveHand) {                                       // it's the player's turn 
      const hand = this.playerHands[this.activeHandIdx];
      if (
        hand.onChainStatus == "awaitingPlayerUpdate" &&        // The hand's onchain status is "awaitingPlayerUpdate"
        hand.minScore < 21                                      // They're min is 20 or less
      ) {
        return true
      }
    }
    return false
  }

  get splitOffered(): boolean {
    if (this.isPlayersTurn && this.hasActiveHand) {                                   // It's the player's turn 
      const hand = this.playerHands[this.activeHandIdx];
      if (
        hand.onChainStatus == "awaitingPlayerUpdate" &&     // The hand's onchain status is "awaitingPlayerUpdate"
        hand.hand.length == 2 &&                            // Only two cards dealt       
        hand.hand[0].rankIcon == hand.hand[1].rankIcon      // Both cards are the same rank
      ) {
        return true
      }
    }
    return false
  }

  get doubleOffered(): boolean {
    if (this.isPlayersTurn && this.hasActiveHand) {                                   // It's the player's turn 
      const hand = this.playerHands[this.activeHandIdx];
      if (
        hand.onChainStatus == "awaitingPlayerUpdate" &&     // The hand's onchain status is "awaitingPlayerUpdate"
        hand.hand.length == 2 &&                            // Only two cards dealt       
        hand.hand[0].rankIcon != PlayingCardRank.ACE &&
        hand.hand[1].rankIcon != PlayingCardRank.ACE &&     // Neither card is an Ace
        [9, 10, 11].includes(hand.maxScore)                   // Score is 9/10/11 (can use max or min since no aces)
      ) {
        return true
      }
    }
    return false
  }

  get hasSate() {
    return this.baseState != null
  }
}





