export enum AuthorityStatus {
  Suspended = 0,
  Active = 1,
}

export enum TokenStatus {
  Inactive = 0,
  Active = 1,
  OutFlowsSuspended = 2,
  InFlowsSuspended = 3,
}

export enum TokenType {
  BaseTradingCurrency = 0,
  OtherTradingCurrency = 1,
  WrappedSolTradingCurrency = 2,
  OtherWrappedTradingCurrency = 3,
  PlayTradingCurrency = 4,
  ProjectTokenTradingCurrency = 5,
  ProjectTokenNonTradingCurrency = 6,
}

export enum HouseStatus {
  Uninitialized = 0,
  Active = 1,
  Frozen = 2,
  OutFlowsSuspended = 3,
  InFlowsSuspended = 4
}

export const getHouseStatusString = (houseStatus: number) => {
  switch(houseStatus) {
    case HouseStatus.Uninitialized:
      return 'Uninitialized'
    case HouseStatus.InFlowsSuspended:
      return 'InFlowsSuspended'
    case HouseStatus.OutFlowsSuspended:
      return 'OutFlowsSuspended'
    case HouseStatus.Active:
      return 'Active'
    case HouseStatus.Frozen:
      return 'Frozen'
    default:
      return `Unknown -> ${houseStatus}`
  }
}

export enum HouseTokenStatus {
  Uninitialized = 0,
  Active = 1,
  Frozen = 2,
  OutFlowsSuspended = 3,
  InFlowsSuspended = 4
}

export const getHouseTokenStatusString = (houseTokenStatus: number) => {
  switch(houseTokenStatus) {
    case HouseTokenStatus.Uninitialized:
      return 'Uninitialized'
    case HouseTokenStatus.InFlowsSuspended:
      return 'InFlowsSuspended'
    case HouseTokenStatus.OutFlowsSuspended:
      return 'OutFlowsSuspended'
    case HouseTokenStatus.Active:
      return 'Active'
    case HouseTokenStatus.Frozen:
      return 'Frozen'
    default:
      return `Unknown -> ${HouseTokenStatus}`
  }
}


export enum BetStatus {
  Uninitialized = 0,
  Open = 1,
  AwaitingPlayerUpdate = 2,
  Won = 3,
  Loss = 4,
  Push = 5,
  Canceled = 6
}

export enum CallingProgramStatus {
  Inactive = 0,
  Active = 1,
  OutFlowsSuspended = 2,
  InFlowsSuspended = 3,
}

export enum LpStatus {
  Inactive = 1,
  Active = 2,
  OutFlowsSuspended = 3,
  InFlowsSuspended = 4,
}

export enum AvatarSource {
  None = 0,
  Nft = 1,
  Arweave = 2,
  Ipfs = 3,
}

// ------- CASINO ENUMS ------

export enum GameType {
  Unspecified = 0,
  CoinFlip = 1,
  Limbo = 2,
  Plinko = 3,
  Dice = 4,
  Roulette = 5,
  Mines = 6,
  Crash = 7,
  Keno = 8,
  Baccarat = 9,
  SlotsThree = 10,
  Wheel = 11,
  Hurdles = 12,
  BlackJack = 13,
  Slide = 14,
  Jackpot = 15,
  Tower = 16,
  BinaryOption = 101,
}

export enum GameInstanceTrigger {
  User = 0,
  Time = 1,
  Slot = 2,
}

export enum GameTokenStatus {
  Inactive = 0,
  Active = 1,
}

export enum GameStatus {
  Uninitialized = 0,
  Active = 1,
  Frozen = 2,
  OutFlowsSuspended = 3,
  InFlowsSuspended = 4,
}

export enum GameSpecTokenStatus {
  Uninitialized = 0,
  Active = 1,
  Frozen = 2,
  OutFlowsSuspended = 3,
  InFlowsSuspended = 4,
}

export enum GameInstanceStatus {
  Uninitialized = 0,
  Active = 1,
  AwaitingPlayerResponse = 2,
  AwaitingOracleResponse = 3,
  Settling = 4,
  Voided = 5,
  Complete = 6
}

export enum BetOutcome {
  Open = 0,
  Won = 1,
  Lost = 2,
  Push = 3,
  Canceled = 4,
}

// ------- RANDOMNESS ENUMS ------

export enum RandomnessDispatcherStatus {
  Inactive = 0,
  Active = 1,
  NoNewRequests = 2,
}

export enum RandomnessAuthorityStatus {
  Inactive = 0,
  Active = 1,
}

export enum RandomnessCallerStatus {
  Inactive = 0,
  Active = 1,
}

export enum RandomnessProviderStatus {
  Inactive = 0,
  Active = 1,
  NoNewRequests = 2,
}

export enum RequestStatus {
  Uninitialized = 0,
  Awaiting = 1,
  Complete = 2,
  Error = 3,
}

export enum PlatformStatus {
  Inactive = 0,
  Active = 1,
  NoNewPlayers = 2,
  NoNewPlayersOrBets = 3,
}

export enum PlatformTokenStatus {
  NoNewActivity = 0,
  Active = 1,
}

export enum GameResultStatus {
  OPEN = "open",
  WON = "won",
  LOST = "lost",
  PUSH = "push",
  CANCELLED = "cancelled",
}

export enum RewardType {
  Undefined = 0,
  Referral = 1,
  Rakeback = 2,
  DailyBonus = 3,
  WeeklyBonus = 4,
  MonthlyBonus = 5,
  LevelUpBonus = 6,
}
