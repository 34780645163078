import React, { useState } from "react";
import { BaseModal, Button } from "../components/common";

// SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface ISonicBridgeModalProps {
  open: boolean;
  closeModal: Function;
}

export const SonicBridgeModal: React.FC<ISonicBridgeModalProps> = ({
  open,
  closeModal
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const steps = [{
    image: "/static/sonic-bridge/sonic-bridge-1.png",
    title: '1. Connect wallet',
    text: 'Click the ‘connect’ button in the top right of the screen and connect supported wallet you’d like to bridge funds from.'
  }, {
    image: "/static/sonic-bridge/sonic-bridge-2.png",
    title: '2. Select Bridging Networks',
  text: 'Choose the networks you’d like to bridge from and to. Make sure the network you’re bridging to is “Sonic SVM”.'  }, {
    image: "/static/sonic-bridge/sonic-bridge-3.png",
    title: '3. Select Token and Amount',
  text: 'SOL is required for playing on Zeebit. If you’d like to play with another token you still need to bridge some SOL. Zeebit currently supports SOL, SONIC and sonicUSD as play currencies.'  }, {
    image: "/static/sonic-bridge/sonic-bridge-4.png",
    title: '4. Select Recipient Address',
  text: 'Paste the PUBKEY of receiving address, if you’re bridging to the same wallet you’re connected with press the blue “self” button on the right.'  }]

  return (
    <BaseModal open={open} onClose={closeModal} title={"Sonic Bridging"} classes={{
      dialog: `w-full sm:max-w-[560px] bg-gray-700 p-5`,
    }}>
      {/* SLIDE SHOW HERE */}
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        className="rounded-lg"

      >
        {
          steps.map((step, index) => {
            return <SwiperSlide>
              <div className="h-[292px] bg-[#2764C1] rounded-lg flex items-center justify-center px-5">
                <img src={step.image} alt={`Slide ${index + 1}`} className="rounded-xl" />
              </div>
            </SwiperSlide>
          })
        }
      </Swiper>
      {/* PROGRESS STEPS HERE */}

       {/* Pagination Dots Moved Below */}
       <div className="swiper-pagination"></div>

      {/* TEXT HERE */}
      <div className='text-gray-50 text-center flex flex-col gap-y-4'>
        <div className="text-2xl font-semibold">
          {steps[activeIndex].title}
        </div>
        <div className="text-normal font-normal">
          {steps[activeIndex].text}
        </div>

        <Button
          onClick={() => window.open('https://bridge.sonic.game')}
          variant="primary"
          size="md"
          className="h-[42px]"
        >
          Start Bridging
        </Button>

        <Button
          onClick={() => window.open('https://zeebit.gitbook.io/zeebit.io/basics/funding-on-sonic')}
          variant="gray"
          size="md"
          className="h-[42px]"
        >
          Read more
        </Button>
      </div>
    </BaseModal>
  );
}
