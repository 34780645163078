import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSet } from "react-use";
import axios from "axios";
import { capitalize } from "lodash";

import { SONIC_BRIDGE_URL } from "../../constants/chains";

export default function useOrdersPolling(onSuccess: Function) {
  const [set, { add, remove }] = useSet(new Set([]))
  const queryClient = useQueryClient()
  useQuery({
    queryFn: async () => {
      set.forEach(async (order_id) => {
        if (order_id === 'signing') {
          return
        }

        const res = await axios.get(`${SONIC_BRIDGE_URL}/order/status`, { params: {
          order_id,
        }})

        if (res.data.code === 0) {
          const toChain = capitalize(res.data.data.to_chain.split('_')[0])
          if (res.data.data.status === 'SUCCESS') {
            remove(order_id);

            onSuccess();

            queryClient.invalidateQueries({
              queryKey: ['/history', "/token/balance"],
            })
          } else if (res.data.data.status === 'FAILED') {
            remove(order_id)

            queryClient.invalidateQueries({
              queryKey: ['/history'],
            })
          }
        }
      })
    },

    queryKey: ['/status', set.size],
    refetchInterval: 10000,
    enabled: set.size > 0,
  })

  return {
    addToPolling: add,
    pollingOrders: set,
    removeOrder: remove,
  }
}
