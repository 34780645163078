import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Connection, Transaction } from "@solana/web3.js";

import { ModalProps } from "./types";
import Icon from "../common/icon/Icon";
import { BaseModal, Button, Dropdown, IconFont } from "../common";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import FormItem from "../common/form-item/FormItem";
import IconUrl from "../common/icon/Icon";
import { truncatePubkey } from "../../utils/string/string";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import { confirmTransaction } from "../../utils/solana/utils";
import {
  NetworkContext,
  AggregatedBalancesContext,
  ToasterContext
} from "../../contexts";
import { WrappedWalletContext } from "../../contexts";
import { BASE_TOAST_CONFIG, BaseToast } from "../toast/BaseToast";
import { APP_NETWORK_TYPE } from "../../types/chain";
import CopyIcon from "../../assets/icons/copy.svg";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { networksData, SONIC_BRIDGE_URL } from "../../constants/chains";
import useOrdersPolling from "../../sdk/sonic-bridge/use-orders-polling";
import { sendLegacyTransaction } from "../../data/lib/transactions";
import GoogleIcon from "../../assets/icons/google-colored.svg";
import TwitterIcon from "../../assets/icons/twitter-colored.svg";
import DiscordIcon from "../../assets/icons/discoard-colored.svg";
import { Wallets } from "../../constants/wallets";
import { getRpcReadEndpoint } from "../../utils/env/env";
import { TokenAmountField } from "../games/formGenerator/TokenAmoutInput";

type Chain = {
  chain_id: string;
  chain_name: string;
  explorer: string;
  logo: string;
  name: string;
  rpc: string;
  icon: string;
  chain: APP_NETWORK_TYPE;
};

enum SonicBirgeAllowedTokens {
  SOL = "So11111111111111111111111111111111111111112",
  USDC = "5cNgerbfdDF2oDyygHdZKkpEJs5TZHfsWqzYRxTtiuij"
}

export const SonicBridgeTransferModal: FC<ModalProps> = ({
  visible,
  hideModal,
}) => {
  const networksIcons = {
    [Wallets.GOOGLE]: <GoogleIcon />,
    [Wallets.TWITTER]: <TwitterIcon />,
    [Wallets.DISCORD]: <DiscordIcon />,
  };

  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { walletPubkey, solanaRpc, selectedWalletName } = useContext(WrappedWalletContext);
  const { client: sonicClient, recentBlockhash } = useContext(NetworkContext);
  const { wallet } = useContext(WrappedWalletContext);
  const toast = useContext(ToasterContext);

  const solanaClient = useMemo(() => {
    try {
      return new Connection(getRpcReadEndpoint(APP_NETWORK_TYPE.SOLANA), {
        commitment: "confirmed",
      });
    } catch (e) {
      console.warn("Issue loading the client", e);
    }
  }, []);


  const [tokensAmountValue, setTokensAmountValue] = useState("0");
  const [availableChains, setAvailableChains] = useState<Chain[]>([]);
  const [
    selectedToken, setSelectedToken
  ] = useState(
    mergedTokens?.find(token => SonicBirgeAllowedTokens.SOL === token.context?.pubkey?.toString())
  );

  const selectedBridgingSource = availableChains?.[0];
  const selectedBridgingDestination = availableChains?.[1];

  const selectedClient = selectedBridgingSource?.chain === APP_NETWORK_TYPE.SOLANA
    ? solanaClient
    : sonicClient;

  const {
    isCopiedToClipboard, copyToClipboard
  } = useCopyToClipboard("Successfully copied wallet public key to clipboard.");
  const { addToPolling, pollingOrders, removeOrder } = useOrdersPolling(() => {
    toast(
      <BaseToast
        message={`
          ${tokensAmountValue} ${selectedMerged?.context?.symbol} Bridge to ${selectedBridgingDestination?.name} Completed
        `}
        type={"success"}
      />,
      BASE_TOAST_CONFIG,
    );
    setTokensAmountValue("0");
  })

  const selectedMerged = useMemo(() => {
    return mergedTokens?.find((token) => {
      return token.context?.pubkey == selectedToken?.context?.pubkey
    })
  }, [mergedTokens, selectedToken?.context?.pubkey]);

  const { isLoading: isChainsDataLoading } = useQuery({
    queryFn: async () => {
      const res = await axios.get(`${SONIC_BRIDGE_URL}/chains`)

      const chains = []

      for (const item of res.data.data) {
        const name = item.chain_name.split(/\s+/)[0].toUpperCase() as APP_NETWORK_TYPE;
        item.name = name;
        item.chain = name;
        item.icon = networksData[name].icon;
        if (item.name === APP_NETWORK_TYPE.SONIC) {
          item.rpc = 'https://api.testnet.v1.sonic.game';
          item.explorer = 'https://explorer.sonic.game/?cluster=testnet.v1';
          item.name = 'Sonic-Testnet v1';
          chains.push(item)
        } else {
          item.rpc = 'https://api.devnet.solana.com'
          item.name = 'Solana-Devnet'
          item.explorer = 'https://explorer.solana.com/'
          chains.unshift(item)
        }
      }

      setAvailableChains(chains as Chain[]);
    },
    queryKey: ['/chains'],
    enabled: !availableChains.length
  })

  const { data: hasSystemError = false } = useQuery({
    queryFn: async () =>
      axios
        .get(`${SONIC_BRIDGE_URL}/bridge/status`)
        .then((res) => res?.data?.paused?.sonic || res?.data?.paused?.solana),
    queryKey: ['/bridge/status'],
  })

  const { data: departureNetworkBalance } = useQuery({
    queryFn: async () => {
      const res = await axios
        .get(`${SONIC_BRIDGE_URL}/token/balance`, {
          params: {
            wallet: walletPubkey?.toString(),
            chain_id: selectedBridgingSource?.chain_id,
            token: selectedToken?.context?.pubkey,
          }
        });

      return res.data.data
    },
    queryKey: [
      '/token/balance',
      walletPubkey?.toString(),
      selectedToken?.context?.pubkey,
      selectedBridgingSource?.chain_id,
      pollingOrders.size
    ],
    gcTime: 0,
  });

  const { mutate } = useMutation({
    mutationKey: ['/build/tx'],
    mutationFn: async (data: any) => (
      axios.post(`${SONIC_BRIDGE_URL}/quote/build-tx`, data)
    ),
  });

  const params = {
    from_chain: selectedBridgingSource?.chain_id,
    to_chain: selectedBridgingDestination?.chain_id,
    from_wallet: walletPubkey?.toString(),
    to_wallet: walletPubkey?.toString(),
    token: selectedToken?.context?.pubkey,
    amount: tokensAmountValue * 10 ** selectedMerged?.context?.decimals,
  }

  const { data: quoteRes } = useQuery({
    queryFn: async () => await axios.get(`${SONIC_BRIDGE_URL}/quote`, { params }),
    queryKey: ['/quote', JSON.stringify(params)],
    gcTime: 0,
  })

  const handleSubmit = async () => {
    mutate(
      {
        from_chain: selectedBridgingSource.chain_id,
        from_wallet: walletPubkey?.toString(),
        token: selectedToken?.context?.pubkey,
        amount: tokensAmountValue * 10 ** selectedMerged.context?.decimals,
        to_chain: selectedBridgingDestination.chain_id,
        to_wallet: walletPubkey?.toString(),
      },
      {
        onSuccess(res) {
          triggerTransaction(res.data.data.tx_str, res.data.data.order_id)
        },
        onError() {
          // toast({
          //   title: ` ${data.amount} ${currentToken.token_symbol} Bridge to ${chains[1].name} Failed`,
          //   description:
          //     'Sorry, the transaction failed. The system is undergoing maintenance and upgrades.',
          //   status: 'error',
          //   position: isMobile ? 'top' : 'top-right',
          // })
        },
      },
    )
  };

  const triggerTransaction = async (
    transactionString: string,
    order_id: string,
  ) => {
    if (!walletPubkey) {
      console.error('Wallet not connected')
      return
    }
    addToPolling('signing');

    try {
      const tx = Transaction.from(Buffer.from(transactionString, 'base64'))

      const { txid } = await sendLegacyTransaction(
        selectedClient,
        solanaRpc.signTransaction,
        tx,
        "processed"
      );

      if (!txid) {
        throw new Error('Could not retrieve transaction hash')
      }

      addToPolling(order_id);

      await confirmTransaction(txid, selectedClient, recentBlockhash, "confirmed");
    } catch (error) {
      console.log('Transaction failed:', error);
    } finally {
      removeOrder('signing');
    }
  }

  const tokensDropdownItems = mergedTokens
    ?.filter(token => [SonicBirgeAllowedTokens.SOL].includes(token.context?.pubkey?.toString()))
    ?.map(token => ({
      value: token?.context?.symbol,
      data: token,
      key: token?.context?.symbol,
      name: `${token?.context?.name} (${token?.context?.symbol})`,
    })) || [];

  const amountError =
    departureNetworkBalance?.ui_amount < tokensAmountValue
      ? "Insufficient funds"
      : "";
  const solGasFee = "0.00005";

  useEffect(() => {
    if (visible && !pollingOrders.size)
      setTokensAmountValue("0");
  }, [visible, pollingOrders]);

  return (
    <BaseModal
      open={visible}
      title="Sonic Bridging"
      onClose={() => {
        hideModal();
      }}
      classes={{
        dialog: `w-[342px] sm:max-w-[560px] bg-gray-800 p-5`
      }}
    >
      <div
        data-id="sonic-bridge-transfer-modal"
        className="flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative"
      >
        <div
          className={twMerge(
            "flex flex-col overflow-y-auto w-full pr-3 -mr-3 gap-y-6",
            SCROLLBAR_CLASSES_BLACK
          )}
        >
          <div className="flex w-full flex-col gap-y-4">
            <div className="flex gap-3 flex-col relative">
              <div className="flex w-full gap-3 bg-gray-600 pb-8 pt-2 px-3 rounded-md flex-col sm:flex-row">
                <FormItem
                  className="[&>label]:font-normal [&>label]:first-of-type:text-gray-200 basis-2/3"
                  label="From"
                >
                  <div className="flex items-center justify-between relative w-full py-2 px-4 bg-gray-500 rounded-md">
                    <div className="flex items-center cursor-pointer w-full">
                      <IconUrl
                        iconUrl={selectedBridgingSource?.icon}
                        className="w-5 h-5 mr-2 mb-0.5"
                      />
                      <div className="flex flex-col gap-y-0.5">
                        <div className="text-base font-semibold">
                          {selectedBridgingSource?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </FormItem>

                <div className="self-stretch [&>label]:font-normal w-full mt-0 md:mt-6.5">
                  <div className="flex flex-col w-full items-start">
                    <button
                      className={twMerge(
                        "flex self-stretch h-9 py-2.5 px-4 justify-between",
                        "items-center gap-x-1 flex-1 bg-gray-500 rounded-md font-sm",
                        isCopiedToClipboard ? "" : "cursor-pointer"
                      )}
                      disabled={isCopiedToClipboard}
                      onClick={() => (copyToClipboard(walletPubkey?.toString() || ""))}
                    >
                      <div className="flex text-sm items-center">
                        {
                          wallet?.adapter?.icon
                            ? (
                              <IconUrl
                                iconUrl={wallet?.adapter?.icon}
                                className="w-5 h-5 mr-2 mb-0.5"
                              />
                            ) : (
                              <Icon
                                icon={<div>{networksIcons[selectedWalletName || Wallets.GOOGLE]}</div>}
                                className="w-5 h-5 mr-2 mb-0.5"
                              />
                            )
                        }
                        {truncatePubkey(walletPubkey?.toString() || "", 4)}
                      </div>
                      <div>{<Icon size={"lg"} icon={<CopyIcon />} />}</div>
                    </button>
                  </div>
                </div>
              </div>

              <Button
                variant="secondary-gray"
                className={twMerge(
                  "rounded-full h-14 px-0 aspect-square border-8 border-gray-800 bg-gray-600 mt-1.5",
                  "absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 "
                )}
                onClick={() => setAvailableChains(availableChains => [...availableChains.reverse()])}
              >
                <IconFont name="transactions" size="xl" />
              </Button>

              <div className="flex w-full gap-3 bg-gray-600 pb-5 pt-2 px-3 rounded-md flex-col sm:flex-row">
                <FormItem
                  className="[&>label]:font-normal [&>label]:first-of-type:text-gray-200 basis-2/3"
                  label="To"
                >
                  <div className="flex items-center justify-between relative w-full py-2 px-4 bg-gray-500 rounded-md">
                    <div className="flex items-center cursor-pointer w-full">
                      <IconUrl
                        iconUrl={selectedBridgingDestination?.icon}
                        className="w-5 h-5 mr-2 mb-0.5"
                      />
                      <div className="flex flex-col gap-y-0.5">
                        <div className="text-base font-semibold">
                          {selectedBridgingDestination?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </FormItem>

                <div className="self-stretch [&>label]:font-normal w-full mt-0 md:mt-6.5">
                  <div className="flex flex-col w-full items-start">
                    <button
                      className={twMerge(
                        "flex self-stretch h-9 py-2.5 px-4 justify-between",
                        "items-center gap-x-1 flex-1 bg-gray-500 rounded-md font-sm",
                        isCopiedToClipboard ? "" : "cursor-pointer"
                      )}
                      disabled={isCopiedToClipboard}
                      onClick={() => (copyToClipboard(walletPubkey?.toString() || ""))}
                    >
                      <div className="flex text-sm items-center">
                        {
                          wallet?.adapter?.icon
                            ? (
                              <IconUrl
                                iconUrl={wallet?.adapter?.icon}
                                className="w-5 h-5 mr-2 mb-0.5"
                              />
                            ) : (
                              <Icon
                                icon={<div>{networksIcons[selectedWalletName || Wallets.GOOGLE]}</div>}
                                className="w-5 h-5 mr-2 mb-0.5"
                              />
                            )
                        }
                        {truncatePubkey(walletPubkey?.toString() || "", 4)}
                      </div>
                      <div>{<Icon size={"lg"} icon={<CopyIcon />} />}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full gap-3 flex-col sm:flex-row">
              <FormItem
                className="[&>label]:font-normal [&>label]:first-of-type:text-gray-200 basis-2/3"
                label="Asset"
              >
                <Dropdown
                  items={tokensDropdownItems}
                  classes={{
                    input: `h-12 bg-gray-900 hover:bg-gray-500 [&[data-headlessui-state="open"]]:bg-gray-900`,
                    menu: "top-1 bg-gray-500 px-2 w-full",
                  }}
                  containerStyles="w-full sm:w-full"
                  disabled={!!pollingOrders.size}
                  triggerButton={
                    ({ open }) => (
                      <div
                        className="flex items-center justify-between relative w-full px-2"
                      >
                        <div className="flex items-center cursor-pointer w-full">
                          {
                            tokensDropdownItems.find(
                              (token) => (
                                token.data.context?.pubkey == selectedToken?.context?.pubkey
                              )
                            ) != null
                              ? (
                                <>
                                  <IconUrl
                                    iconUrl={selectedMerged?.context?.imageDarkPng}
                                    className="w-5 h-5 mr-2 mb-0.5"
                                  />
                                  <div className="flex flex-col gap-y-0.5">
                                    <div className="text-base font-semibold">
                                      {selectedMerged?.context?.symbol}
                                    </div>
                                  </div>
                                </>
                              ) : ''
                          }
                        </div>
                        <IconFont
                          className={`transition-all ${open ? "rotate-180" : ""} text-[24px]`}
                          name="arrow_down"
                        />
                      </div>
                    )
                  }
                  itemComponent={
                    ({ item }) => {
                      return (
                        <button
                          className="flex w-full bg-gray-500 rounded-lg hover:bg-gray-400 items-center p-2"
                          onClick={() => {

                            setSelectedToken(
                              mergedTokens?.find(token => item.data.context.pubkey === token.context?.pubkey)
                            )
                          }}
                        >
                          <IconUrl
                            iconUrl={item?.data?.context.imageDarkPng}
                            className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5"
                          />
                          <div className="flex flex-col gap-y-0.5 relative top-0.5 text-left">
                            <div className="flex justify-start text-base font-semibold capitalize">
                              {item.name}
                            </div>
                          </div>
                        </button>
                      );
                    }}
                />
              </FormItem>

              <FormItem
                className="self-stretch [&>label]:font-normal [&>label]:first:text-gray-200 w-full"
                label="Amount"
                rightLabel={
                  <div className="flex font-semibold">
                    Balance:&nbsp;
                    {
                      isChainsDataLoading
                        ? (<div className="h-4 w-16 rounded bg-gray-600 animate-pulse" />)
                        : (
                          formatZeebitNumber(
                            departureNetworkBalance?.ui_amount || 0,
                            NumberType.TOKEN_AMOUNT,
                            0,
                            departureNetworkBalance?.decimals
                          )
                        )
                    }

                    &nbsp;{selectedMerged?.context?.symbol}
                  </div>
                }
                error={amountError}
              >
                <TokenAmountField
                  classes={{
                    label: amountError ? "" : "border-none",
                    input: "py-3",
                  }}
                  minWager={0}
                  wager={String(tokensAmountValue)}
                  setWager={setTokensAmountValue}
                  step={0.1}
                  maxWager={departureNetworkBalance?.ui_amount}
                  actions={["half", "max"]}
                  error={amountError}
                />
              </FormItem>
            </div>
          </div>

          <div className="flex w-full text-sm font-normal text-gray-200 -mt-4">
            Transaction Fee (EST):
            &nbsp;{quoteRes?.data?.data.fee ? (<span>{quoteRes?.data?.data.fee} SOL</span>) : ""}
          </div>

          <div className="flex w-full flex-col gap-y-3">
            <Button
              variant="primary"
              className={twMerge(
                "w-full",
                amountError || Number(tokensAmountValue) <= 0 ? "bg-gray-600" : "",
                !!pollingOrders.size ? "animate-pulse" : ""
              )}
              size="lg"
              isLoading={!!pollingOrders.size}
              disabled={!!pollingOrders.size && (!!amountError || Number(tokensAmountValue) <= 0)}
              onClick={handleSubmit}
            >
              {!!pollingOrders.size ? "Bridging..." : "Bridge"}
            </Button>
            {!!pollingOrders.size && (<div className="flex text-sm font-normal text-gray-300">This may take a little bit of time</div>)}
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
