import { BaseModal } from "../components/common/modal/Modal";
import { SCROLLBAR_CLASSES } from "../styles/commonClasses";
import termsHtml from "./../static/terms/terms.html";
var template = { __html: termsHtml };

interface ITermsModal {
  open: boolean;
  closeModal: Function;
}

export const TermsModal = ({ closeModal, open }: ITermsModal) => {
  return (
    <BaseModal title="Community Rules" onClose={closeModal} open={open}>
      <div
        className={`overflow-y-auto h-[40vh] md:h-[360px] p-3 justify-center rounded-lg bg-global-bg font-sm text-gray-400 font-normal ${SCROLLBAR_CLASSES}`}
      >
        <div dangerouslySetInnerHTML={template} />
      </div>
    </BaseModal>
  );
};
